import { IBodyAddExtraWorker, IBodyUpdateExtraWorker, IDataUpdateMultiNote } from 'common/interface';
import { axiosInstance, sendPost } from './axios';
import { CommonStatus } from 'common';

export const addExtraWorker = async ({ payload, companyId }: { payload: IBodyAddExtraWorker; companyId?: number }) => {
  const response = await axiosInstance.post('/v5/worker/add-extra-worker', payload, { headers: { companyId } });

  return response?.data;
};

export const updateExtraWorker = async ({
  body,
  noteId,
  companyId,
}: {
  body: IBodyUpdateExtraWorker | { status: CommonStatus };
  noteId: number;
  companyId?: number;
}) => {
  const response = await axiosInstance.post(`/v5/worker/update-extra-worker/${noteId}`, body, {
    headers: { companyId },
  });

  return response?.data;
};

export const updateMultiNote = async (payload: { data: IDataUpdateMultiNote[] }) => {
  return sendPost('/v5/worker/update-multiple-note', payload);
};
