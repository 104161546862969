import { Form } from 'antd';

interface IProps {
  nameCheckBoxMorningShift?: string;
  nameCheckBoxAfternoonShift?: string;
  nameCheckBoxEveningShift?: string;
  nameCheckBoxOtherShift?: string;
}

const ErrorCheckBoxShift = ({
  nameCheckBoxAfternoonShift = 'afternoonShift',
  nameCheckBoxMorningShift = 'morningShift',
  nameCheckBoxEveningShift = 'eveningShift',
  nameCheckBoxOtherShift = 'otherShift',
}: IProps) => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, getFieldError }) => {
        const errorMorningShift = getFieldError(nameCheckBoxMorningShift)?.[0];
        const errorAfternoonShift = getFieldError(nameCheckBoxAfternoonShift)?.[0];
        const errorEveningShift = getFieldError(nameCheckBoxEveningShift)?.[0];
        const errorOtherShift = getFieldError(nameCheckBoxOtherShift)?.[0];

        const isNotSelectAtLeastOneShift = [
          errorMorningShift,
          errorAfternoonShift,
          errorEveningShift,
          errorOtherShift,
        ].some((x) => x);

        if (isNotSelectAtLeastOneShift)
          return <div className="text-error strong">少なくとも1つのシフトを選択してください。</div>;

        return null;
      }}
    </Form.Item>
  );
};

export default ErrorCheckBoxShift;
