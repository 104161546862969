import queryKeys from 'common/queryKeys';
import { useQueryClient } from 'react-query';

const useRefetchCalendar = () => {
  const queryClient = useQueryClient();

  const refetchCalendar = () => {
    queryClient.invalidateQueries(['construction-site-detail']);
    queryClient.invalidateQueries(['list-tasks--calendar']);
    queryClient.invalidateQueries(['list-tasks--home-calendar']);
    queryClient.invalidateQueries(['list-tasks-of-construction']);
    queryClient.invalidateQueries(['construction-site-task-detail']);
    queryClient.invalidateQueries([queryKeys.calendarWorkerReport]);

    // refetch worker report
    queryClient.invalidateQueries([queryKeys.calendarDetailWorkerReport]);
  };

  return {
    refetchCalendar,
  };
};

export default useRefetchCalendar;
