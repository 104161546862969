import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const SectionWrapper = ({ children, className, ...rest }: IProps) => {
  return (
    <div className={classNames(styles.wrapper, className)} {...rest}>
      {children}
    </div>
  );
};

export default SectionWrapper;
