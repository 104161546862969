import { ModeType, UserRole } from 'common';
import { ICompanyActive, ITaskDetail } from 'common/interface';
import useListCompanyActive from 'hooks/apis/useListCompanyActive';
import useProfile from 'hooks/useProfile';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';

interface IProps {
  taskDetail?: ITaskDetail;
  mode?: ModeType;
}

/**
 * @description :
 * - get role of user in multiple company
 * - get company selected in multiple company
 */

const useGetRoleInCompany = ({ taskDetail, mode = ModeType.EDIT }: IProps) => {
  const { listCompanyActive } = useListCompanyActive();
  const profile = useProfile();

  // when update, edit task -> this is role of user in company
  const defaultCompanySelected = listCompanyActive?.find(
    (company) => company.id === taskDetail?.companyId
  ) as ICompanyActive;

  const isInModeViewOrUpdate = [ModeType.EDIT, ModeType.VIEW].includes(mode);

  // when create new task -> this is role of user in company
  const [companySelected, setCompanySelected] = useState<ICompanyActive | undefined>();

  // current role of user in company
  const currentRoleInCompany = (
    !isNil(taskDetail) ? defaultCompanySelected?.roleId : companySelected?.roleId
  ) as UserRole;

  const isOnlyVendor = !companySelected?.isCustomer && currentRoleInCompany === UserRole.VENDOR;
  const isOnlyCustomer = currentRoleInCompany === UserRole.CUSTOMER && !companySelected?.isCustomer;
  const isCustomer = currentRoleInCompany === UserRole.CUSTOMER || Boolean(companySelected?.isCustomer);
  const isVendorCustomer = Boolean(companySelected?.isCustomer) && currentRoleInCompany === UserRole.VENDOR;
  const isVendor = currentRoleInCompany === UserRole.VENDOR;
  const isWorker = [UserRole.OTHER_WORKER, UserRole.WORKER, UserRole.PART_TIME_WORKER, UserRole.VENDOR_WORKER].includes(
    currentRoleInCompany
  );

  /* --------------------------- mode = VIEW | CREATE --------------------------- */
  /* ------------------------------ roles in task ------------------------------ */
  const isCustomerOfTask = profile?.id === taskDetail?.customerId;
  const isVendorOfTask = taskDetail?.vendors?.some((vendor) => vendor?.userId === profile?.id);
  const isVendorCustomerOfTask = isVendorOfTask && isCustomerOfTask;
  const isOnlyVendorOfTask = isVendorOfTask && !isCustomerOfTask;
  const isOnlyCustomerOfTask = isCustomerOfTask && !isVendorOfTask;

  useEffect(() => {
    if (isInModeViewOrUpdate) {
      setCompanySelected(defaultCompanySelected);
    }
  }, [defaultCompanySelected?.id]);

  return {
    currentRoleInCompany,
    companySelected,
    setCompanySelected,
    defaultCompanySelected,
    isOnlyVendor,
    isCustomer,
    isOnlyCustomer,
    isVendorCustomer,
    isWorker,
    isVendor,
    isVendorOfTask,
    isVendorCustomerOfTask,
    isOnlyVendorOfTask,
    isCustomerOfTask,
    isOnlyCustomerOfTask,
  };
};

export default useGetRoleInCompany;
