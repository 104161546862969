import { Avatar } from 'antd';
import classNames from 'classnames';
import styles from './styles.module.scss';
import React from 'react';
import icClose from 'assets/icons/close-border-white.svg';
interface IProps {
  src?: string;
  className?: string;
  name?: string;
  email?: string;
  size?: 'small' | 'large' | number;
  closeIcon?: boolean;
  onClose?: () => void;
  isVendor?: boolean;
}

export default function AvatarProfile({
  src,
  name,
  className,
  email,
  size,
  closeIcon = false,
  onClose,
  isVendor = false,
}: IProps) {
  let avatarSize = typeof size === 'number' ? size : size === 'large' ? 80 : 40;

  const ava = src ? (
    <Avatar
      size={avatarSize}
      src={src}
      className={classNames(className, styles.avatar, {
        [styles.avatarVendor]: isVendor,
      })}
    />
  ) : (
    <Avatar
      size={avatarSize}
      className={classNames(className, styles.avatar, styles.avatarText, {
        [styles.avatarVendor]: isVendor,
      })}
      style={{ flexShrink: 0 }}
    >
      {name?.slice(0, 1) || email?.slice(0, 1)}
    </Avatar>
  );

  return (
    <div className={styles.wrapper}>
      {ava}
      {closeIcon && (
        <img
          src={icClose}
          alt="close"
          className={styles.icClose}
          onClick={(e) => {
            e.stopPropagation();

            onClose?.();
          }}
        />
      )}
    </div>
  );
}
