import { IUpdateSideBar, Route } from 'common/interface';

export const checkPermissionSideNav = ({
  routes,
  itemMenuServer,
}: {
  routes: Route[];
  itemMenuServer: IUpdateSideBar;
}) => {
  const find = routes.find((route) => route.key === itemMenuServer.key);

  return find?.visible;
};
