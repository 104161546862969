import { Col, Form, Row } from 'antd';
import icons from 'assets/icons';
import { listDateClosingSalary, listTypePaymentOffsetWithMonth, textJP } from 'common/const';
import CommonSpace from 'components/CommonSpace';
import SectionWrapper from 'components/SectionWrapper';
import { useRulesForm } from 'hooks/useRulesForm';
import PaymentPrice from './PaymentPrice';
import { CommonSelect } from 'components/CommonSelect';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  disableAll?: boolean;
}

const PaymentInformation = ({ disableAll, ...rest }: IProps) => {
  const { rulesForm } = useRulesForm();

  return (
    <SectionWrapper {...rest}>
      <CommonSpace align="center" gap={5} className="mb-10">
        <img src={icons.yen.outline.black} alt="" />
        <div className="strong mt-4">{textJP.paymentInformation}</div>
      </CommonSpace>

      <PaymentPrice disableAll={disableAll} />

      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            label={textJP.payment.month}
            name={['customer', 'paymentMonthOffset']}
            rules={[rulesForm().required]}
            initialValue={listTypePaymentOffsetWithMonth[0].id} // default is current month
            className="mb-10"
          >
            <CommonSelect options={listTypePaymentOffsetWithMonth} showSearch={false} disabled={disableAll} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={textJP.payment.date}
            name={['customer', 'paymentDate']}
            rules={[rulesForm().required]}
            className="mb-10"
          >
            <CommonSelect options={listDateClosingSalary} showSearch={false} disabled={disableAll} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={textJP.payment.deadline}
            name={['customer', 'deadline']}
            rules={[rulesForm().required]}
            className="mb-0"
          >
            <CommonSelect options={listDateClosingSalary} showSearch={false} disabled={disableAll} />
          </Form.Item>
        </Col>
      </Row>
    </SectionWrapper>
  );
};

export default PaymentInformation;
