import time from 'assets/icons/time.svg';
import classNames from 'classnames';
import { CommonButton } from 'components/CommonButton';
import { CommonModal } from 'components/CommonModal';
import React from 'react';
import styles from './styles.module.scss';

export const PendingSignUp = ({ openModalConfirm, setOpen }: any) => {
  return (
    <>
      <CommonModal
        isModalVisible={openModalConfirm}
        handleClose={() => setOpen({ openModalConfirm: false })}
        title=""
        footer={null}
        className={`${styles.modalEmailVendor} ${styles.pModal}`}
      >
        <div className={styles.header}>
          <div className={styles.rotate}>
            <div className={classNames(styles.time, styles.add)}>
              <img src={time} alt="" />
            </div>
          </div>
          <div className={styles.formContent}>
            <p>承認後メールをお送りいたします。</p>
          </div>
          <CommonButton
            title="次へ "
            type="primary"
            className={styles.btnGoBack}
            htmlType="button"
            onClick={() => setOpen({ openModalConfirm: false })}
          />
        </div>
      </CommonModal>
    </>
  );
};
