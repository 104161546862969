import { Col, Form, Row } from 'antd';
import { ConstructionSiteType } from 'common';
import { textJP } from 'common/const';
import { CommonInputNumber } from 'components/CommonInputNumber';
import { useRulesForm } from 'hooks/useRulesForm';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  disableAll?: boolean;
}

const PaymentPrice = ({ disableAll }: IProps) => {
  const { rulesForm } = useRulesForm();

  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const type = getFieldValue(['constructionSite', 'type']);

        // payment fixed price
        if (type === ConstructionSiteType.FIXED_PRICE) {
          return (
            <Form.Item
              label={textJP.amount}
              name={['customer', 'regularUnitPrice']}
              rules={[rulesForm().required]}
              className="mb-10"
            >
              <CommonInputNumber disabled={disableAll} />
            </Form.Item>
          );
        }

        // payment labor price
        return (
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Form.Item
                label={textJP.amount}
                name={['customer', 'regularUnitPrice']}
                rules={[rulesForm().required]}
                className="mb-10"
              >
                <CommonInputNumber placeholder={textJP.price.regular} disabled={disableAll} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={textJP.amount}
                name={['customer', 'nightUnitPrice']}
                rules={[rulesForm().required]}
                className="mb-10"
              >
                <CommonInputNumber placeholder={textJP.price.night} disabled={disableAll} />
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default PaymentPrice;
