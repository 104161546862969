import { IProject } from 'common/interface';
import queryKeys from 'common/queryKeys';
import { useQueryClient } from 'react-query';

const useListProjectTask = () => {
  const queryClient = useQueryClient();

  const getListProjectTask = () => {
    return (queryClient.getQueryData([queryKeys.construction.listProject]) ?? []) as IProject[];
  };

  const setListProjectTask = (data: any) => {
    queryClient.setQueryData([queryKeys.construction.listProject], data);
  };

  return { getListProjectTask, setListProjectTask };
};

export default useListProjectTask;
