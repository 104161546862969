import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from 'assets/icons/logo.svg';
import styles from './styles.module.scss';
import { Typography } from 'antd';

const { Text } = Typography;

export const Banner = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.banner}>
      <h2>{t('title.slogan')}</h2>
      <p>①案件管理</p>
      <p>・FAXやメールの必要なし</p>
      <p>・自動的にスケジューリングされ、相互のカレンダーに反映</p>
      <p>・外出先でもスマホから各種書類発行可能</p>
      <p>②スタッフ管理</p>
      <p>・時給、日給、月給、プロジェクト給、一人親方にも対応</p>
      <p>・設定に基づき、給料の自動計算</p>
      <p>・スタッフが提出したシフトの集計機能(当日プロジェクト数との照らし合わせ)</p>
      <p>・現在地付き打刻機能で勤怠管理可能</p>
      <p>③その他</p>
      <p>・各種データのアップロード、共有可能</p>
      <p>・フレキシブルな設定が可能</p>
      <p>・テレワーク</p>
    </div>
  );
};

export const BannerRightHeader = () => {
  return (
    <div className={styles.bannerRight}>
      <div className={styles.bannerRightImage}>
        <img src={logo} alt="booking off" />
      </div>
      <h2>
        <Text>Booking</Text> <Text>off</Text>
      </h2>
    </div>
  );
};
