import { sendGet } from './axios';

export const allResource = () => sendGet<IResource>('/init/resource');

interface IResourceItem {
  id: number;
  name: string;
  order: number;
  status: number;
  type: number;
  value: string | number | any;
}

export interface IResource {
  bank: Array<IResourceItem>;
  bloodGroup: Array<IResourceItem>;
  cardType: Array<IResourceItem>;
  city: Array<Partial<IResourceItem>>;
  disease: Array<IResourceItem>;
  employmentInsurance: Array<IResourceItem>;
  healthInsurance: Array<IResourceItem>;
  ken: Array<Partial<IResourceItem>>;
  pensionInsurance: Array<IResourceItem>;
  [key: string]: any;
}
