import styles from './styles.module.scss';
import { Col, Form, Row, Space } from 'antd';
import { CommonInputNumber } from 'components/CommonInputNumber';
import classNames from 'classnames';
import addField from 'assets/icons/add_circle_outline.svg';
import iconDelete from 'assets/icons/trash-2.svg';
import { CommonSelect } from 'components/CommonSelect';
import React, { useEffect, useRef, useState } from 'react';
import { ICertificate, IVendor, TTypeShift } from 'common/interface';
import { useRulesForm } from 'hooks/useRulesForm';
import { FormInstance } from 'antd/es/form/Form';

interface IProps {
  disabledAll?: boolean;
  options?: ICertificate[];
  typeShift?: TTypeShift;
  vendor?: IVendor;
  maxWorker?: number;
  showIcons?: boolean;
  showTextAdd?: boolean;
  form?: FormInstance;
  type?: 'certificateVendor';
  dependenciesMaxWorker?: string[];
  stylesTextAdd?: React.CSSProperties;
}

const FormAddCertificate = (props: IProps) => {
  const {
    disabledAll,
    showIcons,
    options = [],
    typeShift,
    vendor,
    maxWorker,
    showTextAdd = true,
    type,
    form,
    dependenciesMaxWorker,
    stylesTextAdd,
  } = props;

  const [selectedCertificates, setSelectedCertificate] = useState<Record<number, { value: number; label: string }>>({});

  // ids of selected certificates
  const selectedCertificateIds = Object.values(selectedCertificates).map((certificate) => certificate?.value);

  const notSelectedCertificates = options.filter((opt) => !selectedCertificateIds.includes(opt.id as any));

  // is disable icon add
  const isDisableAdd = disabledAll || (options.length !== 0 && options.length === selectedCertificateIds.length);

  const { rulesForm } = useRulesForm();

  const textAddRef = useRef<HTMLDivElement>(null);

  // const [isClickTextAddCertificate, setIsClickTextAddCertificate] = useState(false);

  const nameList = typeShift || `certificate_vendor_id_${vendor?.id}`;

  const certificates = vendor?.id && form?.getFieldValue(vendor?.id);

  // update initialSelectedCertificates
  useEffect(() => {
    // default selected certificate for vendor
    if (form && vendor?.id && type === 'certificateVendor') {
      const certificates = form.getFieldValue(`certificate_vendor_id_${vendor?.id}`);

      const initialCertificates =
        certificates?.map((certificate: any) => ({
          value: certificate?.certificateId?.value,
          label: certificate?.certificateId?.label,
        })) || {};

      setSelectedCertificate({ ...initialCertificates });
    }

    // default selected certificate for customer
    if (form && typeShift && type !== 'certificateVendor') {
      const certificates = form.getFieldValue(typeShift);

      const initialCertificates =
        certificates?.map((certificate: any) => ({
          value: certificate?.certificateId?.value,
          label: certificate?.certificateId?.label,
        })) || {};

      setSelectedCertificate({ ...initialCertificates });
    }
  }, [certificates, form, vendor?.id, type, typeShift]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapList}>
        <Form.List name={nameList}>
          {(fields, { add, remove }) => (
            <>
              {/* Render list  */}
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={8} className={styles.row} key={key}>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      name={[name, 'certificateId']}
                      label={<span className={classNames('color-text', 'text-13')}>ライセンスなど</span>}
                      rules={[rulesForm().required]}
                      labelCol={{ span: 24 }}
                    >
                      <CommonSelect
                        labelInValue
                        filterOption={false}
                        options={notSelectedCertificates}
                        optionLabel="title"
                        optionValue="id"
                        onChange={(val) => {
                          const newCertificate = { [name]: val };

                          setSelectedCertificate((pre) => ({ ...pre, ...newCertificate } as any));
                        }}
                        disabled={disabledAll}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} className={styles.colWithIcon}>
                    <Form.Item
                      {...restField}
                      name={[name, 'workers']}
                      style={{ flex: '1' }}
                      label={<span className={classNames('color-text', 'text-13')}>数量</span>}
                      rules={[
                        rulesForm().required,
                        rulesForm().checkPositiveNumber(),
                        // rulesForm().checkMaxNumber(maxWorker), // remove rule check > maxWorker
                      ]}
                      labelCol={{ span: 24 }}
                      dependencies={dependenciesMaxWorker}
                    >
                      <CommonInputNumber disabled={disabledAll} />
                    </Form.Item>

                    {/* Icons : add && delete */}
                    {showIcons && (
                      <Space direction="horizontal" align="center">
                        <img
                          src={addField}
                          alt=""
                          onClick={() => {
                            if (isDisableAdd) return;

                            add();
                          }}
                          className={classNames(styles.icon, styles.iconAdd, {
                            [styles.disabled]: isDisableAdd,
                          })}
                        />

                        <img
                          src={iconDelete}
                          alt=""
                          onClick={() => {
                            if (disabledAll) return;

                            remove(name);

                            const formValue = form?.getFieldValue(nameList);

                            const selectedCertificatesNew = formValue?.reduce((obj: any, item: any, index: number) => {
                              return { ...obj, [index]: item?.certificateId };
                            }, {} as any);

                            setSelectedCertificate(selectedCertificatesNew);

                            // show add text
                            // if (name === 0) {
                            //   setIsClickTextAddCertificate(false);
                            // }
                          }}
                          className={classNames(styles.icon, {
                            [styles.disabled]: disabledAll,
                          })}
                        />
                      </Space>
                    )}
                  </Col>
                </Row>
              ))}

              {/* Text add */}
              {showTextAdd && (
                <Form.Item
                  // className={classNames(styles.textAddWrap, {
                  //   [styles.hide]: selectedCertificateIds.length > 0 || form?.getFieldValue(nameList)?.length > 0,
                  // })}
                  noStyle
                >
                  <div
                    className={classNames(styles.titleAdd, {
                      [styles.disabled]: isDisableAdd,
                      [styles.hide]: form?.getFieldValue(nameList)?.length > 0,
                    })}
                    onClick={() => {
                      if (isDisableAdd) return;

                      add();

                      // setIsClickTextAddCertificate(true);
                    }}
                    ref={textAddRef}
                    style={stylesTextAdd}
                  >
                    <img src={addField} alt="" />
                    <span>ライセンスを追加</span>
                  </div>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      </div>
    </div>
  );
};

export default FormAddCertificate;
