import { InputNumber, InputNumberProps } from 'antd';
import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { isNil } from 'lodash';

interface IPropsInput extends InputNumberProps {
  onChange?: (vals: any) => any;
  value?: any;
  min?: number;
  max?: number;
  disabled?: boolean;
  placeholder?: string;
  step?: number;
  className?: string;
  isOnlyInteger?: boolean;
}
const MAX_NUMBER = 1000000000000000;
const NOT_INTEGER_KEYS = [',', '.'];

export const CommonInputNumber = ({
  onChange,
  value,
  min = 0,
  max = MAX_NUMBER,
  placeholder,
  step,
  className,
  isOnlyInteger,
  onKeyDown,
  onPaste,
  ...rest
}: IPropsInput) => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;

    // block input float number
    if (isOnlyInteger && NOT_INTEGER_KEYS.includes(key)) {
      e.preventDefault();
    }

    onKeyDown?.(e);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData('Text');

    // Check if the pasted data is a float
    const isFloatNumber = NOT_INTEGER_KEYS.some((key) => pastedData.includes(key)) || isNaN(Number(pastedData));

    // block paste float number
    if (isOnlyInteger && isFloatNumber) {
      e.preventDefault();
    }

    onPaste?.(e);
  };

  return (
    <>
      <InputNumber
        className={classnames(styles.input, className)}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        type="number"
        {...rest}
        prefix="$"
        onKeyDown={handleKeyPress}
        onPaste={handlePaste}
      />
    </>
  );
};
