import React from 'react';
import useIsAppearMetadata from '../../hooks/useIsAppearMetadata';
import { ITaskDetail } from 'common/interface';
import { Col, Row } from 'antd';

interface IProps {
  taskDetail?: ITaskDetail;
}

const MetadataTimeDesire = ({ taskDetail }: IProps) => {
  const { isAppearMetadata, metadataCustomerUpdate } = useIsAppearMetadata({ taskDetail });

  const isHaveMetadataDayOffs = [isAppearMetadata('startTimeDesire'), isAppearMetadata('endTimeDesire')].some(
    (item) => item
  );

  if (!isHaveMetadataDayOffs) return null;

  return (
    <Row className="fontsize-14 color-text2">
      <Col span={12} className="text-green">
        {metadataCustomerUpdate?.startTimeDesire}-{metadataCustomerUpdate?.endTimeDesire}
      </Col>
    </Row>
  );
};

export default MetadataTimeDesire;
