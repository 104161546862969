import { Col, Form, Row } from 'antd';
import icons from 'assets/icons';
import { textJP } from 'common/const';
import { CommonInput } from 'components/CommonInput';
import CommonSpace from 'components/CommonSpace';
import SectionWrapper from 'components/SectionWrapper';
import { useRulesForm } from 'hooks/useRulesForm';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  disableAll?: boolean;
}

const InformationCustomer = ({ disableAll, ...rest }: IProps) => {
  const { rulesForm } = useRulesForm();

  return (
    <SectionWrapper {...rest}>
      <CommonSpace align="center" gap={5} className="mb-10">
        <img src={icons.userDouble.black} alt="" />
        <span className="strong">{textJP.clientInformation}</span>
      </CommonSpace>

      <Row gutter={20} className="mb-10">
        <Col span={12}>
          <Form.Item
            label={textJP.clientName}
            name={['customer', 'name']}
            rules={[rulesForm().required]}
            className="mb-10"
          >
            <CommonInput disabled={disableAll} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={textJP.companyName} name={['customer', 'companyName']} className="mb-10">
            <CommonInput disabled={disableAll} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={textJP.phone}
        name={['customer', 'phone']}
        className="mb-10"
        rules={[rulesForm().required, rulesForm().validatePhoneNumber]}
        validateFirst
      >
        <CommonInput disabled={disableAll} />
      </Form.Item>

      <Form.Item
        label={textJP.postCode}
        name={['customer', 'postalCode']}
        className="mb-10"
        rules={[rulesForm().required, rulesForm().validatePostCodeJapan()]}
        validateFirst
      >
        <CommonInput disabled={disableAll} />
      </Form.Item>

      <Form.Item label={textJP.address} name={['customer', 'address']} className="mb-0" rules={[rulesForm().required]}>
        <CommonInput disabled={disableAll} />
      </Form.Item>
    </SectionWrapper>
  );
};

export default InformationCustomer;
