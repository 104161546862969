import { useMutation } from 'react-query';
import useRefetchCalendar from './useRefetchCalendar';
import { adminUpdateNote } from 'api/constructionSite';
import { handleErrorMessage, handleMessageSuccess } from 'helper';

const useUpdateNote = () => {
  const { refetchCalendar } = useRefetchCalendar();

  const updateNoteMutation = useMutation({
    mutationFn: adminUpdateNote,
    onSuccess(data, variables, context) {
      handleMessageSuccess();

      refetchCalendar();
    },
    onError(error, variables, context) {
      handleErrorMessage(error);
    },
  });

  return { updateNoteMutation };
};

export default useUpdateNote;
