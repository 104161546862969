import { Badge, Button, Col, Dropdown, Form, Menu, Row, Space, Typography } from 'antd';
import { logout } from 'api/axios';
import { getTotalUnread } from 'api/notification';
import icBell from 'assets/icons/bell.svg';
import icContact from 'assets/icons/contact.svg';
import icSuspend from 'assets/icons/ic_suspend.svg';
import icEmail from 'assets/icons/email1.svg';
import logo from 'assets/icons/logo2.svg';
import icLogout from 'assets/icons/logout.svg';
import icPolicy from 'assets/icons/policy.svg';
import icPersonCheck from 'assets/icons/person-check-icon.svg';
import icClockWhite from 'assets/icons/clock-white.svg';
import icProfile from 'assets/icons/profile.svg';
import icRepeat from 'assets/icons/repeat.svg';
import icTerm from 'assets/icons/term.svg';
import icLaw from 'assets/icons/transaction-law.svg';
import { UserRole } from 'common';
import AcceptInviteOfAdmin from 'components/AcceptInviteOfAdmin';
import AccessControl from 'components/AccessControl';
import AvatarProfile from 'components/Avatar';
import ChangeProfileSupperAdmin from 'components/ChangeProfileSuperAdmin';
import { CommonButton } from 'components/CommonButton';
import { CommonIconButton } from 'components/CommonIconButton';
import { CommonModal } from 'components/CommonModal';
import { ModalOvertime } from 'components/ModalOvertime';
import { ModalTimeKeeping } from 'components/ModalTimeKeeping';
import { getRoleName } from 'helper';
import useProfile from 'hooks/useProfile';
import useProfileCompany from 'hooks/useProfileCompany';
import useRoleId from 'hooks/useRoleId';
import Cookies from 'js-cookie';
import ListCompany from 'pages/Authentication/LoginCompany/ListCompany';
import CompanySignUp from 'pages/Authentication/SignUp/CompanySignUp';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import CreateConstructionSite from 'pages/ConstructionSite/CreateConstructionSite';
import queryString from 'query-string';
import { AddTaskModal } from 'pages/Tasks/AddTaskModal';
import { useCreateConstructionSite } from 'stores/CreateConstructionSiteStore';
import { useNoteToTaskStore } from 'stores/NoteToTaskStore';

const { Text, Title } = Typography;

export default function PageHeader() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const currentCompanyId = +Cookies.get('companyId')!;
  const roleId = useRoleId();
  const { profileCompany } = useProfileCompany();
  // const [showCreateConstructionSite, setShowCreateConstructionSite] = useState(false);
  const { setShowCreateConstructionSite, showCreateConstructionSite } = useCreateConstructionSite();
  const [loading, setLoading] = useState(false);
  const profile = useProfile();
  const companyAccount = queryClient.getQueryData('commonCompany') as any;
  const listCompany = companyAccount?.data?.filter((el: any) => el.id !== currentCompanyId);
  const [showTimeKeepingModal, setShowTimeKeepingModal] = useState(false);
  const [showRequestOTModal, setShowRequestOTModal] = useState(false);
  const [showChangeAccount, setShowChangeAccount] = useState(false);
  const [showChangeSuperAdminModal, setShowChangeSuperAdminModal] = useState(false);
  const [company, setCompany] = useState<number | null>(null);
  const { data: getTotalNoti } = useQuery('getTotalUnread', getTotalUnread);
  const location = useLocation();
  const searchQuery =
    (queryString.parse(location.search as string) as {
      constructionSiteId: string;
      'create-project'?: string;
    }) ?? {};
  const [showAddTask, setShowAddTask] = useState(false);
  const noteToTask = useNoteToTaskStore((state) => state.noteToTask);
  const setNoteToTask = useNoteToTaskStore((state) => state.setNoteToTask);

  const handleChange = (e: any) => {
    setCompany(+e.target.value + 1);
  };
  const handleSubmit = (data: any) => {
    setLoading(true);
    const company = listCompany.find((el: any, i: number) => i === data?.role);
    Cookies.set('companyId', company?.id + '');
    Cookies.set('roleId', company?.roleId + '');
    Cookies.set('viewMode', 'month');
    history.push('/');
    queryClient.refetchQueries();
    window.location.reload();
    handleClose();
  };

  const handleClose = () => {
    queryClient.invalidateQueries('profile');
    setShowChangeAccount(false);
    setLoading(false);
  };

  const roleInvite = [UserRole.ADMIN, UserRole.MANAGER, UserRole.VENDOR, UserRole.VENDOR_MANAGER].includes(roleId);
  const isSuperAdmin = [UserRole.SUPER_ADMIN].includes(roleId);

  const menu = (
    <Menu className={styles.dropdown} style={{ minWidth: 200 }}>
      {!profile?.isSuperAdmin && (
        <>
          <Menu.Item key="1" onClick={() => history.push('/mypage')}>
            <img className={styles.icons} src={icProfile} alt="" />
            プロフィール
          </Menu.Item>
          {roleInvite && (
            <Menu.Item key="2" onClick={() => history.push('/invites-user')}>
              <img className={styles.icons} src={icEmail} alt="" />
              招待状の管理
            </Menu.Item>
          )}
          <Menu.Item key="3" onClick={() => setShowChangeAccount(true)}>
            <img className={styles.icons} src={icRepeat} alt="" />
            企業を切り替える
          </Menu.Item>
        </>
      )}
      {profile?.isSuperAdmin && (
        <Menu.Item key="profileSuperAdmin" onClick={() => setShowChangeSuperAdminModal(true)}>
          <img className={styles.icons} src={icProfile} alt="" />
          プロフィール
        </Menu.Item>
      )}
      <Menu.Item key="5" onClick={() => window.open('/term-of-policy', '_blank')}>
        <img className={styles.icons} src={icTerm} alt="" />
        利用規約
      </Menu.Item>
      <Menu.Item key="6" onClick={() => window.open('/term-of-privacy', '_blank')}>
        <img className={styles.icons} src={icPolicy} alt="" />
        プライバシーポリシー
      </Menu.Item>
      <Menu.Item key="8" onClick={() => window.open('/transaction-law', '_blank')}>
        <img className={styles.icons} src={icLaw} alt="" />
        特定商取引法
      </Menu.Item>
      <Menu.Item key="9" onClick={() => window.open('/contact', '_blank')}>
        <img className={styles.icons} src={icContact} alt="" />
        問い合わせ
      </Menu.Item>
      {/* {!isSuperAdmin && (
        <Menu.Item key="10" onClick={() => history.push('/suspend')}>
          <img className={styles.icons} src={icSuspend} alt="" />
          退会
        </Menu.Item>
      )} */}
      <Menu.Item key="4" onClick={logout}>
        <img className={styles.icons} src={icLogout} alt="" />
        ログアウト
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.headerWrapper}>
      <Link className={styles.logo} to="/">
        <img src={logo} alt="" />
        <Text>Booking off</Text>
      </Link>
      <div className={`${styles.companyName} line-1`}>{profileCompany?.name}</div>
      {['/works/calendar', '/calendar'].includes(location.pathname) && (
        <AccessControl
          accessibleRoles={[
            UserRole.ADMIN,
            UserRole.MANAGER,
            UserRole.VENDOR_MANAGER,
            UserRole.OFFICE_MANAGER,
            UserRole.CUSTOMER,
          ]}
          isEnabled={profileCompany?.isCustomer && profileCompany?.roleId === UserRole.VENDOR}
        >
          <CommonButton
            title="プロジェクト作成"
            onClick={() => setShowCreateConstructionSite(true)}
            style={{
              borderColor: '#D9962B',
              backgroundColor: '#D9962B',
              color: 'white',
              marginLeft: 16,
            }}
            type="primary"
          />
        </AccessControl>
      )}
      <div style={{ flex: 1 }} />
      <Space size="middle">
        <AccessControl
          accessibleRoles={[
            UserRole.ADMIN,
            UserRole.MANAGER,
            UserRole.VENDOR_MANAGER,
            UserRole.OFFICE_MANAGER,
            UserRole.LEADER,
          ]}
        >
          <Space size="middle">
            <CommonIconButton
              title="打刻"
              icon={icPersonCheck}
              onClick={() => setShowTimeKeepingModal(true)}
              type="ghost"
            />

            <CommonIconButton title="残業申請" icon={icClockWhite} onClick={() => setShowRequestOTModal(true)} />
          </Space>
        </AccessControl>
        {showCreateConstructionSite && (
          <CreateConstructionSite
            mode="create"
            onSuccess={() => setShowCreateConstructionSite(false)}
            onCancel={() => {
              setShowCreateConstructionSite(false);
              setNoteToTask(null);
            }}
            setShowAddTask={(value: boolean) => {
              // nếu tạo note thì không mở modal add task ở đây...
              if (noteToTask) {
                setShowAddTask(false);

                return;
              }

              setShowAddTask(value);
            }}
          />
        )}
        {showAddTask && (
          <AddTaskModal
            constructionSiteId={Number(searchQuery.constructionSiteId)}
            handleClose={() => {
              //close modal add task
              setShowAddTask(false);

              // remove constructionSiteId from query string
              const isInPageCalendar = location.pathname === '/calendar';
              if (isInPageCalendar) {
                const { constructionSiteId, ...rest } = searchQuery;
                history.push({
                  search: queryString.stringify(rest),
                });
              }
            }}
          />
        )}
        <div className={styles.notificationButton} onClick={() => history.push('/notification')}>
          <CommonIconButton icon={icBell} title="お知らせ" />
          <Badge count={getTotalNoti?.data?.totalUnread || 0} className={styles.notificationBadge} size="small" />
        </div>
      </Space>

      <div className={styles.menuWrapper}>
        <div className={styles.menuItem}>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className={styles.avatar}>
              <AvatarProfile name={profile?.name} src={profile?.avatar} email={profile?.email} />
              <div className={styles.contentProfile}>
                <div>
                  <b title={profile.name} className="line-1" style={{ maxWidth: 150 }}>
                    {profile.name || profile.email}
                  </b>
                </div>
                <div>{getRoleName(+Cookies.get('roleId')!)}</div>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>

      <CommonModal
        isModalVisible={showChangeAccount}
        handleClose={handleClose}
        title=""
        footer={null}
        className={`${styles.modalEmailVendor} ${styles.pModal}`}
      >
        <div className={styles.header}>
          <Title level={4}>企業を選び、ログインしてください。</Title>
          <Form onFinish={handleSubmit} layout="vertical" scrollToFirstError={true}>
            <ListCompany currentProfile={profile} handleChange={handleChange} currentCompany />
            <div>
              <CompanySignUp closeListCompany={handleClose} />
            </div>
            <Row className={styles.responsiveButton} gutter={20}>
              <Col span={12}>
                <Form.Item>
                  <CommonButton onClick={handleClose} title="キャンセル " className={styles.buttonLogin} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <CommonButton
                    htmlType="submit"
                    type={company ? 'primary' : 'default'}
                    disabled={!company || loading}
                    className={styles.btnSubmit}
                    title="変更 "
                    loading={loading}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </CommonModal>
      <AcceptInviteOfAdmin />
      {showTimeKeepingModal && <ModalTimeKeeping handleClose={() => setShowTimeKeepingModal(false)} />}
      {showRequestOTModal && <ModalOvertime handleClose={() => setShowRequestOTModal(false)} />}

      {showChangeSuperAdminModal && (
        <ChangeProfileSupperAdmin setShowChangeSuperAdminModal={setShowChangeSuperAdminModal} />
      )}
    </div>
  );
}
