import { Spin } from 'antd';
import React from 'react';
import styles from './styles.module.scss';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
  children?: React.ReactNode;
  variants?: 'small' | 'large'; // small is wrapper for component, large is wrapper for page
  // position?: 'top' | 'center' | 'bottom';
}

const Loader = ({ isLoading, children, variants, ...rest }: IProps) => {
  if (variants === 'small') {
    return (
      <div className={styles.wrapper} {...rest}>
        {isLoading && (
          <div className={styles.spinContainer}>
            <Spin />
          </div>
        )}

        {children}
      </div>
    );
  }

  return (
    <>
      {isLoading && (
        <div className={styles.spinContainer} {...rest}>
          <Spin />
        </div>
      )}

      {children}
    </>
  );
};

export default Loader;
