import { Col, Form, Input, Row } from 'antd';
import style from '../../styles.module.scss';
import classNames from 'classnames';
import { CommonInputNumber } from 'components/CommonInputNumber';
import addField from 'assets/icons/add_circle_outline.svg';
import iconDelete from 'assets/icons/trash-2.svg';

interface IProps {
  disabledAll?: boolean;
  isInviteVendorCustomer?: boolean;
  showIcons?: boolean;
}

const FormPrice = ({ disabledAll = false, isInviteVendorCustomer = false, showIcons = true }: IProps) => {
  return (
    <Col span={12} className={style.wrapContent}>
      <div className={style.titleSection}>単価</div>
      <Row gutter={[24, 10]}>
        <Col span={12}>
          <Form.Item
            label={<span className={classNames('color-text', 'text-13')}>常用基本単価</span>}
            name={isInviteVendorCustomer ? 'titleRegularUnitPriceCustomer' : 'titleRegularUnitPrice'}
            initialValue="常用基本単価"
            rules={[{ required: true, message: '全ての項目を入力してください。' }]}
          >
            <Input className="inputGreyNoBorder" placeholder="タイトルを入力" disabled={disabledAll} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<span className={classNames('color-text', 'text-13')}>単価</span>}
            name={isInviteVendorCustomer ? 'regularUnitPriceCustomer' : 'regularUnitPrice'}
            rules={[{ required: true, message: '全ての項目を入力してください。' }]}
          >
            <CommonInputNumber placeholder="単価" className={style.inputNumber} disabled={disabledAll} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<span className={classNames('color-text', 'text-13')}>夜間単価</span>}
            name={isInviteVendorCustomer ? 'titleNightUnitPriceCustomer' : 'titleNightUnitPrice'}
            rules={[{ required: true, message: '全ての項目を入力してください。' }]}
            initialValue="夜間単価"
          >
            <Input className="inputGreyNoBorder" placeholder="タイトルを入力" disabled={disabledAll} />
          </Form.Item>
        </Col>
        <Col span={showIcons ? 10 : 12}>
          <Form.Item
            label={<span className={classNames('color-text', 'text-13')}>単価</span>}
            name={isInviteVendorCustomer ? 'nightUnitPriceCustomer' : 'nightUnitPrice'}
            rules={[{ required: true, message: '全ての項目を入力してください。' }]}
          >
            <CommonInputNumber placeholder="単価 " className={style.inputNumber} disabled={disabledAll} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} className={style.wrapper}>
        <Form.List name="dataUnitPrice">
          {(fields, { add, remove }) => (
            <>
              {showIcons && (
                <Form.Item
                  className={classNames(style.addField, {
                    [style.disabled]: disabledAll,
                  })}
                >
                  <img src={addField} alt="" onClick={() => !disabledAll && add()} />
                </Form.Item>
              )}

              {fields.map(({ key, name, ...restField }) => (
                <div className={style.wrapperUnitPrice} key={key}>
                  <Row gutter={24} className={style.unitPrice}>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'title']}
                        label={<span className={classNames('color-text', 'text-13')}>タイトル</span>}
                        rules={[{ required: true, message: '全ての項目を入力してください。' }]}
                      >
                        <Input placeholder="タイトルを入力" className="inputGreyNoBorder" disabled={disabledAll} />
                      </Form.Item>
                    </Col>
                    <Col span={showIcons ? 10 : 12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'price']}
                        label={<span className={classNames('color-text', 'text-13')}>単価</span>}
                        rules={[{ required: true, message: '全ての項目を入力してください。' }]}
                      >
                        <CommonInputNumber placeholder="単価" className={style.inputNumber} disabled={disabledAll} />
                      </Form.Item>
                    </Col>
                    {showIcons && (
                      <Col span={2} className={style.iconWrap}>
                        <img
                          src={iconDelete}
                          alt=""
                          onClick={() => !disabledAll && remove(name)}
                          className={style.icon}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              ))}
            </>
          )}
        </Form.List>
      </Row>
    </Col>
  );
};

export default FormPrice;
