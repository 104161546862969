import { IBodyUpdateBreakTime } from 'common/interface';
import { sendGet, sendPost, sendPut } from './axios';
import { TypeAlertBreakTime } from 'common/const';

export const getListTimekeepingConstructionSite = (params: any) => sendGet('/v2/timekeeping/list', params);

export const approveTimekeepingConstructionSite = (params: any) =>
  sendPost('/construction-site/approve-time-keeping', params);

export const timeKeepingForOtherPeople = (params: any) => sendPost('/v2/timekeeping/for-other-user', params);

export const approveTimekeeping = (params: any) => sendPost('/time-keeping/approve', params);
export const createTimekeeping = (params: any) => sendPost('/time-keeping/create', params);
export const createOvertime = (params: any) => sendPost('/v2/timekeeping/request-overtime', params);
export const requestOverTimeForOther = (params: any) => sendPost('/v2/timekeeping/request-overtime-for-other', params);

export const hasCheckin = () => sendGet('/v2/timekeeping');
export const checkinCheckout = (params?: any) => sendPost('/v2/timekeeping', params);

export const removeTimeKeeping = (id: number) => sendPost(`/time-keeping/soft-remove-timekeeping/${id}`);

export const getListConstructionHaveTask = (params: any) =>
  sendPost('v2/construction-site/list-construction-site-have-task', params);

export const getListTimeKeeping = (params: any) => sendGet('/v2/timekeeping/list', params);

export const approveTimekeepingV2 = (id: number, params: any) => sendPost(`/v2/timekeeping/approve/${id}`, params);
export const getListFastApprovedTimekeeping = (params: any) => sendGet('/v2/timekeeping/quick-accept', params);
export const getWorkingTime = (params: any) => sendPost('/v2/timekeeping/handle-working-time', params);
export const getTotalConstructionSitePayment = (params: any) => sendPost('/v2/timekeeping/handle-total-cost', params);

export const updateCustomSalary = (id: number, params: any) => sendPut(`/v2/timekeeping/${id}/custom-salary`, params);
export const getRemainMonthlyOvertime = (params: any) => sendPost('v2/timekeeping/view-over-time', params);

export const removeTimeKeepingForOther = (id: number) => sendPost(`/v2/timekeeping/remove/${id}`);

export const setTimeKeepingRest = (params: any) => sendPost(`/v5/timekeeping/rest`, params);

// update break time
export const updateBreakTime = ({ timekeepingId, body }: { timekeepingId: number; body: IBodyUpdateBreakTime }) =>
  sendPut<{
    data: TypeAlertBreakTime;
  }>(`/v2/timekeeping/update-total-rest-duration/${timekeepingId}`, body);
