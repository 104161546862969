import React from 'react';
import { dateUtils } from 'common/dateUtils';
import { ITaskDetail } from 'common/interface';

interface IProps extends React.HTMLAttributes<HTMLInputElement> {
  taskDetail?: ITaskDetail;
}

function getDetailsTime(taskDetail?: ITaskDetail) {
  const isSameTimeStartAndEnd = taskDetail?.seriesFrom === taskDetail?.seriesTo;

  if (taskDetail?.workingDates?.length) {
    return taskDetail?.workingDates?.join(', ');
  }

  if (isSameTimeStartAndEnd) {
    return dateUtils.getDate(taskDetail?.seriesFrom);
  }

  return `${dateUtils.getDate(taskDetail?.seriesFrom)}-${dateUtils.getDate(taskDetail?.seriesTo)}`;
}

const DetailsTime = ({ taskDetail, ...rest }: IProps) => {
  return <div {...rest}>{getDetailsTime(taskDetail)}</div>;
};

export default DetailsTime;
