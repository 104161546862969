import { DatePicker } from 'antd';
import { TYPE_PICKER } from 'common';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import styles from './styles.module.scss';
import React from 'react';

interface IPropsDatePicker {
  onChange?: (value: Date | null, dateString: string) => void;
  value?: any;
  picker?: any;
  format?: string;
  allowClear?: boolean;
  disabled?: boolean;
  disabledDate?: (date: Moment) => boolean;
  className?: string;
  popupClassName?: string;
  getPopupContainer?: (triggerNode: any) => any;
  dropdownClassName?: string;
  minuteStep?: number;
  suffixIcon?: React.ReactNode;
  onSelect?: (date: any) => void;
  placeholderText?: string;
  hideIcon?: boolean;
  style?: React.CSSProperties;
  showToday?: boolean;
  defaultPickerValue?: Moment;
}

export const CommonDatepicker = ({
  onChange,
  value,
  picker = TYPE_PICKER.DATE,
  format = picker === TYPE_PICKER.TIME ? 'HH:mm' : 'YYYY/MM/DD',
  disabledDate,
  className,
  minuteStep,
  hideIcon = false,
  ...rest
}: IPropsDatePicker) => {
  const newValue = value ? (picker === TYPE_PICKER.TIME ? moment(value, 'HH:mm') : moment(value)) : '';

  const handleSelect = (date: any) => {
    if (TYPE_PICKER.TIME === picker) {
      onChange!(date, date.format());
    }
  };

  return (
    <DatePicker
      // @ts-ignore
      onChange={onChange}
      onSelect={handleSelect}
      className={classNames(styles.datepicker, className, {
        [styles.hideIcon]: hideIcon,
      })}
      // @ts-ignore
      value={newValue}
      picker={picker}
      format={format}
      disabledDate={disabledDate}
      placeholder={picker === TYPE_PICKER.DATE ? 'YYYY/MM/DD' : rest?.placeholderText ?? ''}
      minuteStep={minuteStep || 1}
      {...rest}
    />
  );
};
