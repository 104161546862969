import { IMetadataCustomerUpdate, ITaskDetail } from 'common/interface';
import { has } from 'lodash';
import { useMemo } from 'react';

interface IProps {
  taskDetail?: ITaskDetail;
}

export const addMissingShiftCustomer = (metadataCustomerUpdate: IMetadataCustomerUpdate) => {
  if (!metadataCustomerUpdate) return metadataCustomerUpdate;

  if (!has(metadataCustomerUpdate, 'morningShiftCustomer')) {
    metadataCustomerUpdate.morningShiftCustomer = metadataCustomerUpdate?.morningShift;
  }

  if (!has(metadataCustomerUpdate, 'afternoonShiftCustomer')) {
    metadataCustomerUpdate.afternoonShiftCustomer = metadataCustomerUpdate?.afternoonShift;
  }

  if (!has(metadataCustomerUpdate, 'eveningShiftCustomer')) {
    metadataCustomerUpdate.eveningShiftCustomer = metadataCustomerUpdate?.eveningShift;
  }

  if (!has(metadataCustomerUpdate, 'otherShiftCustomer')) {
    metadataCustomerUpdate.otherShiftCustomer = metadataCustomerUpdate?.otherShift;
  }

  return metadataCustomerUpdate;
};

const useIsAppearMetadata = ({ taskDetail }: IProps) => {
  const metadataCustomerUpdate = useMemo(() => {
    const metadata =
      taskDetail?.metadataCustomerUpdate && Object.keys(taskDetail?.metadataCustomerUpdate)
        ? (addMissingShiftCustomer(JSON.parse(taskDetail?.metadataCustomerUpdate)) as any)
        : null;

    return metadata;
  }, [taskDetail]);

  const isAppearMetadata = (key: string) => {
    return metadataCustomerUpdate && (taskDetail as any)?.[key] != ((metadataCustomerUpdate as any)?.[key] as any);
  };

  return {
    isAppearMetadata,
    metadataCustomerUpdate,
  };
};

export default useIsAppearMetadata;
