import { UserRole } from 'common';
import { IComment } from 'common/interface';
import useProfile from './useProfile';
import { ROLES_ADMIN } from 'common/const';

interface IProps {
  comment: IComment;
}

const useCheckRoleEditNote = ({ comment }: IProps) => {
  const profile = useProfile();

  const isRolesAdmin = ROLES_ADMIN?.includes(comment?.viewerRoleId);
  const isRoleCustomer = comment?.viewerIsCustomer || comment?.viewerRoleId === UserRole.CUSTOMER;
  const isMyComment = comment?.userId === profile.id;
  const isUserIsAssignedInNote = comment?.userViewNote?.some((user) => user?.userId === profile?.id);

  const isCanDeleteNote = () => {
    if (isRoleCustomer) return isMyComment;

    return isRolesAdmin;
  };

  const isCanViewActionsNote = () => {
    // const isNoteCreatedByAdmin = ROLES_ADMIN.includes(comment?.creatorRoleId);

    if (isRoleCustomer) return isMyComment || isUserIsAssignedInNote;

    return isRolesAdmin;
  };

  return { isMyComment, isCanViewActionsNote, isCanDeleteNote };
};

export default useCheckRoleEditNote;
