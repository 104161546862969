import { dateUtils } from 'common/dateUtils';
import { ICheckedTask, ITaskDetail } from 'common/interface';
import moment, { Moment } from 'moment';
import { FormInstance } from 'rc-field-form';

export const getTimeDesire = (time: any) => {
  return typeof time === 'object' ? time?.format('HH:mm') : time || null;
};

export const getTimesDesire = (values: any) => {
  return {
    startTimeDesire: getTimeDesire(values?.startTimeDesire),
    endTimeDesire: getTimeDesire(values?.endTimeDesire),
  };
};

export const getTimesDesireUpdate = (values: any, taskDetail?: ITaskDetail) => {
  return {
    startTimeDesire:
      typeof values?.startTimeDesire === 'object'
        ? values?.startTimeDesire?.format('HH:mm')
        : values?.startTimeDesire || taskDetail?.startTimeDesire || null,

    endTimeDesire:
      typeof values?.endTimeDesire === 'object'
        ? values?.endTimeDesire?.format('HH:mm')
        : values?.endTimeDesire || taskDetail?.endTimeDesire || null,
  };
};

export const getWidth = (checked: ICheckedTask) => {
  const isCheckedShift = checked.morning || checked.afternoon || checked.evening || checked.other;

  const widthModal = isCheckedShift ? 1200 : 800;
  const spanCol1 = isCheckedShift ? 12 : 24;
  const spanCol2 = isCheckedShift ? 12 : 0;

  return {
    widthModal,
    spanCol1,
    spanCol2,
  };
};

export const getDayOffs = (form?: FormInstance) => {
  const dayOffs = form?.getFieldValue('dayOffs') || [];

  return dayOffs.map((item: { format: Moment }) => dateUtils.currentDatabaseDate(item?.format));
};

export const getDayOffsFromMetadata = (taskDetail: ITaskDetail) => {
  const metadataDayOffs = taskDetail?.metadataDayOffs ? JSON.parse(taskDetail.metadataDayOffs) : [];

  const dayOffs = (metadataDayOffs as string[])?.map((dayOff) => ({ format: moment(dayOff) }));

  return dayOffs;
};
