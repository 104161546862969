import { Checkbox, Col, Form, Radio, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { CURRENCY_UNIT, CommonStatus } from 'common';
import { MAX_LENGTH_MONEY_FORMAT, MAX_PERCENT_FORMAT } from 'common/const';
import { CommonInput } from 'components/CommonInput';
import CommonRadioGroup from 'components/CommonRadioGroup';
import LabelRequired from 'components/Form/LabelRequired';

import { getValueInputSymbol } from 'helper';
import { useRulesForm } from 'hooks/useRulesForm';
import React from 'react';
import { NumericFormat } from 'react-number-format';

interface IProps {
  form: FormInstance;
  disabledAll?: boolean;
}

const FormServiceCharge = ({ form, disabledAll }: IProps) => {
  const { rulesForm } = useRulesForm();

  const resetValueServiceCharge = () => {
    form.resetFields([
      'serviceChargeValue',
      'serviceChargePercent',
      'checkboxServiceChargeValue',
      'checkboxServiceChargePercent',
    ]);
  };

  const handleChangePercent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = getValueInputSymbol(e.target.value, false);

    if (value && +value > 100) {
      form.setFieldsValue({ serviceChargePercent: '100%' });
    }
  };

  return (
    <Row gutter={[20, 10]}>
      <Col span={24}>
        <Form.Item
          label="手数料"
          name="serviceCharge"
          rules={[rulesForm().required]}
          initialValue={CommonStatus.ACTIVE}
          className="mb-0"
        >
          <CommonRadioGroup
            onChange={(e) => {
              const value = e.target.value;

              if (value === CommonStatus.INACTIVE) {
                resetValueServiceCharge();
              }
            }}
            disabled={disabledAll}
          >
            <Radio value={CommonStatus.INACTIVE}>無</Radio>
            <Radio value={CommonStatus.ACTIVE}>有</Radio>
          </CommonRadioGroup>
        </Form.Item>
      </Col>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const serviceCharge = getFieldValue('serviceCharge');

          if (!serviceCharge) return null;

          return (
            <>
              <Col span={24}>
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <LabelRequired label="種類" />
                  </Col>
                  <Col span={12}>
                    <LabelRequired label="値" />
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const serviceCharge = getFieldValue('serviceCharge');
                        const checkboxServiceChargePercent = getFieldValue('checkboxServiceChargePercent');

                        return (
                          <Form.Item
                            className="mb-0"
                            name="checkboxServiceChargeValue"
                            valuePropName="checked"
                            rules={[rulesForm().dynamicRequired(!checkboxServiceChargePercent)]}
                            dependencies={['checkboxServiceChargePercent']}
                          >
                            <Checkbox
                              onChange={(e) => {
                                const value = e.target.checked;

                                // Reset value when uncheck
                                if (!value) {
                                  form.resetFields(['serviceChargeValue']);
                                }
                              }}
                              disabled={!serviceCharge || disabledAll}
                            >
                              固定
                            </Checkbox>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const checkboxServiceChargeValue = Boolean(getFieldValue('checkboxServiceChargeValue'));

                        return (
                          <Form.Item
                            className="mb-0"
                            name="serviceChargeValue"
                            rules={[
                              rulesForm().dynamicRequired(checkboxServiceChargeValue),
                              rulesForm().checkPositiveNumberBiggerThanZero({ isHavePrefix: true }),
                            ]}
                            validateFirst
                          >
                            <NumericFormat
                              customInput={CommonInput}
                              allowNegative={false}
                              thousandSeparator=","
                              prefix={CURRENCY_UNIT.YEN}
                              disabled={disabledAll || !checkboxServiceChargeValue || disabledAll}
                              maxLength={MAX_LENGTH_MONEY_FORMAT}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={24} className="mt-10">
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const serviceCharge = getFieldValue('serviceCharge');

                        const checkboxServiceChargeValue = getFieldValue('checkboxServiceChargeValue');

                        return (
                          <Form.Item
                            className="mb-0"
                            name="checkboxServiceChargePercent"
                            valuePropName="checked"
                            rules={[rulesForm().dynamicRequired(!checkboxServiceChargeValue)]}
                            dependencies={['checkboxServiceChargeValue']}
                          >
                            <Checkbox
                              onChange={(e) => {
                                const value = e.target.checked;

                                // Reset value when uncheck
                                if (!value) {
                                  form.resetFields(['serviceChargePercent']);
                                }
                              }}
                              disabled={!serviceCharge || disabledAll}
                            >
                              <span>プロジェクト金額の割</span>
                            </Checkbox>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const checkboxServiceChargeValue = Boolean(getFieldValue('checkboxServiceChargePercent'));
                        const serviceChargePercent = getFieldValue('serviceChargePercent');

                        const maxLength = String(serviceChargePercent)?.includes('.')
                          ? MAX_LENGTH_MONEY_FORMAT
                          : MAX_PERCENT_FORMAT;

                        return (
                          <Form.Item
                            className="mb-0"
                            name="serviceChargePercent"
                            rules={[
                              rulesForm().dynamicRequired(checkboxServiceChargeValue),
                              rulesForm().checkPositiveNumberBiggerThanZero({ isHaveSuffix: true }),
                            ]}
                            required={false}
                            validateFirst
                          >
                            <NumericFormat
                              customInput={CommonInput}
                              allowNegative={false}
                              thousandSeparator=","
                              suffix={CURRENCY_UNIT.PERCENT}
                              disabled={disabledAll || !checkboxServiceChargeValue || disabledAll}
                              onChange={handleChangePercent}
                              maxLength={maxLength}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </>
          );
        }}
      </Form.Item>
    </Row>
  );
};

export default FormServiceCharge;
