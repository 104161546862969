import { Checkbox, Col, Form, Radio, Row } from 'antd';
import { SalaryType, STATUS } from 'common';
import { ListIncomeTax, ListIncomeTax1, ListMonth, listUnemploymentInsuranceType } from 'common/const';
import { CommonInputNumber } from 'components/CommonInputNumber';
import { CommonSelect } from 'components/CommonSelect';

interface IProps {
  disable: boolean;
  listInsurance: any;
}
export const InsuranceItem = ({ disable, listInsurance }: IProps) => {
  return (
    <>
      <Col span={12}>
        <Row gutter={10}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const salaryType = getFieldValue(['metadata', 'salaryType']);
              if (salaryType === SalaryType.MONTH_SALARY) return null;

              return (
                <Col span={24}>
                  <Form.Item
                    label="社会保険加入"
                    name={['metadata', 'isInsurrance']}
                    rules={[
                      {
                        required: disable ? false : true,
                        message: '全ての項目を入力してください。',
                      },
                    ]}
                  >
                    <Radio.Group disabled={disable}>
                      <Radio value={STATUS.INACTIVE}>いいえ</Radio>
                      <Radio value={STATUS.ACTIVE}>はい</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const isHealthInsurance = getFieldValue(['metadata', 'isHealthInsurrance']);
              const salaryType = getFieldValue(['metadata', 'salaryType']);
              const isInsurance = getFieldValue(['metadata', 'isInsurrance']);

              if (!isInsurance && salaryType !== SalaryType.MONTH_SALARY) return null;

              return (
                <>
                  <Col span={6}>
                    <Form.Item label=" " name={['metadata', 'isHealthInsurrance']} valuePropName="checked">
                      <Checkbox disabled={disable}>手入力</Checkbox>
                    </Form.Item>
                  </Col>

                  {isHealthInsurance ? (
                    <Col span={9}>
                      <Form.Item
                        label="健康保険 等級"
                        name={['metadata', 'healthInsurance']}
                        rules={[
                          {
                            required: disable ? false : true,
                            message: '全ての項目を入力してください。',
                          },
                        ]}
                      >
                        <CommonInputNumber disabled={disable} />
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={9}>
                      <Form.Item
                        label="健康保険 等級"
                        name={['metadata', 'indexHealthInsurance']}
                        rules={[
                          {
                            required: disable ? false : true,
                            message: '全ての項目を入力してください。',
                          },
                        ]}
                      >
                        <CommonSelect
                          options={listInsurance}
                          optionValue="index"
                          optionLabel="range"
                          disabled={disable}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={9}>
                    <Form.Item
                      label="雇用保険二事業の保険"
                      name={['metadata', 'isDisableUnemploymentInsurance']}
                      // name={['metadata', 'unemploymentInsuranceType']}
                      rules={[
                        {
                          required: disable ? false : true,
                          message: '全ての項目を入力してください。',
                        },
                      ]}
                    >
                      <CommonSelect options={listUnemploymentInsuranceType} disabled={disable} />
                    </Form.Item>
                  </Col>
                </>
              );
            }}
          </Form.Item>
        </Row>
      </Col>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const salaryType = getFieldValue(['metadata', 'salaryType']);
          const listIncome: any = {
            [SalaryType.HOUR_SALARY]: ListIncomeTax1,
            [SalaryType.SHIFT_SALARY]: ListIncomeTax,
            [SalaryType.MONTH_SALARY]: ListIncomeTax1,
          };

          return (
            <Col span={6}>
              <Form.Item
                label="源泉徴収の対象"
                name={['metadata', 'typeIncomeTax']}
                rules={[
                  {
                    required: !disable,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonSelect options={listIncome[salaryType]} disabled={disable} />
              </Form.Item>
            </Col>
          );
        }}
      </Form.Item>

      <Col span={6}>
        <Form.Item
          label="扶養数"
          name={['metadata', 'numberOfDependents']}
          rules={[
            {
              required: !disable,
              message: '全ての項目を入力してください。',
            },
          ]}
          labelAlign="left"
        >
          <CommonInputNumber disabled={disable} />
        </Form.Item>
      </Col>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const isPensionInsurrance = getFieldValue(['metadata', 'isPensionInsurrance']);
          const salaryType = getFieldValue(['metadata', 'salaryType']);
          const isInsurance = getFieldValue(['metadata', 'isInsurrance']);

          if (!isInsurance && salaryType !== SalaryType.MONTH_SALARY) return null;
          return (
            <>
              <Col span={12}>
                <Row gutter={10}>
                  <Col span={6}>
                    <Form.Item label=" " name={['metadata', 'isPensionInsurrance']} valuePropName="checked">
                      <Checkbox disabled={disable}>手入力</Checkbox>
                    </Form.Item>
                  </Col>
                  {isPensionInsurrance ? (
                    <Col span={18}>
                      <Form.Item
                        label="厚生年金保険料"
                        name={['metadata', 'pensionInsurance']}
                        rules={[
                          {
                            required: disable ? false : true,
                            message: '全ての項目を入力してください。',
                          },
                        ]}
                      >
                        <CommonInputNumber disabled={disable} />
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={18}>
                      <Form.Item
                        label="厚生年金保険料"
                        name={['metadata', 'indexPensionInsurance']}
                        rules={[
                          {
                            required: disable ? false : true,
                            message: '全ての項目を入力してください。',
                          },
                        ]}
                      >
                        <CommonSelect
                          options={listInsurance}
                          optionValue="index"
                          optionLabel="range"
                          disabled={disable}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
            </>
          );
        }}
      </Form.Item>

      <Col span={6}>
        <Form.Item
          label="住民税徴収方法"
          name={['metadata', 'isResidenceTax']}
          rules={[
            {
              required: !disable,
              message: '全ての項目を入力してください。',
            },
          ]}
          labelAlign="left"
        >
          <Radio.Group disabled={disable}>
            <Radio value={STATUS.ACTIVE}>特別徴収</Radio>
            <Radio value={STATUS.INACTIVE}>普通徴収</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const isResidenceTax = getFieldValue(['metadata', 'isResidenceTax']);
          if (isResidenceTax) {
            return (
              <>
                <Col span={6}>
                  <Form.Item
                    label="住民税額設定"
                    name={['metadata', 'isSimpleMonth']}
                    rules={[
                      {
                        required: !disable,
                        message: '全ての項目を入力してください。',
                      },
                    ]}
                    labelAlign="left"
                  >
                    <Radio.Group disabled={disable}>
                      <Radio value={STATUS.ACTIVE}>簡易設定</Radio>
                      <Radio value={STATUS.INACTIVE}>月別設定</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <label className="mb-5">住民税徴収方法</label>
                  <Row gutter={10}>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const isSimpleMonth = getFieldValue(['metadata', 'isSimpleMonth']);

                        return !isSimpleMonth ? (
                          <>
                            <MonthItem disable={disable} name="6" month="6月" />
                            <MonthItem disable={disable} name="12" month="12月" />
                            <MonthItem disable={disable} name="7" month="7月" />
                            <MonthItem disable={disable} name="1" month="1月" />
                            <MonthItem disable={disable} name="8" month="8月" />
                            <MonthItem disable={disable} name="2" month="2月" />
                            <MonthItem disable={disable} name="9" month="9月" />
                            <MonthItem disable={disable} name="3" month="3月" />
                            <MonthItem disable={disable} name="10" month="10月" />
                            <MonthItem disable={disable} name="4" month="4月" />
                            <MonthItem disable={disable} name="11" month="11月" />
                            <MonthItem disable={disable} name="5" month="5月" />

                            <Col span={12} className="text-bold">
                              住民税年額
                            </Col>
                            <Form.Item shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                const month1 = getFieldValue(['metadata', '1']) || 0;
                                const month2 = getFieldValue(['metadata', '2']) || 0;
                                const month3 = getFieldValue(['metadata', '3']) || 0;
                                const month4 = getFieldValue(['metadata', '4']) || 0;
                                const month5 = getFieldValue(['metadata', '5']) || 0;
                                const month6 = getFieldValue(['metadata', '6']) || 0;
                                const month7 = getFieldValue(['metadata', '7']) || 0;
                                const month8 = getFieldValue(['metadata', '8']) || 0;
                                const month9 = getFieldValue(['metadata', '9']) || 0;
                                const month10 = getFieldValue(['metadata', '10']) || 0;
                                const month11 = getFieldValue(['metadata', '11']) || 0;
                                const month12 = getFieldValue(['metadata', '12']) || 0;

                                const total =
                                  Number(month1) +
                                  Number(month2) +
                                  Number(month3) +
                                  Number(month4) +
                                  Number(month5) +
                                  Number(month6) +
                                  Number(month7) +
                                  Number(month8) +
                                  Number(month9) +
                                  Number(month10) +
                                  Number(month11) +
                                  Number(month12);

                                return (
                                  <Col span={12} className="text-bold">
                                    {total}円
                                  </Col>
                                );
                              }}
                            </Form.Item>
                          </>
                        ) : (
                          <>
                            <Col span={8}>
                              <Form.Item name={['metadata', 'month']} label={false}>
                                <CommonSelect options={ListMonth} disabled={disable} />
                              </Form.Item>
                            </Col>
                            <Col span={16}>
                              <Form.Item
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                name={['metadata', 'fitstMonthTax']}
                                label={'の金額'}
                                rules={[
                                  {
                                    required: true,
                                    message: '全ての項目を入力してください。',
                                  },
                                ]}
                                style={{
                                  flexDirection: 'row',
                                }}
                              >
                                <CommonInputNumber />
                              </Form.Item>
                            </Col>

                            <Col span={8}>
                              <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                  const month = getFieldValue(['metadata', 'month']);

                                  if (month < 12) return <>{month + 1}月以降</>;
                                  if (month === 12) return <>1月以降</>;
                                }}
                              </Form.Item>
                            </Col>

                            <Col span={16}>
                              <Form.Item
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                name={['metadata', 'remainMonthTax']}
                                label={'の金額'}
                                rules={[
                                  {
                                    required: true,
                                    message: '全ての項目を入力してください。',
                                  },
                                ]}
                                style={{
                                  flexDirection: 'row',
                                }}
                              >
                                <CommonInputNumber />
                              </Form.Item>
                            </Col>

                            <Col span={12} className="text-bold">
                              住民税年額
                            </Col>

                            <Form.Item shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                const firstMonthTax = getFieldValue(['metadata', 'fitstMonthTax']) ?? 0;
                                const remainMonthTax = getFieldValue(['metadata', 'remainMonthTax']) ?? 0;

                                return (
                                  <Col span={12} className="text-bold">
                                    {firstMonthTax + remainMonthTax * 11}円
                                  </Col>
                                );
                              }}
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                </Col>
              </>
            );
          }
        }}
      </Form.Item>
    </>
  );
};

interface IMonthItem {
  disable: boolean;
  name: string;
  month: string;
}
const MonthItem = ({ disable, name, month }: IMonthItem) => {
  return (
    <>
      <Col span={4}>{month}</Col>
      <Col span={8}>
        <Form.Item
          label={false}
          name={['metadata', name]}
          rules={[
            {
              required: !disable,
              message: '全ての項目を入力してください。',
            },
          ]}
          labelAlign="left"
        >
          <CommonInputNumber disabled={disable} />
        </Form.Item>
      </Col>
    </>
  );
};
