export enum STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum NUMBER_PERCENT_TAX {
  DEFAULT = 0.1,
  CHECKED = 0.08,
}

export enum PERCENT_TAX {
  DEFAULT = 10,
  CHECKED = 8,
}

export enum WORKSSTATUS {
  REJECT = 0,
  PENDING = 1,
  APPROVED = 2,
}

export enum TYPE_INPUT {
  MULTIPLE = 'multiple',
}

export enum TYPE_PICKER {
  TIME = 'time',
  DATE = 'date',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  DAY = 'day',
}

export enum CURRENCY {
  SYMBOL = '¥',
  UNIT = '円',
}

export enum Environments {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}
export enum Permissions {
  LANGUAGE = 1,
  PERMISSION = 2,
  RESOURCE = 3,
  USER = 4 /* Super admin manager all user */,
  /** Quản lỉ tất cả các role từ manager trở xuống */
  STAFF = 5,
  CONSTRUCTION_SITE = 6,
  VENDOR = 7,
  PAPER = 8,
  CUSTOMER = 9,
}
export enum Action {
  VIEW = 1,
  EDIT = 2,
}
export enum CommonStatus {
  ACTIVE = 1,
  INACTIVE = 0,
  NOT_VERIFY = 2,
  REJECT_VERIFY = 3,
}
export enum UserStatus {
  DELETED = -1,
  BLOCKED = 0,
  ACTIVE = 1,
  NOT_VERIFY = 2,
  REJECT_VERIFY = 3,
  SUSPEND = 4,
}
export enum UserState {
  ACTIVE = 1,
  ADMIN_WAS_BLOCKED = 2,
  ADMIN_EXPIRE_PURCHASE = 3,
}
export enum Gender {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}
export enum UserRole {
  /** System admin */
  SUPER_ADMIN = 1,
  /** End user Admin */
  ADMIN = 2,
  /** End user Manager */
  MANAGER = 3,
  OFFICE_MANAGER = 4,
  /** End user leader */
  LEADER = 5,
  /** End user vendor manager */
  VENDOR_MANAGER = 6, // Role này dã bị xóa.
  /** End user vendor */
  VENDOR = 7,
  /** End user Customer */
  CUSTOMER = 8,
  /** End user worker */
  VENDOR_WORKER = 9,
  /** Admin or Manager hire worker part time*/
  PART_TIME_WORKER = 10,
  OTHER_WORKER = 11, // Free Lancer
  WORKER = 12, // staff
}
export const ONLY_MANAGER_ROLES = [UserRole.MANAGER, UserRole.OFFICE_MANAGER, UserRole.VENDOR_MANAGER];

export enum UserType {
  SYSTEM = 1,
  USER = 2,
}
export enum KeyQueue {
  SEND_MAIL = 'SEND_MAIL',
  PUSH_NOTIFICATION = 'notify',
}
export enum VerificationType {
  REGISTER = 1,
  FORGOT_PASSWORD = 2,
}
export enum VerificationCodeStatus {
  ACTIVE = 1,
  USED = 0,
}
export enum FileType {
  /** Bằng cấp, chứng chỉ */
  USER_CERTIFICATION = 1,
  CONSTRUCTION_SITE_FILE = 2,
}
export enum ObjectType {
  USER = 1,
  CONSTRUCTION_SITE = 2,
}
export enum ConstructionSiteMemberType {
  VENDOR = 1,
  WORKER = 2,
}
export enum ConstructionSiteState {
  NOT_WORKING = 0,
  COMING_TO_WORK = 2,
  WORKING = 1,
  PAUSE = 3,
  COMPLETED = 4,
}

export enum UserPaperType {
  ADMIN = 1,
  VENDOR = 2,
  CUSTOMER = 3,
}

export enum ConstructionSiteStatus {
  ACTIVE = 1,
  INACTIVE = 0,
  NOT_VERIFY = 2,
  REJECT = 3,
}
export enum RepeatType {
  NOT_REPEAT = 0,
  EVERY_DAY = 1,
  EVERY_WEEK = 2,
  EVERY_MONTH = 3,
  EVERY_YEAR = 4,
}
// Status In UserVendor
export enum VendorStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}
export enum PaymentRequestStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  REQUESTING = 2,
  REJECT = 3,
  ALL = -1,
}

export enum SalaryType {
  HOUR_SALARY = 1,
  MONTH_SALARY = 3,
  CONSTRUCTION_SALARY = 4,
  SHIFT_SALARY = 5,
}

export enum SearchVendorOption {
  /**Tìm những vendor trong cùng công ty */
  CONSTRUCTION_SITE = 'CONSTRUCTION_SITE',
}
export enum TimeKeepingStatus {
  DELETED = 0,
  ACCEPTANCE_REPORT = 1,
  REQUESTING = 2,
  REJECTED = 3,
}
export enum TimeKeepingType {
  REQUEST = 1,
  REQUEST_OT = 2,
  REQUEST_FOR_OTHER_USER = 3,
  REQUEST_OT_FOR_OTHER_USER = 4,
}

export enum TimeKeepingState {
  NORMAL = 0,
  EARLY = 1,
  LATE = 2,
}

export enum SignUpStatus {
  SIGN_UP_ADMIN = 1,
  SIGN_UP_STAFF = 2,
}

export enum ScheduleType {
  TIME_OFF = 0,
  REGISTERED = 1,
  UN_REGISTERED = 2,
}

export enum ScheduleStatus {
  REJECT = 0,
  ACCEPT = 1,
  PENDING = 2,
  DELETED = 3,
}

export enum ScheduleState {
  OFF_WORK = 0,
  WORK = 1,
}

export enum CompanyStatus {
  ACTIVE = 1,
  INACTIVE = 0,
  NOT_VERIFY = 2,
  REJECT_VERIFY = 3,
  ADMIN_EXPIRE_PURCHASE = 4,
  APPROVED = 5,
}

export enum CompanyScheduleDay {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum EndOfMonth {
  END_OF_MONTH = 0,
}
export enum PaymentHistoryType {
  BUY_SUBSCRIPTION = 1,
  BUY_EXCEED_MEMBER = 2,
  RECURRING_SUBSCRIPTION = 3,
  ADMIN_UPDATE_COMPANY_SUBSCRIPTION = 4,
}

export enum ConstructionSiteType {
  LABOR = 1,
  FIXED_PRICE = 2,
}

export enum ShipOfDay {
  MORNING = 1,
  AFTERNOON = 2,
  EVENING = 3,
  OTHER = 4,
}

export enum PaperType {
  PAYMENT_REQUEST_LABOR = 1,
  PAYMENT_REQUEST_FIXED_PRICE = 2,
  INVOICE = 3,
  ESTIMATION = 4,
  ACCEPTANCE_CONSTRUCTION_SITE_LABOR = 5,
  ACCEPTANCE_CONSTRUCTION_SITE_FIXED_PRICE = 6,
  CREATE_PAYMENT_REQUEST_FOR_OTHER_WORKER = 7,
  PAYMENT_RECEIPT = 8,
  PAYMENT_ALL = -1,
}
export enum PaperSystemType {
  PAYMENT_REQUEST = 'paymentRequest',
  INVOICE = 'invoice',
  ESTIMATION = 'estimatePrice',
  ACCEPTANCE_CONSTRUCTION_SITE = 'acceptance',
  CREATE_PAYMENT_REQUEST_FOR_OTHER_WORKER = 'paymentRequestForOtherWorker',
  PAYMENT_RECEIPT = 'paymentReceipt',
}

export enum PaperStatus {
  DELETED = 0,
  ACTIVE = 1,
  PENDING = 2,
  REJECTED = 3,
  DRAFT = 10,
  ALL = -1,
}

export enum PaymentStatus {
  ALL = -1,
  PAID = 1,
  UN_PAID = 0,
}

export enum PaymentStatusCompany {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum UpdateTaskMode {
  ONE = 1,
  ALL = 2,
  FUTURE = 3,
}

export enum ErrorInvoiceType {
  DRAFT = 1,
  NOT_PAYMENT_REQUEST = 2,
  NOT_APPROVAL = 3,
}

export enum SHIFT_TASK_SEARCH_CONSTRUCTION {
  MORNING = 1,
  AFTERNOON = 2,
  EVENING = 3,
  OTHER = 4,
}

export enum SHIFT_TASK {
  MORNING = 1,
  AFTERNOON = 2,
  EVENING = 3,
  OTHER = 4,
}

export enum TimeOffStatus {
  DELETED = 0,
  REQUESTING = 2,
  ACCEPT = 1,
  REJECT = 3,
}

export enum TimeOffType {
  FULL_SALARY = 1,
  NO_SALARY = 0,
}

export enum TimeOffMode {
  HALF = 1,
  FULL = 2,
}

export enum SALARY_DELAY_MONTH {
  NOW = 'now',
  NEXT_MONTH = 'next month',
  TWO_MONTH_LATER = 'two month later',
}

export enum HolidayTargetType {
  /**For all users in company */
  ALL = 1,
  /**For specific role */
  ROLE = 2,
  /**For specific user */
  USER = 3,
}

export enum UserConfigTimeOff {
  WORKING_LEVEL_1 = '0.5',
  WORKING_LEVEL_2 = '1.5',
  WORKING_LEVEL_3 = '2.5',
  WORKING_LEVEL_4 = '3.5',
  WORKING_LEVEL_5 = '4.5',
  WORKING_LEVEL_6 = '5.5',
  WORKING_LEVEL_7 = '6.5 以上',
}

export enum HolidayRepeatType {
  NO_REPEAT = 0,
  EVERY_WEEK = 1,
  EVERY_YEAR = 2,
}

export enum HolidayMode {
  HALF = 1,
  FULL = 2,
}

export enum HolidayStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum TimeKeepingOvertimeType {
  /**Overtime ngày thường */
  COMMON = 1,
  /**Overtime ngày lễ */
  HOLIDAY = 2,
  /**Overtime Cuối tuần */
  WEEKEND = 3,
}

export enum UnemploymentInsurranceType {
  GENERAL = 1,
  CONSTRUCTION = 2,
  OTHER = 3,
}

export enum InsurranceTextType {
  SICOAL = 1,
  UNEMPLOYMENT = 2,
}

export enum ModeCalendar {
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
  YEAR = 4,
}

export enum SubscriptionType {
  STRIPE = 1,
  ADMIN_BUY = 2,
  PENDING = 3,
}

export enum HolidayType {
  COMMON = 1,
  IMPORT = 2,
}

export enum TypeIncomeTax {
  A = 1,
  B = 2,
  C = 3,
}

export enum TaxType {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum ModeType {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum TypeFixedCost {
  REGULAR = 1,
  IRREGULAR = 2,
}

export enum TypePaymentTerm {
  EVERY_DAY = 1,
  EVERY_WEEK = 2,
  EVERY_MONTH = 3,
}

export enum TypeViewMissing {
  PAID = 0,
}

export enum COEFFICIENT {
  COEFFICIENT_0 = 0,
  COEFFICIENT_1 = 1,
}

export enum TABLE_CREATE {
  TOTAL_WORKER = 'total_worker',
  COEFFICIENT = 'coefficient',
  UNIT_PRICE = 'unit_price',
  TRANSPORT_FEE = 'transport_fee',
  OTHER_PRICE = 'other_price',
  NOTE = 'note',
  NAME = 'name',
  START_DATE = 'startDate',
}

export enum TABLE_FIX_PRICE {
  CONTACT_VALUE = 'contract_value',
  NOTE = 'note',
  NAME = 'name',
}
export enum END_NODE {
  ACTIVE = 1,
  IN_ACTIVE = 0,
}

export enum REDUCE_FEE {
  UNEMPLOYMENT_INSURANCE = 1,
  PERSONAL_INCOME_TAX = 2,
}

export enum WORKING_TIME {
  MORNING = 1,
  AFTERNOON = 2,
  EVENING = 3,
  OTHER = 4,
}
export enum SUB_NAV {
  ACTIVE = 1,
  IN_ACTIVE = 0,
}

export enum ROUND {
  TEN = 10,
  THOUSAND = 1000,
}

export enum PAGE_SIZE {
  PAGE_SIZE_10 = 10,
  PAGE_SIZE_20 = 20,
  PAGE_SIZE_100 = 100,
  BIG_PAGE_SIZE = 10000,
}

export enum UserVendorWorkingType {
  ALL_DAY = 1,
  MORNING = 2,
  AFTERNOON = 3,
  NIGHT = 4,
  OTHER = 5,
  EMPTY = 6,
}

export enum STATUS_MODAl {
  OPEN = '1',
}

export enum BlockPayroll {
  CHECKED = 1,
  UNCHECKED = 0,
}

export const STATUS_SALARY = {
  ACTIVE: 0,
  UN_ACTIVE: 1,
  ALL: 'all',
};

export enum CompanyBusinessField {
  GENERAL = 1, // 一般の事業

  // Agricultural, forestry or Sake production
  // AFS = 2, // 農林水 清酒製造の事業

  CONSTRUCTION = 3, // 建設の事業
}

export enum ReserveStatus {
  RESERVE = 1,
  NO_RESERVE = 0,
}

export enum PaymentRequestState {
  REVENUE = 1,
  COST = 2,
}

export enum TypeViewRevenueCost {
  MONTH = 1,
  YEAR = 2,
}

export enum TypeSalary {
  'TOTAL_STANDARD_WORKING_DAY' = 'TOTAL_STANDARD_WORKING_DAY',
  'TOTAL_STANDARD_WORKING_HOUR' = 'TOTAL_STANDARD_WORKING_HOUR',
  'TOTAL_ACTUAL_WORKING_DAY' = 'TOTAL_ACTUAL_WORKING_DAY',
  'TOTAL_ACTUAL_WORKING_HOUR' = 'TOTAL_ACTUAL_WORKING_HOUR',
  'TOTAL_MORNING_SHIFT' = 'TOTAL_MORNING_SHIFT',
  'TOTAL_AFTERNOON_SHIFT' = 'TOTAL_AFTERNOON_SHIFT',
  'TOTAL_EVENING_SHIFT' = 'TOTAL_EVENING_SHIFT',
  'TOTAL_ACTUAL_WORKING_SHIFT' = 'TOTAL_ACTUAL_WORKING_SHIFT',
  'TOTAL_HOUR_DAY_OFF_FULL_SALARY' = 'TOTAL_HOUR_DAY_OFF_FULL_SALARY',
  'TOTAL_DAY_OFF_NO_SALARY' = 'TOTAL_DAY_OFF_NO_SALARY',
  'TOTAL_MINUTE_LATE_OR_EARLY' = 'TOTAL_MINUTE_LATE_OR_EARLY',
  'TOTAL_HOUR_OT_COMMON' = 'TOTAL_HOUR_OT_COMMON',
  'TOTAL_HOUR_OT_HOLIDAY' = 'TOTAL_HOUR_OT_HOLIDAY',
  'TOTAL_HOUR_OT_WEEKEND' = 'TOTAL_HOUR_OT_WEEKEND',
  'TOTAL_HOUR_OT_NIGHT_SHIFT' = 'TOTAL_HOUR_OT_NIGHT_SHIFT',
  'TOTAL_HOLIDAY' = 'TOTAL_HOLIDAY',
  'TIME_SHEET_CUSTOMIZE' = 'TIME_SHEET_CUSTOMIZE',
  'AVG_ONE_HOUR_SALARY' = 'AVG_ONE_HOUR_SALARY',
  'TOTAL_BASIC_SALARY' = 'TOTAL_BASIC_SALARY',
  'OVERTIME_SALARY_COMMON' = 'OVERTIME_SALARY_COMMON',
  'OVERTIME_SALARY_WEEKEND' = 'OVERTIME_SALARY_WEEKEND',
  'OVERTIME_SALARY_HOLIDAY' = 'OVERTIME_SALARY_HOLIDAY',
  'OVERTIME_SALARY_NIGHT_SHIFT' = 'OVERTIME_SALARY_NIGHT_SHIFT',
  'TOTAL_SALARY_DAY_OFF' = 'TOTAL_SALARY_DAY_OFF',
  'TOTAL_SUBTRACT_SALARY_EARLY_OR_LATE' = 'TOTAL_SUBTRACT_SALARY_EARLY_OR_LATE',
  'TOTAL_SALARY_BEFORE_TAX' = 'TOTAL_SALARY_BEFORE_TAX',
  'TOTAL_TAX_AND_INSURANCE' = 'TOTAL_TAX_AND_INSURANCE',
  'HEAL_INSURANCE' = 'HEAL_INSURANCE',
  'PERSONAL_INSURANCE' = 'PERSONAL_INSURANCE',
  'UNEMPLOYMENT_INSURANCE' = 'UNEMPLOYMENT_INSURANCE',
  'PERSONAL_INCOME_TAX' = 'PERSONAL_INCOME_TAX',
  'RESIDENT_TAX' = 'RESIDENT_TAX',
  'TOTAL_SALARY' = 'TOTAL_SALARY',
  'SALARY_AND_SUBSIDIZE_CUSTOMIZE' = 'SALARY_AND_SUBSIDIZE_CUSTOMIZE',
  'TAX_AND_INSURANCE_CUSTOMIZE' = 'TAX_AND_INSURANCE_CUSTOMIZE',
  'NOTE' = 'NOTE',
  'BASIC_SALARY' = 'BASIC_SALARY',
  'TOTAL_SALARY_FROM_TIMEKEEPING' = 'TOTAL_SALARY_FROM_TIMEKEEPING',
  'TOTAL_HARD_WORK_SUBSIDY' = 'TOTAL_HARD_WORK_SUBSIDY',
  'TOTAL_COMPANY_SUBSIDY' = 'TOTAL_COMPANY_SUBSIDY',
  'TOTAL_COST_REQUEST' = 'TOTAL_COST_REQUEST',
}

export enum DAY_OF_WEEK {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum MONTH_OF_YEAR {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

export enum UserScheduleType {
  WEEK = 1,
  MONTH = 2,
}

export enum KeySQL {
  id = 'q',
  constructionSiteId = 'w',
  status = 'e',
  createdAt = 'r',
  name = 't',
  maxWorker = 'y',
  maxWorkerEvening = 'u',
  maxWorkerOther = 'i',
  workerMorning = 'o',
  workerAfternoon = 'p',
  workerEvening = 'a',
  workerOther = 's',
  startDate = 'd',
  startTime = 'f',
  endTime = 'g',
  createdBy = 'h',
  morningShift = 'j',
  afternoonShift = 'k',
  eveningShift = 'l',
  otherShift = 'z',
  isAdminUpdate = 'x',
  metadataCustomerUpdate = 'c',
  reserveStatus = 'v',
  companyId = 'b',
  address = 'n',
  constructionSiteName = 'm',
  representativeName = '1',
  totalWorker = '2',
  customerName = '3',
  customerCompanyName = '4',
  creatorName = '5',
  creatorRoleId = '6',
  isCustomer = '7',
  avatar = '8',
  taskId = '9',
  note = 'qq',
  totalVendor = 'qw',
  leaderId = 'qe',
  leaderName = 'qr',
  description = 'qt',
  morningFrom = 'qy',
  morningTo = 'qu',
  afternoonFrom = 'qi',
  afternoonTo = 'qo',
  eveningFrom = 'qp',
  eveningTo = 'qa',
  otherFrom = 'qs',
  otherTo = 'qd',
  station = 'qf',
  seriesFrom = 'qg',
  seriesTo = 'qh',
  maxWorkerMissing = 'qj',
  maxWorkerEveningMissing = 'qk',
  maxWorkerOtherMissing = 'ql',
  groupId = 'qz',
  startTimeDesire = 'qx',
  endTimeDesire = 'qc',
  countVendor = 'qv',
  startTimeAfternoonDesire = 'qb',
  endTimeAfternoonDesire = 'qn',
  startTimeEveningDesire = 'qm',
  endTimeEveningDesire = 'wq',
  startTimeOtherDesire = 'ww',
  endTimeOtherDesire = 'we',
  taskRoleId = 'wr',
  taskCompanyName = 'wt',
  maxWorkerMorning = 'wy',
  maxWorkerAfternoon = 'wu',
  customerId = 'wi',
  price = 'wo',
  workerPrice = 'wp',
  vendorCompanyName = 'wa',
  constructionSiteType = 'ws',
  vendorWorkerInfo = 'wd',
  metadataCustomerUnitPriceCertificate = 'wf',
  metadataUnitPriceCertificate = 'wg',
  constructionSiteAddress = 'wh',
  constructionSiteLeaderId = 'wj',
  constructionSiteMembers = 'wk',
  constructionSiteStation = 'wl',
  userId = 'wz',
  roleId = 'wx',
  managerId = 'wc',
  taskManagerId = 'wv',
  userViewRoleId = 'wb',
  dataVendorView = 'wn',
  isFileTask = 'wm',
  morningShiftCustomer = 'eq',
  afternoonShiftCustomer = 'ew',
  eveningShiftCustomer = 'ee',
  otherShiftCustomer = 'er',
  isAutoCreateTask = 'et',
  customerNickname = 'ey',
  vendorCompanyNickname = 'eu',
}
export enum KEY_SIDE_BAR {
  CALENDAR = '1',
  REGISTER_SHIFT = '2',
  WORK_CALENDAR = '3',
  SALARY_V4 = '4',
  REPORT_REVENUE = '5',
  PAPER = '6',
  STAFF = '7',
  CUSTOMER = '8',
  VENDOR = '9',
  ACCOUNT = '11',
  COMPANIES = '12',
  INVITES = '13',
  SUBSCRIPTION_PLAN = '14',
  INSURANCE = '15',
  OPEN_ACCOUNT = '16',
  SALARY = '17',
  CAMPAIGN = '18',
  PAYMENT_REQUEST = '19',
}

export enum MODE_SCHEDULE {
  APPROVE = 'approve',
  DETAIL = 'detail',
  UPDATE = 'update',
  CREATE = 'create',
}
export enum REGISTER_SHIFT_TABS {
  AWAITING_APPROVAL = '1',
  APPROVED = '2',
}

export enum TypeServiceCharge {
  No = 0,
  YES_VALUE = 1,
  YES_PERCENT = 2,
}

export enum CURRENCY_UNIT {
  YEN = '¥',
  PERCENT = '%',
  DOLLAR = '$',
}

export enum WorkPlace {
  IN_OFFICE = 1, // lam viec tai van phong
  OUT_SIDE_OFFICE = 2, // lam viec ngoai van phong
}

export enum TypeTaskArrangement {
  ONE_DAY = 1,
  MULTI_DAYS = 2,
}

export const ROLES_CAN_CREATE_TASK = [
  UserRole.ADMIN,
  UserRole.MANAGER,
  UserRole.OFFICE_MANAGER,
  UserRole.LEADER,
  UserRole.CUSTOMER,
];

export enum INSURANCE_TABS {
  INSURANCE = 'insurance',
  INSURANCE_RATE = 'insurance-rate',
  SOCIAL_INSURANCE = 'social-insurance',
}

export enum TypePaymentOffset {
  THIS_MONTH = 0,
  NEXT_MONTH = 1,
  NEXT_MONTH_TOO = 2,
  THIS_WEEK = 3,
  NEXT_WEEK = 4,
  NEXT_WEEK_TO = 5,
  DAY_DEFAULT = 6,
}

export enum TypeDrag {
  WORKER = 'worker',
}

export enum AvatarSize {
  SMALL = 36,
  NORMAL = 40,
  MEDIUM = 60,
  LARGE = 80,
}
export enum ShiftStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum TaskTypes {
  SMALL = 1,
  NORMAL = 2,
}
