import { Button, ButtonProps } from 'antd';
import icons from 'assets/icons';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IArrowProps extends ButtonProps {}

const PrevArrow = ({ className, ...props }: IArrowProps) => {
  return (
    <Button
      icon={<img src={icons.arrow.left} alt="" />}
      shape="circle"
      className={classNames(className, styles.btnSlide)}
      {...props}
    />
  );
};

export default PrevArrow;
