import { Col, Form, Row } from 'antd';
import { CommonModal, IPropsModal } from 'components/CommonModal';
import IconAddDateExcludeTask from '../IconAddDateExcludeTask';
import { CommonDatepicker } from 'components/CommonDatepicker';
import iconDelete from 'assets/icons/trash-2.svg';
import { useRulesForm } from 'hooks/useRulesForm';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { getFormatDateDataOther } from 'pages/Schedule/utils';

interface IProps extends IPropsModal {
  form?: FormInstance;
  handleChangeStartDate: (value: Date | null) => void;
  handleChangeEndDate: (value: Date | null) => void;
}

const ModalDateExcludeTask = ({ form, handleChangeStartDate, handleChangeEndDate, visible, onOk, ...rest }: IProps) => {
  const { rulesForm } = useRulesForm();

  const [formListDayOffs] = Form.useForm();

  const onFinish = () => {
    formListDayOffs.validateFields().then((values) => {
      form?.setFieldsValue(values);

      onOk?.(values);
    });
  };

  // set default value for startDate and endDate when open modal
  useEffect(() => {
    if (visible) {
      const startDate = form?.getFieldValue('startDate');
      const endDate = form?.getFieldValue('endDate');
      const dayOffs = form?.getFieldValue('dayOffs');

      formListDayOffs.setFieldsValue({ startDate, endDate, dayOffs });
    }
  }, [visible]);

  return (
    <CommonModal
      visible={visible}
      {...rest}
      afterClose={() => {
        // reset list dayOffs after close modal
        formListDayOffs?.resetFields(['dayOffs']);
      }}
      handleSubmit={() => {
        formListDayOffs.submit();
      }}
    >
      <Form onFinish={onFinish} autoComplete="off" form={formListDayOffs}>
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              label="開始日"
              name="startDate"
              labelAlign="left"
              rules={[rulesForm().required]}
              className="hide-supper-icon mb-form-0"
            >
              <CommonDatepicker
                //@ts-ignore
                inputReadOnly
                allowClear={false}
                onChange={handleChangeStartDate}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="終了日"
              name="endDate"
              dependencies={['startDate']}
              rules={[rulesForm().required, rulesForm().validateSameDateAndStartBeforeEnd('startDate', 'endDate')]}
              labelAlign="left"
              className="hide-supper-icon mb-form-0"
            >
              <CommonDatepicker
                //@ts-ignore
                inputReadOnly
                allowClear={false}
                onChange={handleChangeEndDate}
                placeholder="YYYY/MM/DD"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="dayOffs">
          {(fields, { add, remove }) => (
            <>
              <Form.Item className="mt-10 mb-10">
                <IconAddDateExcludeTask onClick={() => add()} />
              </Form.Item>

              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={20} align="stretch">
                  <Col flex={1}>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        return (
                          <Form.Item
                            {...restField}
                            name={[name, 'format']}
                            rules={[
                              rulesForm().required,
                              rulesForm().validateOverlapDateDayOffs(),
                              rulesForm().validateDateBetweenStartAndEndDate('startDate', 'endDate'),
                            ]}
                            validateFirst
                          >
                            <CommonDatepicker
                              //@ts-ignore
                              // format date -> YYYY/MM/DD (weekday)
                              format={(date) => getFormatDateDataOther(date)}
                              // only can select date between startDate and endDate
                              disabledDate={(date) => {
                                const startDate = getFieldValue('startDate');
                                const endDate = getFieldValue('endDate');

                                const isDateBetweenStartDateAndEndDate = date.isBetween(
                                  startDate,
                                  endDate,
                                  'day',
                                  '[]'
                                );

                                return !isDateBetweenStartDateAndEndDate;
                              }}
                              defaultPickerValue={getFieldValue('startDate')}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>

                  <Col>
                    <img src={iconDelete} alt="delete" onClick={() => remove(name)} className="mt-3 cursorPointer" />
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </CommonModal>
  );
};

export default ModalDateExcludeTask;
