import { Button, ButtonProps } from 'antd';
import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export interface IPropsButton extends ButtonProps {
  onClick?: (data?: any) => any;
  title?: any;
  type?: 'default' | 'primary' | 'text';
  icon?: string;
  classNameImg?: any;
  children?: React.ReactNode;
  isActive?: boolean;
  variants?: 'reject' | 'accept';
  danger?: boolean;
}

export const CommonButton = (props: IPropsButton) => {
  const { onClick, title, type, icon, classNameImg, children, className, isActive, variants, danger, ...rest } = props;

  return (
    <Button
      onClick={onClick}
      type={type}
      className={classNames(
        styles.customButton,
        {
          [styles.icons]: !type,
          [styles.active]: isActive,
          [styles.reject]: variants === 'reject',
          [styles.accept]: variants === 'accept',
          [styles.danger]: danger,
        },
        className
      )}
      icon={icon && <img src={icon} className={classNameImg} alt="" />}
      {...rest}
    >
      {title + ' '}
      {children}
    </Button>
  );
};
