import { Form } from 'antd';
import { UserRole } from 'common';

import React from 'react';

interface IProps {
  disable?: boolean;
  children?: (disabled?: boolean) => React.ReactNode;
}

const ItemFormSupervisionAdmin = ({ disable, children }: IProps) => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, getFieldsValue }) => {
        const roleId = getFieldValue('roleId');

        const disabled = disable;

        // hide if not admin
        if (roleId !== UserRole.ADMIN) return null;

        return children?.(disabled);
      }}
    </Form.Item>
  );
};

export default ItemFormSupervisionAdmin;
