import { message } from 'antd';

import { confirmUpdateTask, rejectCustomerUpdateTask } from 'api/constructionSite';

import { ITaskDetail } from 'common/interface';
import { CommonButton } from 'components/CommonButton';
import { CommonModal } from 'components/CommonModal';
import { handleErrorMessage } from 'helper';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import styles from './styles.module.scss';
import { getMaxWorker } from './utils/getMaxWorker';
import { UpdateTaskMode } from 'common';

interface IProps {
  setOpenModalConfirm: any;
  taskDetail?: ITaskDetail;
  metadataCustomerUpdate: ITaskDetail;
}
type Submit = 'Reject' | 'Accept';
export const ModalConfirmCustomerUpdate = ({ setOpenModalConfirm, taskDetail, metadataCustomerUpdate }: IProps) => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const handleClose = () => {
    setOpenModalConfirm(false);
  };

  const handleSubmit = async (type: Submit) => {
    setLoading(true);

    const dayOffs = metadataCustomerUpdate.metadataDayOffs ? JSON.parse(metadataCustomerUpdate.metadataDayOffs) : [];

    // get maxWorker morning, afternoon
    const maxWorkerObj = getMaxWorker(metadataCustomerUpdate);

    let payload = {};
    if (type === 'Accept') {
      payload = {
        dayOffs,
        name: metadataCustomerUpdate?.name ?? null,
        // maxWorker: metadataCustomerUpdate?.maxWorker,
        // maxWorkerMorning: metadataCustomerUpdate?.maxWorkerMorning,
        // maxWorkerAfternoon: metadataCustomerUpdate?.maxWorkerAfternoon,
        ...maxWorkerObj,
        maxWorkerEvening: metadataCustomerUpdate?.maxWorkerEvening,
        maxWorkerOther: metadataCustomerUpdate?.maxWorkerOther,
        noteConstructionSite: metadataCustomerUpdate?.noteConstructionSite,
        date: {
          from: metadataCustomerUpdate?.date?.from,
          to: metadataCustomerUpdate?.date?.to,
        },
        startTimeDesire: metadataCustomerUpdate?.startTimeDesire,
        endTimeDesire: metadataCustomerUpdate?.endTimeDesire,
        morningShift: metadataCustomerUpdate?.morningShift,
        afternoonShift: metadataCustomerUpdate?.afternoonShift,
        eveningShift: metadataCustomerUpdate?.eveningShift,
        otherShift: metadataCustomerUpdate?.otherShift,
        description: metadataCustomerUpdate?.description,
        files: metadataCustomerUpdate?.files || [],
        unitPriceCustomer: metadataCustomerUpdate?.unitPriceCustomer,
        mode: metadataCustomerUpdate?.mode ?? UpdateTaskMode.ONE,
        morningShiftCustomer: metadataCustomerUpdate?.morningShiftCustomer,
        afternoonShiftCustomer: metadataCustomerUpdate?.afternoonShiftCustomer,
        eveningShiftCustomer: metadataCustomerUpdate?.eveningShiftCustomer,
        otherShiftCustomer: metadataCustomerUpdate?.otherShiftCustomer,
      };
    }

    try {
      if (type === 'Reject') {
        await rejectCustomerUpdateTask({ id: taskDetail?.id, companyId: taskDetail?.companyId });
      }
      if (type === 'Accept') {
        await confirmUpdateTask({ id: Number(taskDetail?.id), payload, companyId: taskDetail?.companyId });
      }
      handleClose();
      message.destroy();
      message.success('成功', 5);
      queryClient.invalidateQueries(['construction-site-detail']);
      queryClient.invalidateQueries(['list-tasks--calendar']);
      queryClient.invalidateQueries(['list-tasks--home-calendar']);
      queryClient.invalidateQueries(['list-tasks-of-construction']);
      queryClient.invalidateQueries(['construction-site-task-detail']);
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <CommonModal
      handleClose={handleClose}
      isModalVisible={true}
      footer=""
      title=""
      centered
      className={`${styles.modalUpdateTimekeeping} ${styles.pModal}`}
    >
      <div className="heading-20 text-bold">手配が編集されました</div>
      <div className="mt-20">クライアントが手配内容を編集しました。</div>
      <div>変更を承認しますか。</div>

      <div className="d-flex justify-content-center mt-30">
        <CommonButton title={'拒否'} className={styles.btnReject} onClick={() => handleSubmit('Reject')} />
        <CommonButton title={'承認'} className={styles.btnAccept} onClick={() => handleSubmit('Accept')} />
      </div>
    </CommonModal>
  );
};
