import { checkValuesChanged } from 'helper';
import { omit } from 'lodash';

const useCheckDataUpdateChanged = () => {
  const checkDataUpdateChanged = ({ currentData = {}, initialData = {} }: { currentData: any; initialData: any }) => {
    // covert to boolean to compare
    // const initialMorningShift = !!(initialData as any)?.morningShift;
    // const initialAfternoonShift = !!(initialData as any)?.afternoonShift;
    // const initialEveningShift = !!(initialData as any)?.eveningShift;
    // const initialOtherShift = !!(initialData as any)?.otherShift;

    // const currentMorningShift = !!currentData?.morningShift;
    // const currentAfternoonShift = !!currentData?.afternoonShift;
    // const currentEveningShift = !!currentData?.eveningShift;
    // const currentOtherShift = !!currentData?.otherShift;

    const initialMorningShiftCustomer = !!(initialData as any)?.morningShiftCustomer;
    const initialAfternoonShiftCustomer = !!(initialData as any)?.afternoonShiftCustomer;
    const initialEveningShiftCustomer = !!(initialData as any)?.eveningShiftCustomer;
    const initialOtherShiftCustomer = !!(initialData as any)?.otherShiftCustomer;

    const currentMorningShiftCustomer = !!currentData?.morningShiftCustomer;
    const currentAfternoonShiftCustomer = !!currentData?.afternoonShiftCustomer;
    const currentEveningShiftCustomer = !!currentData?.eveningShiftCustomer;
    const currentOtherShiftCustomer = !!currentData?.otherShiftCustomer;

    const initialName = (initialData as any)?.name ?? '';
    const currentName = currentData?.name ?? ''; //

    const initialDescription = {
      ...((initialData as any)?.description ?? {}),
      content: (initialData as any)?.description?.content?.trim(),
    };
    const currentDescription = {
      ...(currentData?.description ?? {}),
      content: currentData?.description?.content?.trim(),
    };
    const initialNoteConstructionSite = (initialData as any)?.noteConstructionSite?.trim() ?? '';
    const currentNoteConstructionSite = currentData?.noteConstructionSite?.trim();

    const { hasChanged, differentKeys } = checkValuesChanged({
      initialValues: {
        ...omit(initialData, ['afternoonShift', 'morningShift', 'eveningShift', 'otherShift']),
        name: initialName,
        // morningShift: initialMorningShift,
        // afternoonShift: initialAfternoonShift,
        // eveningShift: initialEveningShift,
        // otherShift: initialOtherShift,
        description: initialDescription,
        noteConstructionSite: initialNoteConstructionSite,
        morningShiftCustomer: initialMorningShiftCustomer,
        afternoonShiftCustomer: initialAfternoonShiftCustomer,
        eveningShiftCustomer: initialEveningShiftCustomer,
        otherShiftCustomer: initialOtherShiftCustomer,
      },
      currentValues: {
        ...omit(currentData, ['afternoonShift', 'morningShift', 'eveningShift', 'otherShift']),
        name: currentName,
        // morningShift: currentMorningShift,
        // afternoonShift: currentAfternoonShift,
        // eveningShift: currentEveningShift,
        // otherShift: currentOtherShift,
        description: currentDescription,
        noteConstructionSite: currentNoteConstructionSite,
        morningShiftCustomer: currentMorningShiftCustomer,
        afternoonShiftCustomer: currentAfternoonShiftCustomer,
        eveningShiftCustomer: currentEveningShiftCustomer,
        otherShiftCustomer: currentOtherShiftCustomer,
      },
    });

    return { hasChanged, differentKeys };
  };

  return { checkDataUpdateChanged };
};

export default useCheckDataUpdateChanged;
