import React from 'react';
import { Route, useHistory } from 'react-router-dom';

export function RoleBasedRouting({ component: Component, isSuperAdmin, ...rest }: any) {
  const history = useHistory();
  return (
    <>
      {!isSuperAdmin ? (
        <Route
          {...rest}
          render={(props) => (
            <>
              <Component {...props} />
            </>
          )}
        />
      ) : null}
      {isSuperAdmin ? history.push('/') : null}
    </>
  );
}
