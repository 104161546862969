import { getListCompanyActive } from 'api/company';
import { ROLES_CAN_CREATE_TASK, UserRole } from 'common';
import { ROLES_ADMIN } from 'common/const';
import { ICompanyActive } from 'common/interface';
import queryKeys from 'common/queryKeys';

import useProfile from 'hooks/useProfile';
import { uniqBy } from 'lodash';
import { useQuery } from 'react-query';

/**
 * @description :
 * - get list company active
 * - get roles in company active
 * - get company with user is admin
 * - check is vendor customer in company active
 */

const useListCompanyActive = (enabled = false) => {
  const profile = useProfile();

  const { data, ...rest } = useQuery({
    queryKey: [queryKeys.company.listCompanyActive],
    queryFn: getListCompanyActive,
    enabled,
  });

  const listCompanyActive: (ICompanyActive & { disabled?: boolean })[] =
    data?.data.map((company) => {
      const isCanCreateTask = ROLES_CAN_CREATE_TASK.includes(company.roleId) || Boolean(company?.isCustomer);

      if (!isCanCreateTask) {
        return {
          ...company,
          disabled: true,
        };
      }

      return company;
    }) ?? [];

  // unique roles in company active
  const rolesInCompanyActive = uniqBy(listCompanyActive, 'roleId').map((company) => company.roleId);

  const isVendorCustomerInCompanyActive = listCompanyActive.some(
    (company) => company.roleId === UserRole.VENDOR && !!company.isCustomer
  );

  // find company with user is admin to set default for form
  const companyWithUserIsAdmin = listCompanyActive?.find((company) => ROLES_ADMIN.includes(company.roleId));

  // ids of company active
  const companyActiveIds = listCompanyActive.filter((company) => !company.disabled).map((company) => company.id);

  // number of company user can create task
  const numberCompanyUserCanCreateTask = listCompanyActive
    .filter((company) => !company.disabled)
    .reduce((acc, item) => {
      const isVendorCustomerInCompany = item.roleId === UserRole.VENDOR && !!item.isCustomer;
      const isCanCreateTask = ROLES_CAN_CREATE_TASK.some((role) => item.roleId === role) || isVendorCustomerInCompany;

      return acc + (isCanCreateTask ? 1 : 0);
    }, 0);

  return {
    listCompanyActive,
    companyWithUserIsAdmin,
    rolesInCompanyActive,
    isVendorCustomerInCompanyActive,
    companyActiveIds,
    numberCompanyUserCanCreateTask,
    ...rest,
  };
};

export default useListCompanyActive;
