import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import 'styles/index.scss';
import 'helper/i18n';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import 'moment/locale/ja';

ReactDOM.render(
  // <React.StrictMode>
  <ConfigProvider locale={jaJP}>
    <App />
  </ConfigProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
