import { allResource } from 'api/init';
import addressHelper from 'helper/address';
import React, { lazy, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import AuthWrapper from 'wrappers/AuthWrapper';

const Login = lazy(() => import('pages/Authentication/Login'));
const LoginWithInvite = lazy(() => import('pages/Authentication/Login/LoginWithInvite'));
const LoginCompany = lazy(() => import('pages/Authentication/LoginCompany'));
const SignUp = lazy(() => import('pages/Authentication/SignUp'));
const SuccessSignUp = lazy(() => import('pages/Authentication/SignUp/SuccessSignUpForm'));
const SignUpStaff = lazy(() => import('pages/Authentication/SignUp/StaffSignUp'));
const ForgotPassword = lazy(() => import('pages/Authentication/ForgotPassword/ForgotPassword'));
const SignUpCustomer = lazy(() => import('pages/Authentication/SignUp/SignUpCustomer'));
const Subscription = lazy(() => import('pages/Subscription/index'));
const SubscriptionIntro = lazy(() => import('pages/SubscriptionIntro'));
const TermPolicy = lazy(() => import('pages/TermPolicy'));
const TermOfPrivacy = lazy(() => import('pages/TermPolicy/TermOfPrivacy'));
const TransactionLaw = lazy(() => import('pages/TermPolicy/TransactionLaw'));
const Contact = lazy(() => import('pages/Contact'));

export default function AppWrapper() {
  const { data: listResource } = useQuery('resource', () => allResource());
  useEffect(() => {
    addressHelper.init(listResource?.data?.ken, listResource?.data?.city);
  }, [listResource]);

  if (!listResource) return null;
  return (
    <div className="root-wrapper">
      <Switch>
        <Route path="/success-sign-up" exact component={SuccessSignUp} />
        <Route path="/login" exact component={Login} />
        <Route path="/login-with-invite" exact component={LoginWithInvite} />
        <Route path="/login-company" exact component={LoginCompany} />
        <Route path="/sign-up-staff" exact component={SignUpStaff} />
        <Route path="/sign-up-customer" exact component={SignUpCustomer} />
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/subscription" exact component={Subscription} />
        <Route path="/subscription-intro" exact component={SubscriptionIntro} />
        <Route path="/term-of-policy" exact component={TermPolicy} />
        <Route path="/term-of-privacy" exact component={TermOfPrivacy} />
        <Route path="/transaction-law" exact component={TransactionLaw} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/" component={AuthWrapper} />
      </Switch>
    </div>
  );
}
