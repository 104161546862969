import { sendPost } from './axios';

export const upload = (files: any) => {
  const formData = new FormData();

  //  name file
  const fileName = files?.file?.name;

  formData.append('files', files.file);
  if (fileName) {
    formData.append('fileName[]', fileName);
  }

  return sendPost('upload', formData);
};

export const uploadMultiple = (files: any[]) => {
  const formData = new FormData();

  for (const file of files) {
    formData.append('files', file);

    // name file
    formData.append('fileName[]', file.name);
  }

  return sendPost('/upload', formData);
};
