import { useEffect } from 'react';

// scroll to top when press key (PageUp)
// scroll to bottom when press key (PageDown)

const useScrollWhenPressKey = () => {
  const scrollToTop = () => document.getElementById('pageWrapper')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const scrollToBottom = () => {
    document.getElementById('pageWrapper')?.scrollTo({ top: 100000, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const isPressPageUp = e.key === 'PageUp';

      if (isPressPageUp) {
        scrollToTop();
      }

      const isPressPageDown = e.key === 'PageDown';

      if (isPressPageDown) {
        scrollToBottom();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};

export default useScrollWhenPressKey;
