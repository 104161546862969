import icons from 'assets/icons';
import React from 'react';

interface IProps extends React.HTMLAttributes<HTMLImageElement> {
  disabled?: boolean;
}

const IconDeleteShift = ({ disabled, onClick, ...rest }: IProps) => {
  const handleOnClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (disabled) return;

    onClick?.(e);
  };

  return (
    <img
      src={icons.trash.gray}
      alt=""
      style={{
        width: '22px',
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1,
      }}
      onClick={handleOnClick}
      {...rest}
    />
  );
};

export default IconDeleteShift;
