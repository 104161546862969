import { Col, Row } from 'antd';
import { ITaskDetail } from 'common/interface';
import { emptyData } from 'pages/Tasks/TaskDetailModal';

interface IProps {
  taskDetail?: ITaskDetail;
}

const DetailsTimeDesire = ({ taskDetail }: IProps) => {
  const { startTimeDesire, endTimeDesire } = (taskDetail as ITaskDetail) || {};

  const isHaveTimeDesire = [startTimeDesire, endTimeDesire].some((item) => Boolean(item));

  if (!isHaveTimeDesire) return <span>{emptyData}</span>;

  return (
    <Row className="fontsize-14 color-text2">
      <Col span={12} className="strong">
        {taskDetail?.startTimeDesire}-{taskDetail?.endTimeDesire}
      </Col>
    </Row>
  );
};

export default DetailsTimeDesire;
