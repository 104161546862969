import { IVendor } from 'common/interface';
import styles from './styles.module.scss';
import AvatarProfile from 'components/Avatar';
import { Col, Form, Row, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import classNames from 'classnames';
import { CommonInput } from 'components/CommonInput';
import close from 'assets/icons/x.svg';
import FormAddCertificate from 'components/FormAddCertificate';
import { useQuery } from 'react-query';
import queryKeys from 'common/queryKeys';
import { getDetailVendor } from 'api/vendor';
import { MAX_LENGTH_MONEY, MAX_LENGTH_VENDOR_TASK } from 'common/const';
import { NumericFormat } from 'react-number-format';
import { CommonInputNumber } from 'components/CommonInputNumber';
import { useRulesForm } from 'hooks/useRulesForm';

interface IProps {
  vendor: IVendor;
  form?: FormInstance;
  isActive?: boolean;
  type?: 'certificateVendor';
  handleChangeMaxWorker?: (id: number, maxWorker: number) => void;
  handleChangePrice?: (d: number, price: number) => void;
  handleRemove?: (vendor: IVendor) => void;
  isGetDetailsVendor?: boolean;
  disable?: boolean;
  showIcons?: boolean;
  showTextAdd?: boolean;
  showIconRemoveVendor?: boolean;
  nameFieldMaxWorker?: string[];
  nameFieldPrice?: string[];
  span?: number; // width of itemVendorPicker 1 -> 24
  showPrice?: boolean;
}

const ItemVendorPicker = (props: IProps) => {
  const {
    vendor,
    form,
    isActive,
    type,
    handleChangeMaxWorker,
    handleChangePrice,
    handleRemove,
    isGetDetailsVendor,
    disable,
    showIcons = true,
    showTextAdd = true,
    showIconRemoveVendor = true,
    nameFieldMaxWorker,
    nameFieldPrice,
    span = 10,
    showPrice = false,
  } = props;

  const { rulesForm } = useRulesForm();

  // get details of vendor
  const { data: detailsVendorData } = useQuery({
    queryKey: [queryKeys.detailVendor, vendor.id],
    queryFn: () => getDetailVendor(vendor.id),
    enabled: isGetDetailsVendor && type === 'certificateVendor',
  });

  return (
    <Row gutter={8} style={{ width: '100%' }}>
      <Col
        key={vendor.id}
        span={span}
        className={classNames(styles.itemVendorPickerWrap, {
          [styles.alignStart]: type === 'certificateVendor',
        })}
      >
        <div
          className={classNames(styles.selectedItem, {
            [styles.unActive]: !isActive,
          })}
        >
          <AvatarProfile src={vendor.avatar} name={vendor.name} size={33} />
          <div className={styles.info}>
            <Tooltip title={vendor?.name}>
              {vendor?.vendorCompanyName} - {vendor?.name}
            </Tooltip>
          </div>

          {/* Form max worker of vendor */}
          {type !== 'certificateVendor' && (
            <div className={styles.maxWorker}>
              <label>必要人数</label>

              <Form.Item
                style={{ width: '100%' }}
                name={nameFieldMaxWorker}
                rules={[
                  rulesForm().required,
                  () => ({
                    validator(rule, value) {
                      if (value && +value < 0) {
                        return Promise.reject('必要人は0人以上です');
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                  rulesForm().validateNumberDecimal(2),
                ]}
                validateFirst
              >
                <CommonInputNumber
                  min={0}
                  defaultValue={Number(vendor?.maxWorker || 0)}
                  onChange={(value) => handleChangeMaxWorker?.(vendor.id, +value)}
                  maxLength={MAX_LENGTH_VENDOR_TASK}
                />
              </Form.Item>
            </div>
          )}

          {showIconRemoveVendor && (
            <img
              className={classNames(styles.remove, {
                [styles.disabled]: disable,
              })}
              src={close}
              onClick={() => handleRemove?.(vendor)}
              alt=""
            />
          )}
        </div>

        {showPrice && (
          <Form.Item label="単価" name={nameFieldPrice} className={styles.inputPrice}>
            <NumericFormat
              customInput={CommonInput}
              min={0}
              defaultValue={0}
              onChange={(e) => handleChangePrice?.(vendor.id, +e.target.value)}
              maxLength={MAX_LENGTH_MONEY}
            />
          </Form.Item>
        )}
      </Col>

      {type === 'certificateVendor' && (
        <Col span={24 - span}>
          <FormAddCertificate
            options={detailsVendorData?.data?.dataUnitPriceCertificateVendor as any}
            vendor={vendor}
            showIcons={showIcons}
            showTextAdd={showTextAdd}
            disabledAll={disable}
            form={form}
            type={type}
          />
        </Col>
      )}
    </Row>
  );
};

export default ItemVendorPicker;
