import { TModeMutateStaff } from 'common/interface';
import useProfile from './useProfile';
import { CommonStatus, ONLY_MANAGER_ROLES, UserRole, UserStatus } from 'common';
import { IStaff } from 'api/staff';
import { listRolesStaff } from 'common/const';

const useCheckPermissionStaff = () => {
  const profile = useProfile();
  const isFirstAdminCreateCompany = profile?.id === profile?.adminId;

  // Check if the user is allowed to edit staff
  // Copy from old code + add some conditions
  const getDisabledEditStaff = (
    userRoleId: number,
    staffRole: any,
    staffStatus: any,
    userId: any,
    staffId: any,
    isAdminOriginal?: boolean
  ) => {
    const EDIT_STAFF_PERMISSION = [UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER];
    const isRoleEditable = EDIT_STAFF_PERMISSION.includes(userRoleId);
    const isAdminEditOtherAdmin = userRoleId === UserRole.ADMIN && staffRole === UserRole.ADMIN && userId !== staffId;
    const isManagerEditOtherManager = ONLY_MANAGER_ROLES.includes(userRoleId) && ONLY_MANAGER_ROLES.includes(staffRole);

    // Nếu không trong roles có thể edit thì không được phép edit
    if (!isRoleEditable) return true;

    // check if user is admin and edit other admin
    if (isAdminEditOtherAdmin) {
      // check xem admin có full quyền không
      return profile?.company?.isSupervisingAdmin === CommonStatus.INACTIVE;
    }

    if (isManagerEditOtherManager) {
      return profile.id !== staffId;
    }

    return (
      // user is blocked cannot edited, vendor can edit worker || only admin can edit detail admin
      // logic này đoạn này copy từ code cũ
      staffStatus === UserStatus.BLOCKED ||
      (isRoleEditable
        ? UserRole.ADMIN === userRoleId
          ? false
          : staffRole === UserRole.ADMIN
          ? true
          : false
        : false) ||
      staffRole === UserRole.VENDOR_WORKER
    );
  };

  const isCanViewIsSupervisingAdmin = () => {
    return isFirstAdminCreateCompany;
  };

  const isCanEditIsSupervisingAdmin = ({ mode, staff }: { mode?: TModeMutateStaff; staff?: IStaff }) => {
    if (mode === 'invite') return true;
    if (mode === 'confirm-invite') return false;

    // Nếu không phải admin tạo company thì không được phép chỉnh sửa
    // Nếu là admin tạo company thì không được tự chỉnh sửa bản thân
    return isFirstAdminCreateCompany && profile?.id !== staff?.id;
  };

  const getListStaffCanInvite = () => {
    // Not allow to invite admin if user is not admin
    if ([UserRole.OFFICE_MANAGER, UserRole.MANAGER].includes(profile?.company?.roleId)) {
      return listRolesStaff.filter((role) => role.id !== UserRole.ADMIN);
    }

    return listRolesStaff;
  };

  return {
    getDisabledEditStaff,
    isCanViewIsSupervisingAdmin,
    isCanEditIsSupervisingAdmin,
    getListStaffCanInvite,
    isFirstAdminCreateCompany,
  };
};

export default useCheckPermissionStaff;
