import { UserRole } from 'common';

interface IProps {
  hidden?: boolean;
  accessibleRoles: Array<number>;
  children: any;
  roleInCompany: UserRole | boolean | UserRole[];
}
export default function AccessControlMultiCompany({ children, accessibleRoles, roleInCompany, hidden }: IProps) {
  const isCanAccess = () => {
    if (typeof roleInCompany === 'number') {
      return accessibleRoles.includes(roleInCompany);
    }

    if (Array.isArray(roleInCompany)) {
      return roleInCompany.some((role) => accessibleRoles.includes(role));
    }

    // if roleInCompany is boolean
    return roleInCompany;
  };

  if (hidden || !isCanAccess()) return null;

  return <>{children}</>;
}
