import { Radio, RadioGroupProps } from 'antd';
import styles from './styles.module.scss';
import classNames from 'classnames';

export interface CommonRadioGroupProps extends RadioGroupProps {}

const CommonRadioGroup = (props: CommonRadioGroupProps) => {
  const { className, ...rest } = props;

  return <Radio.Group className={classNames(styles.base, className)} {...rest} />;
};

export default CommonRadioGroup;
