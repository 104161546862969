import { TypePaymentOffset } from 'common';
import { monthPaymentName } from 'common/const';
import { ICustomer } from 'common/interface';
import { getPayloadServiceChargeFromApi } from 'helper';

export const getDefaultValueCustomer = (customerDetail: ICustomer) => {
  let formValue = getPayloadServiceChargeFromApi(customerDetail);

  // Nếu có chứng chỉ thì set value vào form
  // if (customerDetail?.dataUnitPriceCertificate?.length && customerDetail?.dataUnitPriceCertificate?.length > 0) {
  //   const [{ title: titleCertificate, price: priceCertificate, id: idCertificate }, ...rest] =
  //     customerDetail.dataUnitPriceCertificate;

  //   formValue = { ...formValue, titleCertificate, priceCertificate, idCertificate, dataUnitPriceCertificate: rest };
  // }

  return {
    ...formValue,
    [monthPaymentName]: customerDetail?.paymentMonthOffset ?? TypePaymentOffset.THIS_MONTH,
  };

  // return formValue;
};
