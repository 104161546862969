import { Radio } from 'antd';
import { CommonStatus } from 'common';
import CommonRadioGroup, { CommonRadioGroupProps } from 'components/CommonRadioGroup';

interface IProps extends CommonRadioGroupProps {}

// phải set giá trị ngược cho radio vì bên BE đang đòi truyền lên isDisableViewRevenue
// nhưng ở UI thì là isViewRevenue
// Do sự conflig này dẫn đến việc này

const CheckBoxViewRevenue = ({ ...rest }: IProps) => {
  return (
    <CommonRadioGroup {...rest}>
      {/* Có thể xem revenue */}
      <Radio value={CommonStatus.INACTIVE}>はい</Radio>

      {/* Không xem được view revenue */}
      <Radio value={CommonStatus.ACTIVE}>いいえ</Radio>
    </CommonRadioGroup>
  );
};

export default CheckBoxViewRevenue;
