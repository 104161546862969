export enum DayOfWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum SHIFT {
  MORNING = 1,
  AFTERNOON = 2,
  EVENING = 3,
  OTHER = 4,
  TIME_OFF = 10,
}

export const dayOfWeeks = [
  { value: DayOfWeek.SUNDAY, title: '日曜日' },
  { value: DayOfWeek.MONDAY, title: '月曜日' },
  { value: DayOfWeek.TUESDAY, title: '火曜日' },
  { value: DayOfWeek.WEDNESDAY, title: '水曜日' },
  { value: DayOfWeek.THURSDAY, title: '木曜日' },
  { value: DayOfWeek.FRIDAY, title: '金曜日' },
  { value: DayOfWeek.SATURDAY, title: '土曜日' },
];

export const checkboxValue = [
  { label: '午前', value: SHIFT.MORNING },
  { label: '午後', value: SHIFT.AFTERNOON },
  { label: '夜間', value: SHIFT.EVENING },
  { label: 'オプション', value: SHIFT.OTHER },
  { label: '空け', value: SHIFT.TIME_OFF },
];

export const checkboxValueDisabled = [
  { label: '午前', value: SHIFT.MORNING, disabled: true },
  { label: '午後', value: SHIFT.AFTERNOON, disabled: true },
  { label: '夜間', value: SHIFT.EVENING, disabled: true },
  { label: 'オプション', value: SHIFT.OTHER, disabled: true },
  { label: '空け', value: SHIFT.TIME_OFF, disabled: true },
];
