import { Form, Space } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { renderListDayOffs } from 'helper';

interface IProps {
  form?: FormInstance;
}

const ListDayOffsSelected = ({ form }: IProps) => {
  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const dayOffs = (getFieldValue('dayOffs') as { format: string }[]) || [];

        return <Space className="text-blue">{renderListDayOffs(dayOffs?.map((dayOff) => dayOff?.format))}</Space>;
      }}
    </Form.Item>
  );
};

export default ListDayOffsSelected;
