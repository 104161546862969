import { checkEmailExist } from 'api/profile';
import { UserRole } from 'common';
import { listRolesStaff } from 'common/const';
import ConfirmLogout from 'components/ConfirmLogout';
import ConfirmPasswordInvitedUser from 'components/ConfirmPasswordInvitedUser';
import Cookies from 'js-cookie';
import { FormStaff } from 'pages/Staff/FormStaff';
import FormInviteCustomer from 'pages/Customer/FormInviteCustomer';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import useProfile from 'hooks/useProfile';
import { FormCheckInfoVendor } from 'pages/Vendor/FormCheckInfoVendor';
import { FormConfirmInviteStaff } from 'pages/Staff/FormConfirmInviteStaff';

export default function AcceptInviteOfAdmin() {
  const queryClient = useQueryClient();
  const detailRegisterToken = queryClient.getQueryData('detailRegisterToken') as any;
  const detailRegisterTokenCustomer = queryClient.getQueryData('Customer-Token') as any;
  const detailRegisterTokenStaff = queryClient.getQueryData('inviteStaffToken') as any;
  const inviteToken = Cookies.get('inviteToken');
  const currentInviteDetail =
    detailRegisterToken?.data || detailRegisterTokenCustomer?.data || detailRegisterTokenStaff?.data;
  // show/hide modal accept
  const [currentInvite, setCurrentInvite] = useState(currentInviteDetail);
  const [openStaffInfo, setOpenStaffInfo] = useState(false);
  const [openCustomerForm, setOpenCustomerForm] = useState(false);

  // hide form update profile when new account sign up in this system
  const hideFormStaff = queryClient.getQueryData('hideFormStaff') as any;
  const [hideStaffSignUp, setHideStaffSignUp] = useState(hideFormStaff || false);

  const currentProfile = useProfile();
  const { data: existEmail } = useQuery('existEmail', () => checkEmailExist({ email: currentInviteDetail?.email }), {
    enabled: !!currentInviteDetail?.email,
    keepPreviousData: false,
  });

  //email đã tồn tại trong hệ thống
  const isExist = existEmail?.data?.isExist;

  const isStaff = (roleId: number) => listRolesStaff.find((el) => el.id === roleId);

  !!currentInvite && queryClient.removeQueries('detailRegisterToken');

  useEffect(() => {
    if (currentProfile && currentProfile?.company?.roleId === UserRole.CUSTOMER && !currentProfile?.companyName) {
      setOpenCustomerForm(true);
    }
  }, [currentProfile]);

  return (
    <>
      {/* view information invited to vendor by admin */}
      {!!currentInvite &&
        currentProfile?.email === currentInvite?.email &&
        currentInvite?.roleId === UserRole.VENDOR && (
          <FormCheckInfoVendor
            showAddVendor={!!currentInvite}
            handleClose={() => {
              setCurrentInvite(null);
              Cookies.remove('inviteToken');
              window.location.reload();
            }}
            currentInviteVendor={currentInvite}
          />
        )}

      {/* View information of customer  */}
      {!!currentInvite &&
        currentProfile?.email === currentInvite?.email &&
        currentInvite?.roleId === UserRole.CUSTOMER && (
          <FormInviteCustomer
            open={!!currentInvite || openCustomerForm}
            handleClose={() => {
              setCurrentInvite(null);
              Cookies.remove('inviteToken');
              window.location.reload();
            }}
            currentInviteCustomer={currentInvite}
            currentProfile={currentProfile}
          />
        )}

      {/* <FormInviteCustomer
        open={openCustomerForm}
        handleClose={() => {
          setCurrentInvite(null);
          Cookies.remove('inviteToken');
          window.location.reload();
        }}
        currentInviteCustomer={currentInvite}
        currentProfile={currentProfile}
      /> */}
      {/* view information invited to staff by admin */}

      <FormConfirmInviteStaff
        showAddStaff={openStaffInfo || hideStaffSignUp}
        handleClose={() => {
          setOpenStaffInfo(false);
          setHideStaffSignUp(false);
          setCurrentInvite(null);
          Cookies.remove('inviteToken');
          queryClient.removeQueries('hideFormStaff');
          window.location.reload();
        }}
        currentInviteStaff={currentInviteDetail}
      />

      {/* pop up update information of staff staff */}
      {!!currentInvite &&
        isStaff(currentInvite?.roleId) &&
        currentProfile?.email === currentInvite?.email &&
        isExist &&
        hideStaffSignUp && (
          <FormStaff
            edit={!!currentInvite}
            isExist={isExist}
            handleOpenStaffInfo={() => setOpenStaffInfo(true)}
            handleClose={() => {
              setCurrentInvite(null);
            }}
          />
        )}

      {/* step  confirm change another account */}
      {!!currentInvite && currentProfile?.email !== currentInvite?.email && isExist && !!inviteToken && (
        <ConfirmPasswordInvitedUser invitedUser={currentInvite} />
      )}
      {!!currentInvite && currentProfile?.email !== currentInvite?.email && existEmail && !isExist && !!inviteToken && (
        <ConfirmLogout />
      )}
    </>
  );
}
