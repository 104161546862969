import { ITaskDetail } from 'common/interface';
import { checkTaskSeparatedShift } from 'helper';

export const getMaxWorker = (taskDetail: ITaskDetail) => {
  const isNewData = checkTaskSeparatedShift(taskDetail);

  if (isNewData) {
    return {
      maxWorkerMorning: taskDetail?.maxWorkerMorning,
      maxWorkerAfternoon: taskDetail?.maxWorkerAfternoon,
    };
  }

  const isCheckedMorning = Boolean(taskDetail.morningShift);
  const isCheckedAfternoon = Boolean(taskDetail.afternoonShift);

  if (isCheckedMorning && !isCheckedAfternoon) {
    return {
      maxWorkerMorning: taskDetail?.maxWorker,
      // maxWorkerAfternoon: null,
    };
  }

  if (isCheckedAfternoon && !isCheckedMorning) {
    return {
      // maxWorkerMorning: null,
      maxWorkerAfternoon: taskDetail?.maxWorker,
    };
  }

  if (isCheckedMorning && isCheckedAfternoon) {
    return {
      maxWorkerMorning: taskDetail?.maxWorker,
      maxWorkerAfternoon: taskDetail?.maxWorker,
    };
  }

  return {};
};
