import icSendEmail from 'assets/icons/send-email.svg';
import classNames from 'classnames';
import { CommonModal } from 'components/CommonModal';
import { Form } from 'antd';
import { CommonInput } from 'components/CommonInput';
import styles from './styles.module.scss';
import useToggleModal from 'hooks/useToggleModal';
import { useMutation } from 'react-query';
import { sendEmail } from 'api/task';
import { handleErrorMessage, handleMessageSuccess } from 'helper';
import { useState } from 'react';
import { ICompanyActive, ITaskDetail } from 'common/interface';
import { useRulesForm } from 'hooks/useRulesForm';
import useProfile from 'hooks/useProfile';
import { getNamePdfFile } from './utils';
import Loader from 'components/Loader';
import sanitize from 'sanitize-filename';
import { TYPE_INPUT } from 'common';
import { useReactToPrint } from 'react-to-print';

//@ts-ignore
import html2pdf from 'html2pdf.js';
import { getImageFormUrl } from 'api/common';

interface IProps {
  detailsTaskRef: React.RefObject<HTMLDivElement>;
  taskDetail?: ITaskDetail;
  companySelected?: ICompanyActive;
}

type TValue = {
  replyToEmail: string;
  to: string;
  subject: string;
  html: string;
};

const ButtonSendEmail = ({ detailsTaskRef, taskDetail, companySelected }: IProps) => {
  const profile = useProfile();

  const [form] = Form.useForm();
  const { rulesForm } = useRulesForm();

  const { isOpen, toggleModal } = useToggleModal();
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const renderPdfTask = useReactToPrint({
    content: () => detailsTaskRef.current,
    onBeforeGetContent: () => {
      // remove class fixed-header-form
      if (detailsTaskRef?.current) {
        setIsLoadingPdf(true);

        // select all image and replace src
        detailsTaskRef.current.querySelectorAll('img')?.forEach((img: any) => {
          if (img?.src?.startsWith('https://')) {
            img.src = img.src.replace(img.src, getImageFormUrl(img.src));
          }
        });

        // remove class fixed-header-form to fix bug when print pdf
        detailsTaskRef.current?.classList.remove('fixed-header-form');
      }
    },
    onAfterPrint: () => {
      // add class fixed-header-form
      if (detailsTaskRef?.current) {
        setIsLoadingPdf(false);

        // reset class
        detailsTaskRef.current.classList.add('fixed-header-form');
      }
    },
    print: async (printIframe: HTMLIFrameElement) => {
      if (!detailsTaskRef.current) return;

      const document = printIframe.contentDocument;

      if (document) {
        const element = document.getElementsByTagName('html')[0];

        let opt = {
          margin: 1,
          image: { type: 'jpeg', quality: 0.95 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' },
        };

        try {
          // get name pdf file
          const namePdfFile = sanitize(getNamePdfFile(taskDetail) || 'task_details.pdf');

          // create blob pdf
          const blob = await html2pdf().set(opt).from(element).outputPdf('blob', namePdfFile);

          // convert blob to file
          const file = new File([blob], namePdfFile);

          // set pdf file
          setPdfFile(file);
        } catch (error) {
          handleErrorMessage(error);
        }
      }
    },
  });

  const sendEmailMutation = useMutation({
    mutationFn: sendEmail,
    onSuccess() {
      handleMessageSuccess('メールを送信しました。');
      toggleModal(false);
    },
    onError(error, variables, context) {
      handleErrorMessage(error);
    },
  });

  const onSendEmail = (values: TValue) => {
    const formData = new FormData();
    formData.append('replyToEmail', values.replyToEmail);
    formData.append('to', values.to);
    formData.append('subject', values.subject ?? '');
    formData.append('html', values.html ?? '');
    formData.append('file', pdfFile as File);
    formData.append('taskId', taskDetail?.id as any);

    sendEmailMutation.mutate(
      { data: formData, companyId: companySelected?.id },
      {
        onSuccess(data, variables, context) {
          //remove form data
          form.resetFields();
          setPdfFile(null);
        },
      }
    );
  };

  const onClickBtnSendEmail = () => {
    toggleModal(true);

    // set default value for form
    form.setFieldsValue({
      replyToEmail: profile?.email,
      to: taskDetail?.customerEmail,
    });

    // render pdf
    renderPdfTask();
  };

  return (
    <>
      <div className={styles.customButton} onClick={onClickBtnSendEmail}>
        <img src={icSendEmail} alt="" />
        <div className={classNames(styles.titleUnderline, 'strong')}>メール送信</div>
      </div>

      <CommonModal
        visible={isOpen}
        resetPadding
        title="クライアントにメールを送信"
        okText="メール送信"
        cancelText="キャンセル"
        className={styles.modalSendEmail}
        onOk={() => form.submit()}
        onCancel={() => {
          toggleModal(false);

          //remove form data
          form.resetFields();
          setPdfFile(null);
        }}
        confirmLoading={sendEmailMutation.isLoading}
      >
        <Form form={form} layout="vertical" onFinish={onSendEmail}>
          <Form.Item label="From" name="replyToEmail" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="example@gmail.com" />
          </Form.Item>
          <Form.Item label="To" name="to" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="example@gmail.com" />
          </Form.Item>
          <Form.Item label="件名" name="subject" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="タイトルを入力してください。" />
          </Form.Item>
          <Form.Item label="メッセージ" name="html" className="mb-10">
            <CommonInput placeholder="メッセージを入力してください" mode={TYPE_INPUT.MULTIPLE} />
          </Form.Item>

          <Loader isLoading={isLoadingPdf}>
            {pdfFile && <div className="text-blue">{getNamePdfFile(taskDetail)}</div>}
          </Loader>
        </Form>
      </CommonModal>
    </>
  );
};

export default ButtonSendEmail;
