import { Col, Radio, Checkbox, Form, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CommonStatus, STATUS } from 'common';
import { BIG_INPUT_NUMBER, listGender, listWeekDisplay } from 'common/const';
import { ISubsidy, TModeMutateStaff } from 'common/interface';
import { CommonDatepicker } from 'components/CommonDatepicker';
import { CommonInputNumber } from 'components/CommonInputNumber';
import { CommonSelect } from 'components/CommonSelect';
import { validateMaxMinInput } from 'helper/validator';
import { InsuranceItem } from './InsuranceItem';
import { ListSubsidy } from './ListSubsidy';
import { useRulesForm } from 'hooks/useRulesForm';
import CommonRadioGroup from 'components/CommonRadioGroup';
import ItemFormSupervisionAdmin from 'components/ItemForm/ItemFormSupervisionAdmin';
import ItemFormViewRevenue from 'components/ItemForm/ItemFormViewRevenue';
import CheckBoxViewRevenue from 'components/CheckBox/CheckBoxViewRevenue';
import { IStaff } from 'api/staff';
import useCheckPermissionStaff from 'hooks/useCheckPermissionStaff';
import CheckboxPlaceWork from './components/CheckboxPlaceWork';
import { FormInstance } from 'antd/es/form/Form';

interface IProps {
  isInValid?: boolean;
  setIsInValid?: any;
  profileCompany?: any;
  disable?: boolean;
  otherSubsidy: ISubsidy[];
  listInsurance: any[];
  mode?: TModeMutateStaff;
  staff?: IStaff;
  form?: FormInstance;
}
export const SalaryItemShift = ({
  profileCompany,
  isInValid,
  setIsInValid,
  disable = false,
  otherSubsidy,
  listInsurance,
  mode,
  staff,
  form,
}: IProps) => {
  const handleCheck = (e: any) => {
    if (e.target.checked) {
      setIsInValid(false);
    }
  };

  const { rulesForm } = useRulesForm();
  const { isCanViewIsSupervisingAdmin, isCanEditIsSupervisingAdmin } = useCheckPermissionStaff();

  return (
    <>
      {profileCompany?.morningShift ? (
        <Col span={3}>
          <Form.Item
            label="午前単価"
            name={['metadata', 'morningSalary']}
            rules={[
              {
                required: !disable,
                message: '全ての項目を入力してください。',
              },
              validateMaxMinInput(BIG_INPUT_NUMBER, 0),
            ]}
            labelAlign="left"
          >
            <CommonInputNumber placeholder="午前" disabled={disable} />
          </Form.Item>
        </Col>
      ) : null}

      {profileCompany?.afternoonShift ? (
        <Col span={3}>
          <Form.Item
            label="午後単価"
            name={['metadata', 'afternoonSalary']}
            rules={[
              {
                required: !disable,
                message: '全ての項目を入力してください。',
              },
              validateMaxMinInput(BIG_INPUT_NUMBER, 0),
            ]}
            labelAlign="left"
          >
            <CommonInputNumber placeholder="午後" disabled={disable} />
          </Form.Item>
        </Col>
      ) : null}

      {profileCompany?.eveningShift ? (
        <Col span={3}>
          <Form.Item
            label="夜間単価"
            name={['metadata', 'eveningSalary']}
            rules={[
              {
                required: !disable,
                message: '全ての項目を入力してください。',
              },
              validateMaxMinInput(BIG_INPUT_NUMBER, 0),
            ]}
            labelAlign="left"
          >
            <CommonInputNumber placeholder="夜間" disabled={disable} />
          </Form.Item>
        </Col>
      ) : null}

      {1 ? (
        <Col span={3}>
          <Form.Item
            label="オプション単価"
            name={['metadata', 'otherSalary']}
            rules={[
              {
                required: false,
                message: '全ての項目を入力してください。',
              },
              validateMaxMinInput(BIG_INPUT_NUMBER, 0),
            ]}
            labelAlign="left"
          >
            <CommonInputNumber placeholder="オプション" disabled={disable} />
          </Form.Item>
        </Col>
      ) : null}

      <Col span={12}>
        <Form.Item label="性別" name={['metadata', 'gender']} rules={[rulesForm().required]} labelAlign="left">
          <CommonSelect options={listGender} disabled={mode === 'confirm-invite'} />
        </Form.Item>
      </Col>

      {isCanViewIsSupervisingAdmin() && (
        <Col span={12}>
          <ItemFormSupervisionAdmin disable={!isCanEditIsSupervisingAdmin({ mode, staff })}>
            {(disabled) => {
              return (
                <Form.Item
                  label="アドミン権限 (全て表示)"
                  name={['metadata', 'isSupervisingAdmin']}
                  rules={[rulesForm().required]}
                >
                  <CommonRadioGroup disabled={disabled}>
                    <Radio value={CommonStatus.ACTIVE}>はい</Radio>
                    <Radio value={CommonStatus.INACTIVE}>いいえ</Radio>
                  </CommonRadioGroup>
                </Form.Item>
              );
            }}
          </ItemFormSupervisionAdmin>
        </Col>
      )}

      <Col span={12}>
        <ListSubsidy disabled={disable} otherSubsidy={otherSubsidy} />
      </Col>
      <Col span={12}>
        <Form.Item
          label="労働時間/シフト"
          name={['metadata', 'workingHour']}
          rules={[
            {
              required: !disable,
              message: '全ての項目を入力してください。',
            },
            validateMaxMinInput(24, 0),
          ]}
          labelAlign="left"
        >
          <CommonInputNumber disabled={disable} />
        </Form.Item>

        <ItemFormViewRevenue disable={mode === 'confirm-invite'}>
          {(disabled) => (
            <Form.Item
              label="経営レポートを表示"
              name={['metadata', 'isDisableViewRevenue']}
              rules={[rulesForm().required]}
            >
              <CheckBoxViewRevenue disabled={disabled} />
            </Form.Item>
          )}
        </ItemFormViewRevenue>
      </Col>

      <Col span={6}>
        <Form.Item
          label="みなし残業（一ヶ月）"
          name={['metadata', 'monthlyOvertimeHour']}
          rules={[
            {
              required: !disable,
              message: '全ての項目を入力してください。',
            },
            validateMaxMinInput(100, 0),
          ]}
          labelAlign="left"
        >
          <CommonInputNumber disabled={disable} />
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item
          label="残業時の割増賃金率（％）"
          name={['metadata', 'salaryOvertime']}
          rules={[
            {
              required: !disable,
              message: '全ての項目を入力してください。',
            },
            validateMaxMinInput(500, 0),
          ]}
          labelAlign="left"
        >
          <CommonInputNumber disabled={disable} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <div>
          <CheckboxPlaceWork disable={disable} form={form} />
        </div>

        <label>働く曜日</label>
        <div className="d-flex justify-content-between mt-10">
          {listWeekDisplay.map((el: any, index) => (
            <Form.Item valuePropName="checked" label="" key={index} name={['metadata', 'schedule', el?.name]}>
              <Checkbox onChange={handleCheck} disabled={disable}>
                {el.label}
              </Checkbox>
            </Form.Item>
          ))}
        </div>

        {isInValid ? <div className="color-red"> 全ての項目を入力してください。</div> : ''}
      </Col>

      <Col span={6}>
        <Form.Item
          label="社員状態"
          name={['metadata', 'isProbation']}
          rules={[
            {
              required: !disable,
              message: '全ての項目を入力してください。',
            },
          ]}
        >
          <Radio.Group disabled={disable}>
            <Radio value={STATUS.INACTIVE}>正社員</Radio>
            <Radio value={STATUS.ACTIVE}>研修</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const isProbation = getFieldValue(['metadata', 'isProbation']);
            if (isProbation === STATUS.ACTIVE)
              return (
                <Form.Item
                  label="研修中給料率 (％)"
                  name={['metadata', 'probationSalary']}
                  rules={[
                    {
                      required: !disable,
                      message: '全ての項目を入力してください。',
                    },
                    validateMaxMinInput(500, 0),
                  ]}
                >
                  <CommonInputNumber disabled={disable} />
                </Form.Item>
              );

            if (isProbation === STATUS.INACTIVE) {
              return (
                <Form.Item
                  label="雇用年月日"
                  name={['metadata', 'officialDate']}
                  rules={[
                    {
                      required: !disable,
                      message: '全ての項目を入力してください。',
                    },
                  ]}
                >
                  <CommonDatepicker disabled={disable} />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
      </Col>

      <InsuranceItem disable={disable} listInsurance={listInsurance} />

      <Col span={24}>
        <Form.Item label="メモ" name={['metadata', 'description']}>
          <TextArea autoSize={{ minRows: 6, maxRows: 6 }} className={`inputGrey`} disabled={disable} />
        </Form.Item>
      </Col>
    </>
  );
};
