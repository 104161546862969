import AvatarProfile from 'components/Avatar';
import styles from './styles.module.scss';
import { IWorker } from 'common/interface';
import { getWorkerType } from 'helper';
import { UserRole } from 'common';
import icClose from 'assets/icons/x.svg';
import { Form } from 'antd';
import { CommonInput } from 'components/CommonInput';
import { useQuery } from 'react-query';
import queryKeys from 'common/queryKeys';
import { getWorkerSalary } from 'api/common';
import { isNil } from 'lodash';
import Loader from 'components/Loader';
import { FormInstance } from 'antd/es/form/Form';
import useRoleId from 'hooks/useRoleId';
import classNames from 'classnames';

interface IProps {
  worker: IWorker;
  handleRemoveWorker: (id: number) => void;
  form?: FormInstance;
  showPrice?: boolean;
}

const ItemWorkerSelected = ({ worker, handleRemoveWorker, form, showPrice = false }: IProps) => {
  const isHavePrice = [UserRole.VENDOR, UserRole.OTHER_WORKER].includes(worker?.roleId ?? worker?.userCompany?.roleId);
  const nameFormPrice = `price_other_worker_${worker.id}`;
  const roleId = useRoleId();

  const initialPrice = form?.getFieldValue(nameFormPrice);
  const enabledFetchSalary = showPrice && isHavePrice && !isNil(worker?.id) && isNil(initialPrice);

  const { isLoading } = useQuery({
    queryKey: [queryKeys.common.workerBasicSalary, worker.id],
    queryFn: async () => {
      const response = await getWorkerSalary(worker?.id);

      return response?.data?.basicSalary;
    },
    enabled: enabledFetchSalary,
    onSuccess(data) {
      // set value to basic salary
      form?.setFieldsValue({ [nameFormPrice]: data ?? 0 });
    },
  });

  const isShowRemove = () => {
    if (roleId === UserRole.ADMIN) {
      return true;
    }

    if (roleId === UserRole.VENDOR) {
      return worker?.roleId === UserRole.VENDOR_WORKER;
    }

    if (roleId !== UserRole.VENDOR) {
      return worker?.roleId !== UserRole.VENDOR_WORKER;
    }

    return false;
  };

  return (
    <Loader isLoading={isLoading}>
      <div className={styles.workerItem}>
        <AvatarProfile src={worker.avatar} name={worker.name} size={33} />

        <div className={styles.info}>
          <div className={styles.workerName}>{worker?.name}</div>
          <div className={styles.vendorName}>
            {worker?.vendorName ? `サプライヤー: ${worker?.vendorName}` : getWorkerType(worker.roleId)}
          </div>
        </div>

        {isShowRemove() ? (
          <img className={styles.remove} src={icClose} onClick={() => handleRemoveWorker(worker?.id)} alt="" />
        ) : null}

        {showPrice && isHavePrice && (
          <Form.Item label="単価" className={styles.formPrice} name={nameFormPrice}>
            <CommonInput type="number" min={0} defaultValue={0} />
          </Form.Item>
        )}
      </div>
    </Loader>
  );
};

export default ItemWorkerSelected;
