import { useState } from 'react';

const useToggleModal = (initial?: boolean) => {
  const [isOpen, setIsOpen] = useState<boolean>(initial || false);

  const toggleModal = (open: boolean) => {
    setIsOpen(open);
  };

  return { isOpen, toggleModal };
};

export default useToggleModal;
