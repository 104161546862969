import { Col, Form, Row, Typography } from 'antd';
import icAdd from 'assets/icons/plus-gray.svg';
import icDelete from 'assets/icons/trash-2.svg';
import { listHours, listMinutes } from 'common/const';
import { CommonIconButton } from 'components/CommonIconButton';
import { CommonInputNumber } from 'components/CommonInputNumber';
import { CommonSelect } from 'components/CommonSelect';
import React from 'react';

const { Title } = Typography;
interface IFormListBreakTime {
  nameList: string;
  initialValue: IInitialValue[];
  title: string;
  disabled?: boolean;
}

interface IInitialValue {
  hour: number | null;
  minute: number | null;
}

export const FormListBreakTime = ({ initialValue, nameList, disabled = false, title }: IFormListBreakTime) => {
  return (
    <>
      <Title level={5}>{title}</Title>
      <Form.List name={nameList} initialValue={initialValue}>
        {(fields, { add, remove }) => {
          return (
            <>
              <Row gutter={20}>
                {fields.map(({ key, name, fieldKey, ...rest }, index) => (
                  <>
                    <Col span={12} className="d-flex align-items-center justify-content-between">
                      <Form.Item
                        {...rest}
                        label="労働時間"
                        name={[name, 'hour']}
                        fieldKey={[fieldKey, 'hour']}
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              const formValues = getFieldValue(nameList);
                              const formValuesFilter = formValues.filter((el: any) => el !== undefined);
                              const ziped = formValuesFilter.reduce((acc: any, cur: any) => {
                                acc[`${cur.hour}.${cur.minute}`] = cur;
                                return acc;
                              }, {});

                              if (Object.values(ziped).length < formValues.length && formValuesFilter.length > 1) {
                                return Promise.reject(' ');
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <CommonSelect options={listHours} disabled={disabled} suffixIcon={false} />
                      </Form.Item>

                      <div>時間</div>
                      <Form.Item
                        {...rest}
                        label=" "
                        name={[name, 'minute']}
                        fieldKey={[fieldKey, 'minute']}
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              const formValues = getFieldValue(nameList);
                              const formValuesFilter = formValues.filter((el: any) => el !== undefined);
                              const ziped = formValuesFilter.reduce((acc: any, cur: any) => {
                                acc[`${cur.hour}.${cur.minute}`] = cur;
                                return acc;
                              }, {});
                              if (Object.values(ziped).length < formValues.length && formValuesFilter.length > 1) {
                                return Promise.reject(' ');
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <CommonSelect
                          style={{ width: '60px' }}
                          disabled={disabled}
                          options={listMinutes}
                          suffixIcon={false}
                        />
                      </Form.Item>
                      <div>分</div>
                    </Col>

                    <Col span={12} className="d-flex  align-items-center">
                      <Form.Item
                        {...rest}
                        label="休憩時間(分)"
                        name={[name, 'break']}
                        fieldKey={[fieldKey, 'break']}
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                        ]}
                      >
                        <CommonInputNumber disabled={disabled} />
                      </Form.Item>

                      {disabled ? null : index === 0 ? (
                        <CommonIconButton
                          icon={icAdd}
                          onClick={() => {
                            add();
                          }}
                        />
                      ) : (
                        <CommonIconButton
                          icon={icDelete}
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      )}
                    </Col>
                  </>
                ))}
              </Row>
            </>
          );
        }}
      </Form.List>
    </>
  );
};
