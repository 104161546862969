import { IPlan } from 'common/interface';
import { formatCurrencyJP } from 'helper';
import icons from 'assets/icons';
import { CommonButton } from 'components/CommonButton';

import styles from './styles.module.scss';

interface IItemPlanProps {
  plan: IPlan;
  onSelectPlan?: (plan: IPlan) => void;
  disableSelect?: boolean;
}

const ItemPlan = ({ plan, onSelectPlan, disableSelect }: IItemPlanProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{plan?.name ?? ''}</div>

      <div className={styles.priceWrapper}>
        <div className={styles.value}>{formatCurrencyJP(plan?.price ?? 0)}</div>
        <div className={styles.unit}>/ 1ヶ月</div>
      </div>

      <div className={styles.body}>
        <div className={styles.totalUser}>
          <img src={icons.check.blue} alt="" />

          <span>{plan?.totalUser ?? 0}アカウント</span>
        </div>
        <div className={styles.desc}>{plan?.description ?? ''}</div>
      </div>

      <div className={styles.footer}>
        <CommonButton
          title="選択する"
          type={disableSelect ? 'default' : 'primary'}
          className={styles.btnSelect}
          onClick={() => onSelectPlan?.(plan)}
          disabled={disableSelect}
        />
      </div>
    </div>
  );
};

export default ItemPlan;
