import { Col, Form, Row } from 'antd';
import { TYPE_PICKER } from 'common';
import { CommonDatepicker } from 'components/CommonDatepicker';

interface IProps {
  startTimeDesireName: string;
  endTimeDesireName: string;
  disabled?: boolean;
}

const TimeDesire = ({ startTimeDesireName, endTimeDesireName, disabled }: IProps) => {
  return (
    <Row gutter={8} style={{ width: '100%' }}>
      <Col xs={12}>
        <Form.Item label="希望開始時間" name={startTimeDesireName} className="mb-form-0">
          <CommonDatepicker
            // @ts-ignore
            inputReadOnly
            allowClear={false}
            picker={TYPE_PICKER.TIME}
            minuteStep={5}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col xs={12}>
        <Form.Item label="希望終了時間" name={endTimeDesireName} className="mb-form-0">
          <CommonDatepicker
            // @ts-ignore
            inputReadOnly
            picker={TYPE_PICKER.TIME}
            allowClear={false}
            minuteStep={5}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default TimeDesire;
