import cammera from 'assets/icons/camera.svg';
import editImage from 'assets/icons/editImage.svg';
import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IIconEditProps extends React.HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  isHaveImage?: boolean;
}

const IconEdit = ({ disabled, isHaveImage, className, ...rest }: IIconEditProps) => {
  return (
    <div
      className={classNames(
        {
          [styles.notAllow]: disabled,
          [styles.iconUpdoadImage]: isHaveImage,
          [styles.onlyIcon]: !isHaveImage,
        },
        className
      )}
      {...rest}
    >
      <img src={isHaveImage ? editImage : cammera} alt="" />
    </div>
  );
};

export default IconEdit;
