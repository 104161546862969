import { Space } from 'antd';
import icInforGray from 'assets/icons/info-gray.svg';

interface LabelTaskNameProps {
  label?: string;
}

const LabelTaskName = ({ label }: LabelTaskNameProps) => {
  return (
    <Space align="center" size={3}>
      <img src={icInforGray} alt="" style={{ width: '18px', height: '18px', marginTop: '-2px' }} />
      <span>{label}</span>
    </Space>
  );
};

export default LabelTaskName;
