import { IFilter, TResCustomerDetails, TResCustomerMultiCompany } from 'common/interface';
import { sendGet, sendPost, sendPut } from './axios';

export const getCustomerList = (filter: Partial<IFilter>) => sendPost('/customer', filter);
export const updateCustomerStatus = (payload: any) => sendPut('/customer/update-status', payload);
export const addCustomer = (payload: any) => sendPut('/customer/invite', payload);
export const getCustomerDetail = (id: number) => sendPost<TResCustomerDetails>(`/customer/${id}`);
export const updateCustomer = (id: number, payload: any) => sendPut(`/customer/${id}`, payload);
export const getCustomerConstructionSite = (id: number, filter: Partial<IFilter>) =>
  sendPost(`/customer/${id}/list-construction`, filter);
export const createCustomer = (payload: any) => sendPut('/customer/create', payload);
export const getCustomerPaper = (id: number, payload: any) => sendPost(`customer/${id}/list-paper`, payload);

// v2
interface ISearchNameCompany {
  pageIndex: number;
  pageSize: number;
  keyword: string;
}
export const searchNameCompany = (payload: ISearchNameCompany) =>
  sendPost('/v2/construction-site/search-name-company', payload);

//v4

export const getCustomerListByCompany = (filter: Partial<IFilter>) =>
  sendGet('/v4/task/list-customer-by-company', filter);

export const getCustomerListByCompanyNest = (filter: Partial<IFilter>) =>
  sendPost<TResCustomerMultiCompany>('/nest/calendar/search-customer', filter);
