import { dateUtils } from 'common/dateUtils';
import { ITaskDetail } from 'common/interface';

export const getNamePdfFile = (taskDetail?: ITaskDetail) => {
  if (!taskDetail) return null;

  const fileName = [taskDetail?.constructionSiteName, `手配${dateUtils.formatYYYYMMDD(taskDetail?.startDate)}`].join(
    '_'
  );

  return `${fileName}.pdf`;
};
