import { getCompanyColors } from 'api/company';
import { colorsPicker } from 'common/const';
import queryKeys from 'common/queryKeys';
import { randomColor } from 'helper';
import { keyBy } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import configs from 'config';
import useListCompanyActive from './useListCompanyActive';

const useListColorCompany = (enabled = false) => {
  const queryClient = useQueryClient();

  const { listCompanyActive } = useListCompanyActive();

  // const isInDevMode = configs.APP_ENV === 'dev' || configs.APP_ENV === 'staging';

  const { data, ...rest } = useQuery({
    queryFn: getCompanyColors,
    queryKey: queryKeys.company.listColor,
    enabled,
  });

  const inValidListColorCompany = () => queryClient.invalidateQueries(queryKeys.company.listColor);

  const listColorCompanyObj = data ? keyBy(data.data, 'companyId') : {};
  const getDefaultColor = (companyId: number, index: number) => {
    const lengthColorsDefault = colorsPicker.length;
    const indexColor = index >= lengthColorsDefault ? index % lengthColorsDefault : index;

    return listColorCompanyObj?.[companyId]?.color || colorsPicker?.[indexColor];
  };

  const listCompanyWithColor = listCompanyActive?.map((company, index) => ({
    ...company,
    color: getDefaultColor(company.id, index),
  }));

  const listCompanyWithColorObj = keyBy(listCompanyWithColor, 'id');

  return {
    inValidListColorCompany,
    listCompanyWithColor,
    listCompanyWithColorObj,
    ...rest,
  };
};

export default useListColorCompany;
