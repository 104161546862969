import { useMemo } from 'react';
import useRoleId from './useRoleId';
import useProfile from './useProfile';
import { CommonStatus } from 'common';
import { REVENUE_ACCESS } from 'common/const';

const useViewRoutes = () => {
  const roleId = useRoleId();
  const profile = useProfile();

  // check permission to view revenue
  const isCanViewRevenue = useMemo(() => {
    return REVENUE_ACCESS.includes(roleId) && profile?.company?.isDisableViewRevenue === CommonStatus.INACTIVE;
  }, [profile, roleId]);

  return { isCanViewRevenue };
};

export default useViewRoutes;
