import { Button, Checkbox, Col, Form, InputNumber, Row, Select, Typography } from 'antd';
import { createCompany } from 'api/profile';
import icAdd from 'assets/icons/plus-circle.svg';
import { CommonStatus, CompanyScheduleDay, STATUS, SubscriptionType, TYPE_INPUT, TYPE_PICKER, UserRole } from 'common';
import {
  DEFAULT_PLAN_ID,
  listCompanyBusinessField,
  listDateClosingSalary,
  listDayOfWeek,
  listSalaryApplyDate,
  listSubscriptionType,
  MAX_LENGTH,
  MAX_LENGTH_CHARTER_CAPITAL,
  messageError,
  PATTERN_EMAIL,
  PATTERN_FAX,
  PATTERN_PHONE,
  shiftBlockTime,
  textJP,
} from 'common/const';
import { dateUtils } from 'common/dateUtils';
import { BannerRightHeader } from 'components/Banner';
import { CommonButton } from 'components/CommonButton';
import { CommonDatepicker } from 'components/CommonDatepicker';
import { CommonInput } from 'components/CommonInput';
import { CommonModal } from 'components/CommonModal';

import { CommonSelect } from 'components/CommonSelect';
import { FormListBreakTime } from 'components/FormListBreakTime';
import { UploadFile } from 'components/UploadFile';
import { breakTimeCompany, handleErrorMessage, navigateToApp } from 'helper';
import useProfileCompany from 'hooks/useProfileCompany';
import mobile from 'is-mobile';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useSetState } from 'react-use';
import { PendingSignUp } from './PendingSignUp';
import styles from './styles.module.scss';
import { useRulesForm } from 'hooks/useRulesForm';
import useResource from 'hooks/useResource';
import { allResource } from 'api/init';
import { getListSubscriptions } from 'api/subscription';
import ModalPlans from 'components/Plans/ModalPlans';
import { IPlan } from 'common/interface';
import { isNil } from 'lodash';

const { Title } = Typography;
type Shift = 'morning' | 'afternoon' | 'evening';

interface IProps {
  closeListCompany?: any;
}

export default function CompanySignUp({ closeListCompany }: IProps) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useSetState({ openModalSignUp: false, openModalConfirm: false });
  const [openModalPlans, setOpenModalPlans] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<IPlan | null>(null);
  const { refetchProfileCompany } = useProfileCompany();
  const [checked, setChecked] = useSetState({
    morning: true,
    afternoon: false,
    evening: false,
  });

  const queryClient = useQueryClient();
  const isMobile = mobile();
  const token = useMemo(() => Cookies.get('token'), []);
  const refreshToken = useMemo(() => Cookies.get('refreshToken'), []);
  const { rulesForm } = useRulesForm();
  const { data: subscriptions } = useQuery(['subscriptions-list'], () =>
    getListSubscriptions({
      status: [CommonStatus.ACTIVE],
    })
  );
  const [isSelectOtherPlan, setIsSelectOtherPlan] = useState(false);
  const [isSelectSubscriptionTypeStripe, setIsSelectSubscriptionTypeStripe] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const { data: listResource } = useQuery('resource', () => allResource());
  const ken = listResource?.data?.ken || [];
  const listPlans = isSelectSubscriptionTypeStripe ? subscriptions?.data?.slice(0, 1) : subscriptions?.data || [];
  const defaultPlan = listPlans.find((plan: IPlan) => plan.id === DEFAULT_PLAN_ID);

  useEffect(() => {
    form.setFieldsValue({
      morningShift: true,
      afternoonShift: false,
      eveningShift: false,
      morningFrom: '08:00',
      morningTo: '17:00',
      blockMinute: '5',
      admin: [{ hour: 8, minute: 0, break: 60 }],
      manager: [{ hour: 8, minute: 0, break: 60 }],
      officeManager: [{ hour: 8, minute: 0, break: 60 }],
      leader: [{ hour: 8, minute: 0, break: 60 }],
      worker: [{ hour: 8, minute: 0, break: 60 }],
      otherWorker: [{ hour: 8, minute: 0, break: 60 }],

      partTimeWorker: [{ hour: 8, minute: 0, break: 60 }],
      dateRegisterSchedule: CompanyScheduleDay.FRIDAY,
      salaryClosingDate: 0,
      salaryPaymentDelayMonth: 1,
      salaryPaymentDate: 0,
    });
  }, [open]);
  const handleOpen = () => {
    if (isMobile) {
      return navigateToApp(token!, refreshToken!);
    } else {
      setOpen({ openModalSignUp: true });
    }
  };

  const handleSubmit = async (data: any) => {
    if (!selectedPlan?.id) return;

    try {
      setLoading(true);

      const subscriptionId = selectedPlan?.id ?? null;

      const newPayload = {
        kenId: data?.kenId || null,
        businessField: data?.businessField || null,
        avatar: data?.avatar || null,
        stamp: data?.stamp || '',
        name: data?.name ?? null,
        email: data?.email ?? null,
        charterCapital: data?.charterCapital ?? null,
        phone: data?.phone ?? null,
        postalCode: data?.postalCode ?? null,
        address: data?.address ?? '',
        representativeName: data?.representativeName ?? '',
        faxNumber: data?.faxNumber ?? null,
        description: data?.description || '',
        morningFrom: data?.morningFrom
          ? typeof data?.morningFrom === 'string'
            ? data?.morningFrom
            : dateUtils.getHour(data?.morningFrom)
          : null,
        morningTo: data?.morningTo
          ? typeof data?.morningTo === 'string'
            ? data?.morningTo
            : dateUtils.getHour(data?.morningTo)
          : null,
        subscriptionType: data?.subscriptionType,
        afternoonFrom: data?.afternoonFrom ? dateUtils.getHour(data?.afternoonFrom) : null,
        afternoonTo: data?.afternoonTo ? dateUtils.getHour(data?.afternoonTo) : null,
        eveningFrom: data?.eveningFrom ? dateUtils.getHour(data?.eveningFrom) : null,
        eveningTo: data?.eveningTo ? dateUtils.getHour(data?.eveningTo) : null,
        establishedAt: data?.establishedAt ? dateUtils.getDateToString(data?.establishedAt) : null,
        morningShift: data?.morningShift ? STATUS.ACTIVE : STATUS.INACTIVE,
        afternoonShift: data?.afternoonShift ? STATUS.ACTIVE : STATUS.INACTIVE,
        eveningShift: data?.eveningShift ? STATUS.ACTIVE : STATUS.INACTIVE,
        blockMinute: Number(data.blockMinute),
        salaryPaymentDelayMonth: data?.salaryPaymentDelayMonth,
        dateRegisterSchedule: data?.dateRegisterSchedule,
        salaryClosingDate: data?.salaryClosingDate,
        salaryPaymentDate: data?.salaryPaymentDate,
        breakTime: breakTimeCompany(data),
        registrationNumber: data?.registrationNumber ? `T${data?.registrationNumber}` : null,
        subscriptionId,
      };
      const newCompany = await createCompany(newPayload);
      Cookies.set('companyId', `${newCompany?.data?.id}`);
      Cookies.set('roleId', `${UserRole.ADMIN}`);
      if (newCompany?.data?.subscriptionType === SubscriptionType.ADMIN_BUY) {
        history.push('/calendar');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        refetchProfileCompany();
        handleClose();
      } else {
        history.push('/subscription-intro');
      }
      queryClient.invalidateQueries('commonCompany');
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
      closeListCompany();
    }
  };
  const handleClose = () => {
    form.resetFields();
    setOpen({ openModalSignUp: false });
    setSelectedPlan(null);
    setIsSubmit(false);
    setIsSelectOtherPlan(false);
  };

  const handleSelectShift = (e: any, shift: Shift) => {
    switch (shift) {
      case 'morning':
        setChecked({
          morning: e.target.checked,
        });
        break;
      case 'afternoon':
        setChecked({
          afternoon: e.target.checked,
        });
        break;
      case 'evening':
        setChecked({
          evening: e.target.checked,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!checked.morning) {
      form.setFieldsValue({
        morningFrom: undefined,
        morningTo: undefined,
      });
    }
    if (!checked.afternoon) {
      form.setFieldsValue({
        afternoonFrom: undefined,
        afternoonTo: undefined,
      });
    }
    if (!checked.evening) {
      form.setFieldsValue({
        eveningFrom: undefined,
        eveningTo: undefined,
      });
    }
  }, [form, checked]);

  return (
    <>
      <Button className={styles.addCompanyButton} onClick={handleOpen}>
        <img src={icAdd} alt="" />
        <b>企業を作成</b>
      </Button>
      <CommonModal
        handleClose={handleClose}
        isModalVisible={open.openModalSignUp}
        title=""
        form={form}
        footer={null}
        maskClosable={false}
        width={1100}
        className={`${styles.signupCompany} ${styles.pModal}`}
      >
        <BannerRightHeader />
        <Title level={2} className={styles.title}>
          企業概要
        </Title>
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={(value) => {
            const [field] = value;
            const [fieldName] = field?.name as Array<string>;

            // if (fieldName === 'subscriptionId') {
            //   if (field?.value !== 1) {
            //     setIsSelectOtherPlan(true);
            //     form.setFieldsValue({ subscriptionType: SubscriptionType.ADMIN_BUY });
            //   } else {
            //     setIsSelectOtherPlan(false);
            //   }
            // } else

            if (fieldName === 'subscriptionType') {
              if (field?.value === SubscriptionType.STRIPE) {
                setIsSelectSubscriptionTypeStripe(true);
                //
                form.setFieldsValue({ subscriptionId: DEFAULT_PLAN_ID });
                setSelectedPlan(defaultPlan);
              } else {
                setIsSelectSubscriptionTypeStripe(false);
              }
            }
          }}
          onFinish={handleSubmit}
          scrollToFirstError={true}
        >
          <input id="password" type="password" className="hidden-autocomplete" />
          <input id="email" className="hidden-autocomplete" />
          <div className={styles.boxAvatar}>
            <Form.Item label="" name="avatar">
              <UploadFile shape="rectangle" />
            </Form.Item>
          </div>
          <Row gutter={20}>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="企業名（屋号）"
                name="name"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
              >
                <CommonInput />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="企業の設立日" name="establishedAt">
                <CommonDatepicker />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="資本金(￥)" name="charterCapital">
                <InputNumber
                  type="number"
                  min={0}
                  maxLength={MAX_LENGTH_CHARTER_CAPITAL}
                  className="inputGrey"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="メール"
                name="email"
                rules={[
                  {
                    pattern: PATTERN_EMAIL,
                    message: 'メールが違います',
                  },
                ]}
              >
                <CommonInput />
              </Form.Item>
            </Col>

            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="都道府県" name="kenId" rules={[rulesForm().required]}>
                <CommonSelect options={ken} allowClear />
              </Form.Item>
            </Col>

            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="電話番号"
                name="phone"
                rules={[
                  {
                    pattern: PATTERN_PHONE,
                    message: '電話番号の形式が正しくありません。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="ファックス"
                name="faxNumber"
                rules={[
                  {
                    pattern: PATTERN_FAX,
                    message: 'ファックスの形式が正しくありません。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="住所" name="address" labelAlign="left">
                <CommonInput maxLength={MAX_LENGTH * 2} />
              </Form.Item>
            </Col>

            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="雇用保険二事業の保険" name="businessField" rules={[rulesForm().required]}>
                <CommonSelect options={listCompanyBusinessField} allowClear />
              </Form.Item>
            </Col>

            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="代表者名" name="representativeName" labelAlign="left">
                <CommonInput />
              </Form.Item>
            </Col>

            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="郵便番号" name="postalCode" labelAlign="left">
                <CommonInput />
              </Form.Item>
            </Col>

            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="登録番号"
                name="registrationNumber"
                rules={rulesForm().registrationNumber}
                labelAlign="left"
                validateFirst
              >
                <CommonInput className="border-none" maxLength={13} prefix="T" />
              </Form.Item>
            </Col>

            {/* <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="プラン選択"
                name="subscriptionId"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonSelect
                  options={
                    isSelectSubscriptionTypeStripe ? subscriptions?.data?.slice(0, 1) : subscriptions?.data || []
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="支払い方法"
                name="subscriptionType"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonSelect
                  options={
                    isSelectOtherPlan
                      ? listSubscriptionType?.filter((item) => item?.id === SubscriptionType.ADMIN_BUY)
                      : listSubscriptionType
                  }
                />
              </Form.Item>
            </Col> */}

            <Col span={24}>
              <Form.Item label="企業情報" name="description" labelAlign="left">
                <CommonInput mode={TYPE_INPUT.MULTIPLE} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Title level={5}>勤務時間</Title>
            </Col>
            <Col span={12}>
              <Row gutter={20}>
                <Col span={24}>
                  <Title level={5}>
                    <Form.Item className="mb-0" name="morningShift" valuePropName="checked">
                      <Checkbox
                        disabled={!checked.afternoon && !checked.evening && checked.morning}
                        onChange={(e: any) => handleSelectShift(e, 'morning')}
                      >
                        午前
                      </Checkbox>
                    </Form.Item>
                  </Title>
                </Col>
                <Col span={12} xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="開始"
                    name="morningFrom"
                    rules={[
                      {
                        required: checked.morning,
                        message: '全ての項目を入力してください。',
                      },
                    ]}
                    labelAlign="left"
                  >
                    <CommonDatepicker disabled={!checked.morning} picker={TYPE_PICKER.TIME} />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="終了"
                    name="morningTo"
                    rules={[
                      {
                        required: checked.morning,
                        message: '全ての項目を入力してください。',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          const beforeValue = getFieldValue('morningFrom');
                          const newValue = typeof value === 'object' ? value : dateUtils.convertTime(value);
                          const isAfter =
                            value &&
                            dateUtils.isAfter(
                              typeof beforeValue === 'object' ? beforeValue : dateUtils.convertTime(beforeValue),
                              newValue
                            );
                          if (!value || isAfter) {
                            return Promise.resolve();
                          }
                          return Promise.reject('入力した時間が正しくありません。');
                          //Thời gian kết thúc buổi sáng phải sau thời gian bắt đầu buổi sáng
                        },
                      }),
                    ]}
                    labelAlign="left"
                  >
                    <CommonDatepicker disabled={!checked.morning} picker={TYPE_PICKER.TIME} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={20}>
                <Col span={24}>
                  <Title level={5}>
                    <Form.Item className="mb-0" name="afternoonShift" valuePropName="checked">
                      <Checkbox
                        disabled={!checked.morning && !checked.evening && checked.afternoon}
                        onChange={(e: any) => handleSelectShift(e, 'afternoon')}
                      >
                        午後
                      </Checkbox>
                    </Form.Item>
                  </Title>
                </Col>

                <Col span={12} xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="開始"
                    name="afternoonFrom"
                    rules={[
                      {
                        required: checked.afternoon,
                        message: '全ての項目を入力してください。',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          const beforeValue = getFieldValue('morningTo');
                          const isAfter = dateUtils.isBeforeTime(beforeValue, value);
                          if (!value || isAfter || !checked.morning) {
                            return Promise.resolve();
                          }
                          return Promise.reject('入力した時間が正しくありません。');
                          // Thời gian bắt đầu buổi trưa phải sau thời gian kết thúc buổi sáng
                        },
                      }),
                    ]}
                    labelAlign="left"
                  >
                    <CommonDatepicker disabled={!checked.afternoon} picker={TYPE_PICKER.TIME} />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="終了"
                    name="afternoonTo"
                    rules={[
                      {
                        required: checked.afternoon,
                        message: '全ての項目を入力してください。',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          const beforeValue = getFieldValue('afternoonFrom');
                          const newValue = typeof value === 'object' ? value : dateUtils.convertTime(value);
                          const isAfter =
                            value &&
                            dateUtils.isAfter(
                              typeof beforeValue === 'object' ? beforeValue : dateUtils.convertTime(beforeValue),
                              newValue
                            );
                          if (!value || isAfter) {
                            return Promise.resolve();
                          }
                          return Promise.reject('入力した時間が正しくありません。');
                          // Thời gian kết thúc buổi trưa phải sau thời gian bắt đầu buổi trưa
                        },
                      }),
                    ]}
                    labelAlign="left"
                  >
                    <CommonDatepicker disabled={!checked.afternoon} picker={TYPE_PICKER.TIME} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={20}>
                <Col span={24}>
                  <Title level={5}>
                    <Form.Item className="mb-0" name="eveningShift" valuePropName="checked">
                      <Checkbox
                        disabled={!checked.morning && !checked.afternoon && checked.evening}
                        onChange={(e: any) => handleSelectShift(e, 'evening')}
                      >
                        夜間
                      </Checkbox>
                    </Form.Item>
                  </Title>
                </Col>

                <Col span={12} xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="開始"
                    name="eveningFrom"
                    rules={[
                      {
                        required: checked.evening,
                        message: '全ての項目を入力してください。',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          const afternoonTo = getFieldValue('afternoonTo');
                          const morningTo = getFieldValue('morningTo');
                          const beforeValue = afternoonTo || morningTo;

                          const isAfter = dateUtils.isBeforeTime(beforeValue, value);
                          if (!value || isAfter || (!checked.morning && !checked.afternoon)) {
                            return Promise.resolve();
                          }
                          return Promise.reject('入力した時間が正しくありません。');
                          // Thời gian bắt đầu buổi tối phải sau thời gian kết thúc buổi trưa
                        },
                      }),
                    ]}
                    labelAlign="left"
                  >
                    <CommonDatepicker disabled={!checked.evening} picker={TYPE_PICKER.TIME} />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="終了"
                    name="eveningTo"
                    rules={[
                      {
                        required: checked.evening,
                        message: '全ての項目を入力してください。',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          const eveningFrom = getFieldValue('eveningFrom');
                          const startFrom = getFieldValue('morningFrom') || getFieldValue('afternoonFrom');

                          const newValue = typeof value === 'object' ? value : dateUtils.convertTime(value);
                          const isAfter =
                            value &&
                            dateUtils.isAfter(
                              typeof eveningFrom === 'object' ? eveningFrom : dateUtils.convertTime(eveningFrom),
                              newValue
                            );
                          const isBefore =
                            value &&
                            dateUtils.isAfter(
                              newValue,
                              typeof startFrom === 'object' ? startFrom : dateUtils.convertTime(startFrom)
                            );
                          if (!value || isAfter || isBefore) {
                            return Promise.resolve();
                          }
                          return Promise.reject('入力した時間が正しくありません。');
                          // Thời gian kết thúc buổi tối phải sau thời gian bắt đầu buổi tối
                        },
                      }),
                    ]}
                    labelAlign="left"
                  >
                    <CommonDatepicker disabled={!checked.evening} picker={TYPE_PICKER.TIME} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Title level={5}>ブロック単位</Title>
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item
                label="ブロック単位(分)"
                name="blockMinute"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonSelect optionValue="name" options={shiftBlockTime} />
              </Form.Item>
            </Col>
          </Row>
          <Title level={5}>休憩時間</Title>
          <Row gutter={20}>
            <Col span={12}>
              <FormListBreakTime nameList={'admin'} initialValue={[{ hour: null, minute: null }]} title="アドミン" />
            </Col>
            <Col span={12}>
              <FormListBreakTime
                nameList={'manager'}
                initialValue={[{ hour: null, minute: null }]}
                title="マネージャー"
              />
            </Col>

            <Col span={12}>
              <FormListBreakTime
                nameList={'officeManager'}
                initialValue={[{ hour: null, minute: null }]}
                title="オフィスマネージャー"
              />
            </Col>

            <Col span={12}>
              <FormListBreakTime nameList={'leader'} initialValue={[{ hour: null, minute: null }]} title="リーダー" />
            </Col>

            <Col span={12}>
              <FormListBreakTime nameList={'worker'} initialValue={[{ hour: null, minute: null }]} title="社員" />
            </Col>

            <Col span={12}>
              <FormListBreakTime
                nameList={'otherWorker'}
                initialValue={[{ hour: null, minute: null }]}
                title="フリーランス"
              />
            </Col>

            <Col span={12}>
              <FormListBreakTime
                nameList={'partTimeWorker'}
                initialValue={[{ hour: null, minute: null }]}
                title="アルバイト
                "
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Title level={5}>シフト希望の締切り</Title>
              <Form.Item
                label="シフト希望の締切り"
                name="dateRegisterSchedule"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonSelect options={listDayOfWeek} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Title level={5}>毎月締め日</Title>
              <Form.Item
                label="毎月締め日"
                name="salaryClosingDate"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonSelect
                  options={listDateClosingSalary}
                  onChange={() => {
                    form.setFieldsValue({ salaryPaymentDelayMonth: undefined, salaryPaymentDate: undefined });
                  }}
                />
              </Form.Item>
            </Col>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => {
                const salaryClosingDate = getFieldValue('salaryClosingDate');
                // 0 : end of month, 28 : 28th of month
                let listDelayOption = [...listSalaryApplyDate];
                if (salaryClosingDate === 0 || salaryClosingDate === 28) {
                  listDelayOption = listDelayOption.filter((el: any) => el.id !== 0);
                }
                return (
                  <Col span={6}>
                    <Title level={5}>支払日</Title>
                    <Form.Item
                      label="支払日"
                      name="salaryPaymentDelayMonth"
                      rules={[
                        {
                          required: true,
                          message: '全ての項目を入力してください。',
                        },
                      ]}
                      labelAlign="left"
                    >
                      <CommonSelect options={listDelayOption} />
                    </Form.Item>
                  </Col>
                );
              }}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const salaryClosingDate = getFieldValue('salaryClosingDate');
                const salaryPaymentDelayMonth = getFieldValue('salaryPaymentDelayMonth');
                let listOptions = [...listDateClosingSalary];
                // salaryPaymentDelayMonth === this month
                if (salaryClosingDate && salaryPaymentDelayMonth === 0) {
                  listOptions = listOptions.filter((el: any) => el.id > salaryClosingDate);
                  listOptions = [
                    {
                      id: 0,
                      name: '月末日',
                    },
                    ...listOptions,
                  ];
                }

                if (salaryPaymentDelayMonth === undefined) return null;
                return (
                  <Col span={6}>
                    <Title level={5}>&nbsp;</Title>
                    <Form.Item
                      label=" "
                      name="salaryPaymentDate"
                      rules={[
                        {
                          required: true,
                          message: '全ての項目を入力してください。',
                        },
                      ]}
                      labelAlign="left"
                    >
                      <CommonSelect options={listOptions} />
                    </Form.Item>
                  </Col>
                );
              }}
            </Form.Item>
          </Row>
          <Row style={{ marginBottom: '20px' }}>(報酬の編集日は支払日の1日前)</Row>
          <Row justify="start">
            <Col span={12}>
              <Form.Item
                label="印影"
                name="stamp"
                labelAlign="left"
                // style={{ textAlign: 'left' }}
                className="mb-0"
              >
                <UploadFile shape="square" showDelete isCrop />
              </Form.Item>
            </Col>
          </Row>
          <div className="strong fs-16px">{textJP.planSettings}</div>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="subscriptionType"
                label={textJP.paymentMethod}
                className="mb-0"
                rules={[rulesForm().required]}
              >
                <CommonSelect
                  options={
                    isSelectOtherPlan
                      ? listSubscriptionType?.filter((item) => item?.id === SubscriptionType.ADMIN_BUY)
                      : listSubscriptionType
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="" label={textJP.planName} className="mb-0" required>
                <Row gutter={20}>
                  <Col flex={1}>
                    <CommonInput disabled style={{ flex: 1, width: '100%' }} value={selectedPlan?.name ?? ''} />

                    {isNil(selectedPlan) && isSubmit && (
                      <div className="mt-3 text-error fontsize-13">{messageError.requiredField}</div>
                    )}
                  </Col>
                  <Col>
                    <CommonButton
                      title={textJP.btn.choice}
                      type="primary"
                      className=""
                      style={{ width: '140px' }}
                      onClick={() => setOpenModalPlans(true)}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item style={{ textAlign: 'right' }}>
                <CommonButton onClick={handleClose} title="戻る " className={'btnWhite'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <CommonButton
                  title="新規登録"
                  className={'btnPrimary'}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={() => {
                    setIsSubmit(true);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CommonModal>
      <PendingSignUp openModalConfirm={open.openModalConfirm} setOpen={setOpen} />
      <ModalPlans
        form={form}
        selectedPlan={selectedPlan}
        plans={listPlans}
        visible={openModalPlans}
        onCancel={() => {
          setOpenModalPlans(false);
        }}
        onSelectPlan={(plan) => {
          // save selected plan
          setSelectedPlan(plan);

          //
          if (plan.id !== DEFAULT_PLAN_ID) {
            setIsSelectOtherPlan(true);
            form.setFieldsValue({ subscriptionType: SubscriptionType.ADMIN_BUY });
          } else {
            setIsSelectOtherPlan(false);
          }

          // close modal after select plan
          setOpenModalPlans(false);
        }}
      />
    </>
  );
}
