import { Checkbox, Col, Form, message, Radio, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { updateProfileVendor } from 'api/profile';

import {
  BIG_INPUT_NUMBER,
  listDateClosingSalary,
  listTypePaymentOffsetWithMonth,
  monthPaymentName,
  PATTERN_EMAIL,
  PATTERN_FAX,
  PATTERN_PHONE,
} from 'common/const';
import { CommonButton } from 'components/CommonButton';
import { CommonDatepicker } from 'components/CommonDatepicker';
import { CommonInput } from 'components/CommonInput';
import { CommonInputNumber } from 'components/CommonInputNumber';
import { CommonModal } from 'components/CommonModal';
import { CommonSelect } from 'components/CommonSelect';
import { UploadFile } from 'components/UploadFile';
import { getPayloadServiceChargeFromApi, handleErrorMessage } from 'helper';
import { validateMaxMinInput } from 'helper/validator';
import useResource from 'hooks/useResource';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import style from './styles.module.scss';
import FormPrice from 'pages/Customer/components/FormPrice';
import FormCertificate from 'pages/Customer/components/FormCertificate';
import FormCertificateVendor from './components/FormCertificateVendor';
import CommonRadioGroup from 'components/CommonRadioGroup';
import { TaxType } from 'common';
import { useRulesForm } from 'hooks/useRulesForm';
import FormServiceCharge from 'pages/Customer/components/FormServiceCharge';

interface IProp {
  showAddVendor: boolean;
  handleClose: () => void;
  currentInviteVendor?: any;
}

export const FormCheckInfoVendor = ({ showAddVendor, handleClose, currentInviteVendor }: IProp) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { rulesForm } = useRulesForm();

  const listResource = useResource();
  const listCardType = listResource?.cardType;

  useEffect(() => {
    if (!currentInviteVendor) return;

    const newInviteVendor = {
      ...currentInviteVendor,
      ...(currentInviteVendor?.metadata ? JSON.parse(currentInviteVendor?.metadata) : {}),
    };

    const [{ title: titleCertificate, price: priceCertificate }, ...rest] =
      newInviteVendor?.dataUnitPriceCertificate || [{ title: '', price: '' }];
    const [{ title: titleCertificateVendor, price: priceCertificateVendor }, ...restCertificateVendor] =
      newInviteVendor?.dataUnitPriceCertificateVendor || [{ title: '', price: '' }];

    let defaultFormValues = {
      ...newInviteVendor,
      titleCertificate,
      priceCertificate,
      dataUnitPriceCertificate: rest,
      titleCertificateVendor,
      priceCertificateVendor,
      dataUnitPriceCertificateVendor: restCertificateVendor,
      registrationNumber: newInviteVendor?.registrationNumber?.replace('T', '') || null,
    };

    defaultFormValues = getPayloadServiceChargeFromApi(defaultFormValues);

    form.setFieldsValue(defaultFormValues);
  }, [form, currentInviteVendor]);

  const handleUpdateVendor = async (data: any) => {
    try {
      // setLoading(true);
      const newPayload = {
        email: data.email,
        address: data.address,
        faxNumber: data.faxNumber,
        postalCode: data.postalCode,
        phone: data.phoneNumber,
        companyName: data.companyName,
        branch: data.bankInfo.branch,
        cardNumber: data.bankInfo.cardNumber,
        ownerName: data.bankInfo.ownerName,
        cardType: data.bankInfo.type,
        bankName: data.bankInfo.bankName,
        stamp: data.stamp,
      };

      await updateProfileVendor(newPayload);
      queryClient.refetchQueries();
      handleClose();
      message.success('成功');
      setOpen(true);
      form.resetFields();
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
      Cookies.remove('inviteToken');
    }
  };

  return (
    <>
      <CommonModal
        title=""
        centered
        isModalVisible={showAddVendor}
        handleClose={handleClose}
        form={form}
        footer=""
        className={`${style.modalAddVendor} ${style.pModal}`}
        width={1000}
      >
        <div className={style.head}>
          <div className={style.name}>情報を確認</div>
        </div>
        <div className={`heading-16 mb-25`}>基本情報</div>
        <Form form={form} layout="vertical" onFinish={handleUpdateVendor} scrollToFirstError={true}>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label="メール"
                name="email"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                    whitespace: true,
                  },
                  {
                    pattern: PATTERN_EMAIL,
                    message: 'メールが違います',
                  },
                ]}
              >
                <CommonInput disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="企業名"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                    whitespace: true,
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="電話番号"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                  {
                    pattern: PATTERN_PHONE,
                    message: '電話番号の形式が正しくありません。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="ファックス"
                name="faxNumber"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                  {
                    pattern: PATTERN_FAX,
                    message: 'ファックスの形式が正しくありません。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="郵便番号"
                name="postalCode"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                    whitespace: true,
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="住所"
                name="address"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                    whitespace: true,
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className={`heading-16 mb-25`}>その他の情報</div>
            </Col>

            <Col span={12}>
              <Form.Item label="契約締結日" name="contractDate" labelAlign="left">
                <CommonDatepicker disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="締め日" name="salaryClosingDate" labelAlign="left">
                <CommonSelect options={listDateClosingSalary} disabled />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Row gutter={20}>
                <Col span={6}>
                  <Form.Item label="支払月" name={monthPaymentName} rules={[rulesForm().required]}>
                    <CommonSelect options={listTypePaymentOffsetWithMonth} className={style.selectCustom} disabled />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="支払い" name="paymentDate" labelAlign="left">
                    <CommonSelect options={listDateClosingSalary} disabled className={style.customSelect} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="登録番号"
                    name="registrationNumber"
                    rules={rulesForm().registrationNumber}
                    labelAlign="left"
                    validateFirst
                  >
                    <CommonInput className="border-none" maxLength={13} prefix="T" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Form.Item label="" name="isTax">
                <CommonRadioGroup defaultValue={TaxType.INACTIVE} disabled>
                  <Radio value={TaxType.INACTIVE}>外税</Radio>
                  <Radio value={TaxType.ACTIVE}>内税</Radio>
                </CommonRadioGroup>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="人工の単価" name="regularUnitPrice" labelAlign="left">
                <CommonInputNumber disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="人工の単価（夜間)" name="nightUnitPrice" labelAlign="left">
                <CommonInputNumber disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item valuePropName="checked" label="" name="isSettingAutoJoinProject">
                <Checkbox className={style.checkbox} disabled>
                  招待したプロジェクトに自動的に参加する。
                </Checkbox>
              </Form.Item>
            </Col>

            {/* Certificate of vendor */}
            <FormCertificateVendor showIcons={false} disabledAll />

            <Col span={24}>
              <Form.Item valuePropName="checked" label="" name={'isCustomer'}>
                <Checkbox disabled>顧客になりますか。</Checkbox>
              </Form.Item>
            </Col>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const isCustomer = getFieldValue('isCustomer');

                if (isCustomer) {
                  return (
                    <Row gutter={20}>
                      {/* Đơn giá khách hàng */}
                      <FormPrice isInviteVendorCustomer />

                      {/* Chứng chỉ của khách hàng */}
                      <FormCertificate />

                      <Col span={12}>
                        <FormServiceCharge form={form} disabledAll />
                      </Col>
                    </Row>
                  );
                }
              }}
            </Form.Item>

            <Col span={24}>
              <Form.Item
                label="印影"
                name="stamp"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
              >
                <UploadFile shape="square" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className={`heading-16 mb-25`}>銀行口座情報</div>
            </Col>

            <Col span={24}>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    label="金融機関名"
                    name={['bankInfo', 'bankName']}
                    rules={[
                      {
                        required: true,
                        message: '全ての項目を入力してください。',
                        whitespace: true,
                      },
                    ]}
                    labelAlign="left"
                  >
                    <CommonInput />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="支店名"
                    name={['bankInfo', 'branch']}
                    rules={[
                      {
                        required: true,
                        message: '全ての項目を入力してください。',
                        whitespace: true,
                      },
                    ]}
                    labelAlign="left"
                  >
                    <CommonInput />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    label="口座種別"
                    name={['bankInfo', 'type']}
                    rules={[
                      {
                        required: true,
                        message: '全ての項目を入力してください。',
                      },
                    ]}
                    labelAlign="left"
                  >
                    <CommonSelect options={listCardType} showSearch={false} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="口座番号"
                    name={['bankInfo', 'cardNumber']}
                    rules={[
                      {
                        required: true,
                        message: '全ての項目を入力してください。',
                        whitespace: true,
                      },
                      validateMaxMinInput(BIG_INPUT_NUMBER, 0),
                    ]}
                    labelAlign="left"
                  >
                    <CommonInput />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    label="口座名義"
                    name={['bankInfo', 'ownerName']}
                    rules={[
                      {
                        required: true,
                        message: '全ての項目を入力してください。',
                        whitespace: true,
                      },
                    ]}
                    labelAlign="left"
                  >
                    <CommonInput />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Form.Item label="メモ" name="note">
                <TextArea autoSize={{ minRows: 6, maxRows: 6 }} className={`inputGrey`} disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} offset={6}>
              <Form.Item>
                <CommonButton
                  title="確認"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className={style.btnPrimary}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};
