import { Space } from 'antd';
import { emptyData } from 'common/const';
import { ICompanyActive, ITaskDetail } from 'common/interface';
import useListColorCompany from 'hooks/apis/useListColorCompany';
import { isNil } from 'lodash';
import styles from './styles.module.scss';

interface IProps {
  taskDetail?: ITaskDetail;
  companySelected?: ICompanyActive;
}

const NameCompanyWithColor = ({ companySelected, taskDetail }: IProps) => {
  const { listCompanyWithColorObj } = useListColorCompany();

  return (
    <Space>
      <div
        className={styles.colorCompany}
        style={{
          backgroundColor: isNil(taskDetail?.companyId)
            ? ''
            : listCompanyWithColorObj?.[taskDetail?.companyId as number]?.color,
        }}
      />
      <span className="strong fontsize-13">{companySelected?.name || emptyData}</span>
    </Space>
  );
};

export default NameCompanyWithColor;
