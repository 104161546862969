import { FormInstance, Modal, ModalProps } from 'antd';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';

export interface IPropsModal extends ModalProps {
  isModalVisible?: boolean;
  handleClose?: () => any;
  handleSubmit?: (data: any) => any;
  children?: ReactNode;
  title?: string | ReactNode;
  okText?: string | ReactNode;
  cancelText?: string;
  width?: number;
  form?: FormInstance<any>;
  titleCenter?: boolean;
  resetPadding?: boolean;
}

export const CommonModal = ({
  isModalVisible,
  handleClose,
  handleSubmit,
  children,
  form,
  title,
  cancelText = 'キャンセル',
  okText = '保存 ',
  width,
  className,
  titleCenter = false,
  resetPadding = false,
  ...rest
}: IPropsModal) => {
  const onSubmit = async () => {
    try {
      const response = await form?.validateFields();
      handleSubmit!(response);
    } catch (error) {
      console.log(error);
    }
  };
  const onClose = () => {
    form?.resetFields();
    handleClose!();
  };
  return (
    <Modal
      title={title}
      centered
      visible={isModalVisible}
      okText={okText}
      cancelText={cancelText}
      onCancel={onClose}
      onOk={onSubmit}
      width={width}
      closable={true}
      closeIcon={<CloseOutlined />}
      className={classNames(styles.modal, className, {
        [styles.centerTitle]: titleCenter,
        [styles.resetPadding]: resetPadding,
      })}
      {...rest}
    >
      {children}
    </Modal>
  );
};
