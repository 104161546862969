interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  classNameLabel?: string;
  isRequired?: boolean;
}

const LabelRequired = ({ label, classNameLabel, isRequired = true, ...rest }: IProps) => {
  return (
    <div style={{ display: 'flex', columnGap: '5px' }} {...rest}>
      {isRequired && <div className="text-error mt-2">*</div>}

      <div className={classNameLabel}>{label}</div>
    </div>
  );
};

export default LabelRequired;
