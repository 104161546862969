import { Checkbox, Col, Form, FormInstance, Radio, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CommonStatus, STATUS } from 'common';
import { BIG_INPUT_NUMBER, listGender, listWeekDisplay, MIN_PERCENT } from 'common/const';
import { ISubsidy, TModeMutateStaff } from 'common/interface';
import { CommonDatepicker } from 'components/CommonDatepicker';
import { CommonInputNumber } from 'components/CommonInputNumber';
import { CommonSelect } from 'components/CommonSelect';
import { validateMaxMinInput } from 'helper/validator';

import { InsuranceItem } from './InsuranceItem';
import { ListSubsidy } from './ListSubsidy';
import { useRulesForm } from 'hooks/useRulesForm';
import CommonRadioGroup from 'components/CommonRadioGroup';

import ItemFormSupervisionAdmin from 'components/ItemForm/ItemFormSupervisionAdmin';
import ItemFormViewRevenue from 'components/ItemForm/ItemFormViewRevenue';
import CheckBoxViewRevenue from 'components/CheckBox/CheckBoxViewRevenue';
import { IStaff } from 'api/staff';
import useCheckPermissionStaff from 'hooks/useCheckPermissionStaff';
import CheckboxPlaceWork from './components/CheckboxPlaceWork';

interface IProps {
  isInValid?: boolean;
  setIsInValid?: any;
  disable?: boolean;
  form?: FormInstance;
  otherSubsidy: ISubsidy[];
  listInsurance: any[];
  mode?: TModeMutateStaff;
  staff?: IStaff;
}
export const SalaryItemHour = ({
  isInValid,
  setIsInValid,
  disable = false,
  form,
  otherSubsidy,
  listInsurance,
  mode,
  staff,
}: IProps) => {
  const { rulesForm } = useRulesForm();
  const { isCanViewIsSupervisingAdmin, isCanEditIsSupervisingAdmin } = useCheckPermissionStaff();

  const handleCheck = (e: any, workHour: string) => {
    if (e.target.checked) {
      setIsInValid(false);
    } else {
      const formValues = { ...form?.getFieldsValue() };
      formValues.metadata.schedule[workHour] = null;
      form?.setFieldsValue(formValues);
    }
  };

  return (
    <>
      <Col span={12}>
        <Form.Item
          label="基本報酬額（時給）"
          name={['metadata', 'basicSalary']}
          rules={[
            {
              required: disable ? false : true,
              message: '全ての項目を入力してください。',
            },
            validateMaxMinInput(BIG_INPUT_NUMBER, 0),
          ]}
          labelAlign="left"
        >
          <CommonInputNumber disabled={disable} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="性別" name={['metadata', 'gender']} rules={[rulesForm().required]} labelAlign="left">
          <CommonSelect options={listGender} disabled={mode === 'confirm-invite'} />
        </Form.Item>
      </Col>
      {isCanViewIsSupervisingAdmin() && (
        <Col span={12}>
          <ItemFormSupervisionAdmin disable={!isCanEditIsSupervisingAdmin({ mode, staff })}>
            {(disabled) => {
              return (
                <Form.Item
                  label="アドミン権限 (全て表示)"
                  name={['metadata', 'isSupervisingAdmin']}
                  rules={[rulesForm().required]}
                >
                  <CommonRadioGroup disabled={disabled}>
                    <Radio value={CommonStatus.ACTIVE}>はい</Radio>
                    <Radio value={CommonStatus.INACTIVE}>いいえ</Radio>
                  </CommonRadioGroup>
                </Form.Item>
              );
            }}
          </ItemFormSupervisionAdmin>
        </Col>
      )}
      <Col span={12}>
        <ListSubsidy disabled={disable} otherSubsidy={otherSubsidy} />
      </Col>
      <Col span={12}>
        <Form.Item
          label="残業の給料増し（％）"
          name={['metadata', 'salaryOvertime']}
          rules={[
            {
              required: disable ? false : true,
              message: '全ての項目を入力してください。',
            },
            validateMaxMinInput(500, 0),
          ]}
          labelAlign="left"
        >
          <CommonInputNumber min={MIN_PERCENT} disabled={disable} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="みなし残業（一ヶ月）"
          name={['metadata', 'monthlyOvertimeHour']}
          rules={[
            {
              required: disable ? false : true,
              message: '全ての項目を入力してください。',
            },
            validateMaxMinInput(100, 0),
          ]}
          labelAlign="left"
        >
          <CommonInputNumber min={MIN_PERCENT} disabled={disable} />
        </Form.Item>

        <ItemFormViewRevenue disable={mode === 'confirm-invite'}>
          {(disabled) => (
            <Form.Item
              label="経営レポートを表示"
              name={['metadata', 'isDisableViewRevenue']}
              rules={[rulesForm().required]}
            >
              <CheckBoxViewRevenue disabled={disabled} />
            </Form.Item>
          )}
        </ItemFormViewRevenue>

        <CheckboxPlaceWork disable={disable} form={form} />
      </Col>
      {/* list working day */}
      <Col span={12}>
        {listWeekDisplay.map((el: any, index: number) => (
          <Row gutter={20} key={el.name}>
            <Col span={6}>
              <Form.Item
                valuePropName="checked"
                label={index === 0 ? '働く曜日' : false}
                name={['metadata', 'schedule', el.name]}
              >
                <Checkbox onChange={(e: any) => handleCheck(e, el.workHour)} disabled={disable}>
                  {el.label}
                </Checkbox>
              </Form.Item>
            </Col>

            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue, setFieldsValue }) => {
                const isChecked = getFieldValue(['metadata', 'schedule', el.name]);

                return (
                  <Col span={18}>
                    <Form.Item
                      label={index === 0 ? '労働時間/日' : false}
                      name={['metadata', 'schedule', el.workHour]}
                      rules={[
                        {
                          required: isChecked,
                          message: '全ての項目を入力してください。',
                        },
                      ]}
                    >
                      <CommonDatepicker
                        picker="time"
                        format={'HH:mm'}
                        disabled={!isChecked || disable}
                        allowClear={false}
                        suffixIcon={null}
                      />
                    </Form.Item>
                  </Col>
                );
              }}
            </Form.Item>
          </Row>
        ))}

        {isInValid ? <div className="color-red"> 全ての項目を入力してください。</div> : ''}
      </Col>
      {/* end list working day */}
      <Col span={6}>
        <Form.Item
          label="社員状態"
          name={['metadata', 'isProbation']}
          rules={[
            {
              required: disable ? false : true,
              message: '全ての項目を入力してください。',
            },
          ]}
        >
          <Radio.Group disabled={disable}>
            <Radio value={STATUS.INACTIVE}>正社員</Radio>
            <Radio value={STATUS.ACTIVE}>研修</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const isProbation = getFieldValue(['metadata', 'isProbation']);
            if (isProbation === STATUS.ACTIVE)
              return (
                <Form.Item
                  label="研修中給料率 (％)"
                  name={['metadata', 'probationSalary']}
                  rules={[
                    {
                      required: disable ? false : true,
                      message: '全ての項目を入力してください。',
                    },
                    validateMaxMinInput(500, 0),
                  ]}
                >
                  <CommonInputNumber disabled={disable} />
                </Form.Item>
              );

            if (isProbation === STATUS.INACTIVE) {
              return (
                <Form.Item
                  label="雇用年月日"
                  name={['metadata', 'officialDate']}
                  rules={[
                    {
                      required: disable ? false : true,
                      message: '全ての項目を入力してください。',
                    },
                  ]}
                >
                  <CommonDatepicker disabled={disable} />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
      </Col>

      <InsuranceItem disable={disable} listInsurance={listInsurance} />

      <Col span={24}>
        <Form.Item label="メモ" name={['metadata', 'description']}>
          <TextArea autoSize={{ minRows: 6, maxRows: 6 }} className={`inputGrey`} disabled={disable} />
        </Form.Item>
      </Col>
    </>
  );
};
