import { ModeType, UserRole } from 'common';
import { ICheckedTask, ICompanyActive, ITaskDetail } from 'common/interface';
import { getColorOfCalendar } from 'helper';

import useGetRoleInCompany from './useGetRoleInCompany';

export const configShowOneColumn = {
  widthModal: 900,
  spanCol1: 24,
  spanCol2: 0,
};

const configShowTwoColumn = {
  widthModal: 1200,
  spanCol1: 12,
  spanCol2: 12,
};

const useGetWidth = ({
  checked,
  mode,
  task,
  companySelected,
}: {
  checked: ICheckedTask;
  mode?: ModeType;
  task?: ITaskDetail;
  companySelected?: ICompanyActive;
}) => {
  const { isOnlyCustomerOfTask } = useGetRoleInCompany({
    mode: ModeType.VIEW,
    taskDetail: task,
  });

  const { isRedColor, bgGrayColor, isOrangeColor } = (task && getColorOfCalendar(task as any)) || {};

  /* ------------------------------ mode = CREATE ----------------------------- */
  if (
    [companySelected?.roleId === UserRole.CUSTOMER, Boolean(companySelected?.isCustomer)].some(Boolean) &&
    mode === ModeType.CREATE
  ) {
    return configShowOneColumn;
  }

  // roles view : CUSTOMER
  if (isOnlyCustomerOfTask) {
    const arrShowOneColumn = [mode === ModeType.CREATE, isRedColor, isOrangeColor];

    if (arrShowOneColumn.some((item) => Boolean(item))) {
      return configShowOneColumn;
    }

    return configShowTwoColumn;
  }

  // other roles view : ROLES_ADMIN, LEADER

  if (mode === ModeType.VIEW && isRedColor) {
    return configShowOneColumn;
  }

  if (isOrangeColor && !bgGrayColor) {
    return configShowOneColumn;
  }

  // mode = 'update' | 'create'
  const isCheckedShift = checked.morning || checked.afternoon || checked.evening || checked.other;

  return isCheckedShift ? configShowTwoColumn : configShowOneColumn;
};

export default useGetWidth;
