import { ICompanyProfile } from 'common/interface';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

export default function useProfileCompany() {
  const queryClient = useQueryClient();
  const profileCompany: any = queryClient.getQueryData('profileCompany');
  const refetchProfileCompany = useCallback(() => queryClient.invalidateQueries('profileCompany'), [queryClient]);

  const getNumberShiftWorking = () => {
    let number = 0;

    if (profileCompany?.data?.morningShift) {
      number += 1;
    }
    if (profileCompany?.data?.afternoonShift) {
      number += 1;
    }
    if (profileCompany?.data?.eveningShift) {
      number += 1;
    }
    if (profileCompany?.data?.otherShift) {
      number += 1;
    }

    return number;
  };

  return { profileCompany: profileCompany?.data as ICompanyProfile, refetchProfileCompany, getNumberShiftWorking };
}
