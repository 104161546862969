import images from 'assets/images';
import { CommonModal, IPropsModal } from 'components/CommonModal';
import ItemPlan from '../ItemPlan';
import { textJP } from 'common/const';
import Slider from 'react-slick';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';

import styles from './styles.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IPlan } from 'common/interface';
import { Empty } from 'antd';

interface IModalPlansProps extends IPropsModal {
  onSelectPlan?: (plan: IPlan) => void;
  selectedPlan?: IPlan | null;
  plans?: IPlan[];
}

const SLIDE_TO_SHOW = 3;

const ModalPlans = ({ plans, onSelectPlan, form, selectedPlan, ...rest }: IModalPlansProps) => {
  const numberPlans = plans?.length || 0;

  const settings = {
    dots: numberPlans > SLIDE_TO_SHOW,
    speed: 500,
    slidesToShow: SLIDE_TO_SHOW,
    infinite: false,
    // slidesToScroll: SLIDE_TO_SHOW,
    nextArrow: numberPlans > SLIDE_TO_SHOW ? <NextArrow /> : null,
    prevArrow: numberPlans > SLIDE_TO_SHOW ? <PrevArrow /> : null,
    appendDots: (dots: number) => <ul style={{ margin: '0px' }}> {dots} </ul>,
    customPaging: (i: number) => <div className={styles.dot}></div>,
    draggable: numberPlans > SLIDE_TO_SHOW,
  };

  return (
    <CommonModal {...rest} width={1100} resetPadding centered className={styles.modalPlans} footer={false}>
      <div className={styles.logoWrapper}>
        <img src={images.logo.withText} alt="" className={styles.logo} />
      </div>

      <div className={styles.wrapper}>
        <div style={{ position: 'relative', zIndex: 2, color: 'white' }} className={styles.title}>
          {textJP.choosePlan}
        </div>

        <div className={styles.listPlan}>
          {numberPlans > 0 ? (
            <Slider {...(settings as any)}>
              {plans?.map((plan) => (
                <ItemPlan
                  key={plan.id}
                  plan={plan}
                  onSelectPlan={onSelectPlan}
                  disableSelect={selectedPlan?.id === plan.id}
                />
              ))}
            </Slider>
          ) : (
            <Empty className="mt-120" />
          )}
        </div>
      </div>
    </CommonModal>
  );
};

export default ModalPlans;
