import { Input, InputProps } from 'antd';
import { TYPE_INPUT } from 'common';
import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { MAX_LENGTH } from 'common/const';

const { TextArea } = Input;

export interface IPropsInput extends InputProps {
  mode?: TYPE_INPUT.MULTIPLE;
  onChange?: (vals: any) => any;
  value?: any;
  className?: string;
  autoSize?: any;
}

export const CommonInput = ({
  mode,
  onChange,
  value,
  className,
  autoSize = { minRows: 5, maxRows: 5 },
  ...rest
}: IPropsInput) => {
  return (
    <>
      {!mode ? (
        <Input
          className={classNames(styles.input, className)}
          autoComplete="off"
          onChange={onChange}
          value={value}
          maxLength={MAX_LENGTH}
          {...rest}
        />
      ) : (
        // @ts-ignore
        <TextArea
          autoSize={autoSize}
          onChange={onChange}
          className={classNames(styles.input, className)}
          value={value}
          {...rest}
        />
      )}
    </>
  );
};
