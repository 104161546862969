import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import sysend from 'sysend';

const useSyncAccountMultiTabBrowser = () => {
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token');
    sysend.on('login', (newToken: string) => {
      if (newToken && newToken !== token) {
        setIsReload(true);
      }
    });

    return () => {
      sysend.off('login');
    };
  }, []);

  useEffect(() => {
    const handlerFocus = () => {
      if (isReload) {
        window.location.reload();
      }
    };

    window.addEventListener('focus', handlerFocus);

    return () => {
      window.removeEventListener('focus', handlerFocus);
    };
  }, [isReload]);

  return null;
};

export default useSyncAccountMultiTabBrowser;
