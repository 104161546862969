import React, { useState } from 'react';
import { createContext, useContext } from 'use-context-selector';

interface SideNavContextProps {
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideNavContext = createContext<SideNavContextProps | null>(null);

export const SideNavProvider = ({ children }: { children: React.ReactNode }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const value = { isCollapsed, setIsCollapsed };

  return <SideNavContext.Provider value={value}>{children}</SideNavContext.Provider>;
};

export const useSideNavContext = () => {
  const context = useContext(SideNavContext);

  if (!context) {
    throw new Error('useSideNav must be used within a SideNavProvider');
  }

  return context;
};
