import { Space, SpaceProps } from 'antd';
import classNames from 'classnames';
import { getGap } from 'helper';
import styles from './styles.module.scss';

export interface ICommonSpaceProps extends SpaceProps {
  justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
  children?: React.ReactNode;
  widthFull?: boolean;
  wrap?: boolean;
  gap?: number | [number, number];
}

const objJustify = {
  start: 'justify-start',
  end: 'justify-end',
  center: 'justify-center',
  'space-around': 'justify-space-around',
  'space-between': 'justify-space-between',
};

const CommonSpace = (props: ICommonSpaceProps) => {
  const { className, children, justify, widthFull, wrap, gap, style = {}, ...rest } = props;

  const justifyClass = justify ? objJustify?.[justify] : '';
  const widthFullClass = widthFull ? 'width-full' : '';
  const wrapClass = wrap ? 'flex-wrap' : '';

  return (
    <Space
      className={classNames(styles.default, justifyClass, wrapClass, widthFullClass, className)}
      style={{ ...style, rowGap: getGap(gap).rowGap, columnGap: getGap(gap).columnGap }}
      {...rest}
    >
      {children}
    </Space>
  );
};

export default CommonSpace;
