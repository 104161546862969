import { Form, Input, message, Space } from 'antd';
import { updateProfile } from 'api/profile';

import { CommonButton } from 'components/CommonButton';
import { CommonInput } from 'components/CommonInput';
import { CommonModal } from 'components/CommonModal';
import { handleErrorMessage } from 'helper';
import { passwordValidator } from 'helper/validator';
import useProfile from 'hooks/useProfile';
import { pick } from 'lodash';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import style from './styles.module.scss';

interface IProp {
  setShowChangeSuperAdminModal: any;
}

export default function ChangePassword({ setShowChangeSuperAdminModal }: IProp) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const profile = useProfile();
  const handleClose = () => {
    form.resetFields();
    setShowChangeSuperAdminModal(false);
  };

  useEffect(() => {
    form.setFieldsValue({ name: profile?.name });
  }, [profile]);

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);
      const params = pick(data, ['name', 'password']);

      await updateProfile(params);
      await queryClient.invalidateQueries('profile');
      handleClose();
      message.success('成功', 5);
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommonModal
      title=""
      centered
      visible={true}
      form={form}
      onCancel={handleClose}
      footer=""
      className={`${style.modalEmailVendor} ${style.pModal}`}
    >
      <div className={style.head}>
        <div className={style.name}>パスワードの変更</div>
      </div>
      <Form form={form} layout="vertical" onFinish={handleSubmit} scrollToFirstError={true}>
        <Form.Item
          label="氏名 "
          name="name"
          rules={[
            {
              required: true,
              message: '全ての項目を入力してください。',
            },
          ]}
          labelAlign="left"
        >
          <CommonInput />
        </Form.Item>

        <Form.Item label="新しいパスワード" name="password" rules={passwordValidator}>
          <Input.Password className={`inputGrey`} />
        </Form.Item>
        <Form.Item
          label="新しいパスワード（再入力）"
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: '全ての項目を入力してください。',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('パスワードと確認パスワードが間違っています。');
              },
            }),
          ]}
        >
          <Input.Password className={`inputGrey`} />
        </Form.Item>
        <div className={style.footer}>
          <Space size="middle">
            <Form.Item>
              <CommonButton title="キャンセル" className={style.btnWhite} onClick={handleClose} />
            </Form.Item>
            <Form.Item>
              <CommonButton title="保存 " type="primary" htmlType="submit" loading={loading} />
            </Form.Item>
          </Space>
        </div>
      </Form>
    </CommonModal>
  );
}
