import { create } from 'zustand';

interface ICreateConstructionSiteStore {
  showCreateConstructionSite: boolean;
  setShowCreateConstructionSite: (show: boolean) => void;
}

export const useCreateConstructionSite = create<ICreateConstructionSiteStore>((set) => ({
  showCreateConstructionSite: false,
  setShowCreateConstructionSite: (show) => set({ showCreateConstructionSite: show }),
}));
