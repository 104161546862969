import { titlesShift } from 'common/const';
import { ITaskDetail } from 'common/interface';
import styles from './styles.module.scss';
import { isNil } from 'lodash';
import { checkTaskSeparatedShift } from 'helper';

interface IProps {
  type: 'morningShift' | 'afternoonShift' | 'eveningShift' | 'otherShift';
  taskDetail?: ITaskDetail;
}

const CertificateCustomer = ({ type, taskDetail }: IProps) => {
  const isNewData = checkTaskSeparatedShift(taskDetail);

  const getMaxNumberWorker = (taskDetail: ITaskDetail) => {
    if (type === 'morningShift') {
      return isNewData ? taskDetail?.maxWorkerMorning : taskDetail?.maxWorker;
    }

    if (type === 'afternoonShift') {
      return taskDetail?.maxWorkerAfternoon;
    }

    if (type === 'eveningShift') {
      return taskDetail?.maxWorkerEvening;
    }

    if (type === 'otherShift') {
      return taskDetail?.maxWorkerOther;
    }
  };

  const isCheckedShift = (taskDetail: ITaskDetail) => {
    if (type === 'morningShift') {
      return taskDetail?.morningShiftCustomer;
    }

    if (type === 'afternoonShift') {
      return taskDetail?.afternoonShiftCustomer;
    }

    if (type === 'eveningShift') {
      return taskDetail?.eveningShiftCustomer;
    }

    if (type === 'otherShift') {
      return taskDetail?.otherShiftCustomer;
    }
  };

  const isHaveCertificates =
    taskDetail?.metadataCustomerUnitPriceCertificate?.[type] &&
    taskDetail?.metadataCustomerUnitPriceCertificate?.[type]?.length > 0;

  const isHaveMaxNumberWorker = !isNil(taskDetail && getMaxNumberWorker(taskDetail));
  const isHaveCheckedShift = isCheckedShift(taskDetail as any);

  return (
    <>
      {(isHaveCheckedShift || isHaveCertificates) && (
        <div className={styles.certificateCustomer}>
          {isHaveMaxNumberWorker && (
            <div className={styles.title}>
              {!isNewData && type === 'morningShift' ? titlesShift.allDay : titlesShift?.[type]}:{' '}
              {getMaxNumberWorker(taskDetail as any)}
            </div>
          )}

          {isHaveCertificates && (
            <div>
              {taskDetail?.metadataCustomerUnitPriceCertificate?.[type].map((certificate) => (
                <div key={certificate.id}>
                  {certificate.title} : {certificate.workers}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CertificateCustomer;
