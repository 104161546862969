import { getCountPaper } from 'api/paper';
import { STATUS } from 'common';
import { useQuery } from 'react-query';

export default function useBadgeCountPaper() {
  const paramCountPaper = {
    paymentStatus: STATUS.INACTIVE,
  };

  const { data: countPaper, refetch } = useQuery(['count-paper', paramCountPaper], () =>
    getCountPaper(paramCountPaper)
  );

  return { countPaper: countPaper?.data, refetchCountPaper: refetch };
}
