import { Form } from 'antd';
import { CommonStatus, UserRole } from 'common';
import { ROLES_ADMIN } from 'common/const';

interface IProps {
  disable?: boolean;
  children?: (disabled?: boolean) => React.ReactNode;
}

const ItemFormViewRevenue = ({ disable, children }: IProps) => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const roleId = getFieldValue('roleId');

        const isSupervisingAdmin = getFieldValue(['metadata', 'isSupervisingAdmin']);
        const isInviteAdmin = roleId === UserRole.ADMIN;
        const isFullPermission = isSupervisingAdmin === CommonStatus.ACTIVE;
        const disabled = disable || (isInviteAdmin && isFullPermission);

        const isCanView = ROLES_ADMIN.includes(+roleId);
        if (!isCanView) return null;

        return children?.(disabled);
      }}
    </Form.Item>
  );
};

export default ItemFormViewRevenue;
