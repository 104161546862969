import React, { useState } from 'react';
import { Checkbox, message, Spin, UploadProps } from 'antd';
import { uploadMultiple } from 'api/upload';
import icUpload from 'assets/icons/upload.svg';
import icRemove from 'assets/icons/x.svg';
import { ModeType } from 'common';
import { handleErrorMessage } from 'helper';
import icons from 'assets/icons';
import { CommonIconButton } from 'components/CommonIconButton';
import { downloadFileFromUrl, downloadFilesFromUrl } from 'helper/file';
import styles from './styles.module.scss';

interface IProps extends UploadProps {
  value?: any[];
  onChange?: (file: any) => void;
  maxFile?: number;
  mode?: string;
  allowDownload?: boolean;
  zipFilename?: string;
  allowDropFile?: boolean; // allow drag and drop file
}

const MAX_FILE = 10;

export const UploadListFile = ({
  value = [],
  onChange,
  maxFile = MAX_FILE,
  mode,
  disabled,
  allowDownload,
  zipFilename,
  allowDropFile = true,
  ...rest
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  // state for drag and drop files
  const [dragOver, setIDragOver] = useState(false);

  // state for zip file
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isDownload, setIsDownload] = useState(false);
  const isShowListFile = value?.length > 0;

  // handle upload files
  const handleUpload = async (e: any) => {
    setLoading(true);
    try {
      const files = e?.target?.files || e?.dataTransfer?.files; // Support drag and drop

      if (files?.length + value?.length <= maxFile) {
        let newListFile: any[] = [];
        const { data, domain } = await uploadMultiple(files);
        newListFile = [
          ...data.map((el: any, i: number) => ({
            id: Math.random(),
            name: files?.[i]?.name ?? el,
            url: `${domain}/${el}`,
          })),
        ];
        onChange!([...value, ...newListFile]);
      } else {
        message.destroy();
        message.error(`アップロードするファイルは${maxFile}枚以下になります。`, 5);
      }
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const onRemove = (file: any, index: number) => {
    const newValue = [...value.filter((x) => x.id !== file?.id)];
    onChange!(newValue);

    if (inputRef.current) {
      inputRef.current.value = '';
    }

    if (allowDownload) {
      setSelectedIds((ids) => ids.filter((x) => x !== file.id));
    }
  };

  const handleDownload = async () => {
    if (!selectedIds.length) return;

    const selectedFiles = value
      .filter((file) => selectedIds.includes(file.id))
      .map((file) => ({ url: file.url, fileName: file.name }));

    try {
      setIsDownload(true);

      if (selectedFiles.length === 1) {
        await downloadFileFromUrl({ url: selectedFiles[0].url, fileName: zipFilename as string });
        return;
      }

      await downloadFilesFromUrl({ files: selectedFiles, zipFilename: zipFilename as string });
    } catch (error) {
    } finally {
      setIsDownload(false);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    if (!allowDropFile) return;

    e.preventDefault();
    setIDragOver(true);
  };

  const handleDragLeave = () => {
    if (!allowDropFile) return;

    setIDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (!allowDropFile) return;

    e.preventDefault();
    handleUpload(e);
    setIDragOver(false);
  };

  return (
    <div>
      <div className={styles.boxUpload} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
        <div className={styles.picker}>
          <label className={styles.wrapperInput}>
            <input
              ref={inputRef}
              id="fileUpload"
              style={{ display: 'none' }}
              type="file"
              accept="application/pdf,image/*"
              multiple
              onChange={handleUpload}
              disabled={disabled}
              onClick={(e) => ((e.target as any).value = null)} // clear value when click
              {...rest}
            />
            <img src={icUpload} className={styles.boxIcon} alt="" />
          </label>
        </div>

        {dragOver && (
          <div className={styles.dragMessage}>
            <span className={styles.dragMessageItem}>ここにファイルをドロップします。</span>
          </div>
        )}
      </div>

      <div className={styles.fileList}>
        {loading && <Spin />}

        {isShowListFile &&
          [...value]?.map((el: any, index) => (
            <div key={el?.id} className={styles.fileItem}>
              {allowDownload && (
                <Checkbox
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      setSelectedIds([...selectedIds, el?.id]);
                    } else {
                      setSelectedIds((ids) => ids.filter((x) => x !== el?.id));
                    }
                  }}
                  checked={selectedIds.includes(el?.id)}
                />
              )}

              <a target="_blank" href={el?.url} rel="noreferrer">
                {el.name}
              </a>
              {mode !== ModeType.VIEW && !disabled && (
                <img src={icRemove} className={styles.icon} onClick={() => onRemove(el, index)} alt="" />
              )}
            </div>
          ))}

        {[allowDownload, isShowListFile].every(Boolean) && (
          <CommonIconButton
            icon={icons.download.gray}
            size="small"
            onClick={handleDownload}
            disabled={isDownload || selectedIds.length === 0}
            loading={isDownload}
          />
        )}
      </div>
    </div>
  );
};
