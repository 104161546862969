import { Col, Form, Row } from 'antd';
import { getListInsurance } from 'api/insurance';
import { getCompanySubsidy } from 'api/profile';
import { CommonStatus, HolidayTargetType, SalaryType, UserRole } from 'common';
import { BIGGER_WIDTH_MODAL, BIG_WIDTH_MODAL, listRolesStaff, listSalaryType } from 'common/const';
import { ISubsidy } from 'common/interface';
import { CommonButton } from 'components/CommonButton';
import { CommonInput } from 'components/CommonInput';
import { CommonModal } from 'components/CommonModal';
import { CommonSelect } from 'components/CommonSelect';
import { convertMonthTaxToForm, getTimeFromHourMinutes, handleChangeIsSupervisingAdmin } from 'helper';
import useProfileCompany from 'hooks/useProfileCompany';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { OtherWorkerFormItem } from './OtherWorkerFormItems';
import { SalaryItemHour } from './SalaryItemHour';
import { SalaryItemMonth } from './SalaryItemMonth';
import { SalaryItemShift } from './SalaryItemShift';
import style from './styles.module.scss';
import { isNil } from 'lodash';
interface IProps {
  showAddStaff: boolean;
  handleClose: () => void;
  currentInviteStaff?: any;
}

export const FormConfirmInviteStaff = ({ showAddStaff, handleClose, currentInviteStaff }: IProps) => {
  const [form] = Form.useForm();
  const { profileCompany } = useProfileCompany();

  const { data: listInsurance } = useQuery(
    'Insurance',
    () =>
      getListInsurance().then((res) =>
        res?.data?.map((el: any) => ({
          index: el?.index,
          range: `${el?.from || 0} - ${el?.to || '~'}`,
        }))
      ),
    {
      enabled: showAddStaff,
    }
  );

  const { data: detailSubsidy, isLoading } = useQuery('subsidy-company', () => getCompanySubsidy(), {
    enabled: showAddStaff,
  });
  const detailSubsidyCompany: ISubsidy[] = detailSubsidy?.data?.otherSubsidy;

  // set default value to form
  useEffect(() => {
    let newInviteStaff = {};
    if (currentInviteStaff?.metadata) {
      const data = JSON.parse(currentInviteStaff?.metadata);

      const schedule = {
        ...data.schedule,
        mondayHour: getTimeFromHourMinutes(data?.schedule?.mondayHour, data?.schedule?.mondayMinute),
        tuesdayHour: getTimeFromHourMinutes(data?.schedule?.tuesdayHour, data?.schedule?.tuesdayMinute),
        wednesdayHour: getTimeFromHourMinutes(data?.schedule?.wednesdayHour, data?.schedule?.wednesdayMinute),
        thursdayHour: getTimeFromHourMinutes(data?.schedule?.thursdayHour, data?.schedule?.thursdayMinute),
        fridayHour: getTimeFromHourMinutes(data?.schedule?.fridayHour, data?.schedule?.fridayMinute),
        saturdayHour: getTimeFromHourMinutes(data?.schedule?.saturdayHour, data?.schedule?.saturdayMinute),
        sundayHour: getTimeFromHourMinutes(data?.schedule?.sundayHour, data?.schedule?.sundayMinute),
      };

      newInviteStaff = {
        ...currentInviteStaff,
        metadata: {
          ...data,
          ...convertMonthTaxToForm(data?.residenceTax),
          isInsurrance: data?.isInsurrance ? 1 : 0,
          isSimpleMonth: CommonStatus.INACTIVE,
          isHealthInsurrance: !data?.indexHealthInsurance,
          isPensionInsurrance: !data?.indexPensionInsurance,
          schedule,
          registrationNumber: data?.registrationNumber?.replace('T', '') || null,
        },
      };
    }

    form?.setFieldsValue(newInviteStaff);
  }, []);

  const onClose = () => {
    form.resetFields();
    handleClose();
  };

  return (
    <>
      <CommonModal
        title=""
        centered
        isModalVisible={showAddStaff}
        handleClose={onClose}
        form={form}
        footer=""
        className={style.pModal}
        width={BIGGER_WIDTH_MODAL}
      >
        <div className={style.head}>
          <div className={style.name}>新規人員登録</div>
        </div>
        <Form
          form={form}
          layout="vertical"
          onValuesChange={(changedValues) => {
            handleChangeIsSupervisingAdmin(form, changedValues);
          }}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="メール" name="email">
                <CommonInput disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="アカウントタイプ" name={'roleId'} labelAlign="left">
                <CommonSelect options={listRolesStaff} disabled />
              </Form.Item>
            </Col>

            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                if (!getFieldValue('roleId')) return null;
                if (getFieldValue('roleId') === UserRole.VENDOR_WORKER)
                  return (
                    <Col span={12}>
                      <Form.Item label="サプライヤー" name={['metadata', 'managerName']} labelAlign="left">
                        <CommonInput disabled />
                      </Form.Item>
                    </Col>
                  );

                if (getFieldValue('roleId') === UserRole.OTHER_WORKER) return <OtherWorkerFormItem disable={true} />;

                return (
                  <>
                    <Col span={12}>
                      <Form.Item label="報酬の形態" name={['metadata', 'salaryType']} labelAlign="left">
                        <CommonSelect disabled options={listSalaryType} />
                      </Form.Item>
                    </Col>

                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const salaryType = getFieldValue(['metadata', 'salaryType']);
                        const roleId = getFieldValue('roleId');
                        const otherSubsidy = detailSubsidyCompany?.filter(
                          (el: ISubsidy) =>
                            el?.targetType === HolidayTargetType.ALL ||
                            (el?.targetType === HolidayTargetType.ROLE && el?.targetId === roleId)
                        );

                        if (!salaryType) return null;
                        if (salaryType === SalaryType.SHIFT_SALARY)
                          return (
                            <SalaryItemShift
                              profileCompany={profileCompany}
                              disable={true}
                              otherSubsidy={otherSubsidy}
                              listInsurance={listInsurance || []}
                              mode="confirm-invite"
                            />
                          );
                        if (salaryType === SalaryType.HOUR_SALARY)
                          return (
                            <SalaryItemHour
                              disable={true}
                              otherSubsidy={otherSubsidy}
                              listInsurance={listInsurance || []}
                              mode="confirm-invite"
                            />
                          );
                        if (salaryType === SalaryType.MONTH_SALARY)
                          return (
                            <SalaryItemMonth
                              disable={true}
                              otherSubsidy={otherSubsidy}
                              listInsurance={listInsurance || []}
                              mode="confirm-invite"
                            />
                          );
                      }}
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>

            <Col span={24}>
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item>
                    <div className={style.btnAccept}>
                      <CommonButton
                        onClick={onClose}
                        title="保存 "
                        type="primary"
                        className={`${style.btn} ${style.btnPrimary}`}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};
