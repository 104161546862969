import { Col, Form, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { listRolesStaff } from 'common/const';
import { BannerRightHeader } from 'components/Banner';
import { CommonButton } from 'components/CommonButton';
import { CommonModal } from 'components/CommonModal';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';
const { Title } = Typography;

interface IProps {
  roleId?: number;
  companyId?: number;
  responseToken?: any;
}

export default function ConfirmLogout({ companyId, responseToken, roleId }: IProps) {
  const [form] = useForm();
  const history = useHistory();
  const queryClient = useQueryClient();
  const requestStaff = queryClient.getQueryData('inviteStaffToken') as any;

  const currentStaffRole = requestStaff?.data?.roleId;
  const [open, setOpen] = useState(true);

  const isStaff = listRolesStaff.find((el: any) => el.id === currentStaffRole);
  const handleLogout = () => {
    Cookies.remove('companyId');
    Cookies.remove('roleId');
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    queryClient.removeQueries('profile');
    setOpen(false);
    if (isStaff) history.push('/sign-up-staff');
    else history.push('/sign-up');
  };

  const handleConfirm = () => {
    Cookies.set('companyId', companyId + '');
    Cookies.set('roleId', roleId + '');
    Cookies.set('token', responseToken.token);
    Cookies.set('refreshToken', responseToken.refreshToken);
    queryClient.refetchQueries('profile');
    setOpen(false);
  };

  const handleSubmit = () => {
    if (responseToken) handleConfirm();
    else handleLogout();
  };

  return (
    <>
      <CommonModal
        maskClosable={false}
        isModalVisible={open}
        handleClose={() => setOpen(false)}
        title=""
        footer={null}
        width={700}
      >
        <div className={styles.header}>
          <BannerRightHeader />
          <Title level={5} className={styles.formContent}>
            このアカウントをログアウトしますか？
          </Title>
          <Form form={form} layout="vertical" onFinish={handleSubmit} scrollToFirstError={true}>
            <Row justify="space-between" gutter={20}>
              <Col span={12}>
                <Form.Item>
                  <CommonButton
                    title="戻る "
                    type="default"
                    onClick={() => setOpen(false)}
                    className={styles.btnWhite}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <CommonButton title="ログアウト" type="primary" htmlType="submit" className={styles.btnSubmit} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </CommonModal>
    </>
  );
}
