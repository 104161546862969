import { AutoComplete, AutoCompleteProps } from 'antd';
import classNames from 'classnames';
import styles from './styles.module.scss';

const { Option } = AutoComplete;

interface IPropsInput extends AutoCompleteProps {
  onChange?: (vals: any) => any;
  className?: string;
  optionName: string;
  options: any[];
  defaultValue?: string;
}

export default function CommonAutoComplete({
  onChange,
  options = [],
  optionName,
  onSelect,
  onSearch,
  className,
  defaultValue,
  ...rest
}: IPropsInput) {
  return (
    <AutoComplete
      className={classNames(styles.input, className)}
      onSelect={onSelect}
      onSearch={onSearch}
      onChange={onChange}
      defaultValue={defaultValue}
      {...rest}
    >
      {options?.map((items) => (
        <Option key={items[optionName]} value={items[optionName]}>
          {items[optionName]}
        </Option>
      ))}
    </AutoComplete>
  );
}
