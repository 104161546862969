import { ROLES_ADMIN } from 'common/const';
import React, { MouseEvent } from 'react';
import { CommonModal } from 'components/CommonModal';
import useToggleModal from 'hooks/useToggleModal';
import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ITask } from 'common/interface';
import styles from './styles.module.scss';
import classNames from 'classnames';
import useUpdateNote from 'hooks/apis/useUpdateNote';
import { isNil } from 'lodash';

interface IProps {
  event: ITask;
  componentTrigger?: React.ReactNode;
}

type IForm = { note: ITask['note'] };

const NoteTask = ({ event, componentTrigger }: IProps) => {
  const { isOpen, toggleModal } = useToggleModal();
  const { updateNoteMutation } = useUpdateNote();
  const [form] = Form.useForm<IForm>();

  const isCanEditNoteFast = ROLES_ADMIN.includes(event?.taskRoleId);

  const handleClickNote = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    // prevent parent event trigger
    e.stopPropagation();

    if (!isCanEditNoteFast) return;

    form.setFieldsValue({ note: event?.note ?? '' });
    toggleModal(true);
  };

  const handleCancel = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    // prevent parent event trigger
    e.stopPropagation();

    toggleModal(false);
  };

  // handle update note
  const handleSubmit = (values: IForm) => {
    updateNoteMutation.mutate(
      {
        companyId: event?.companyId,
        taskId: event?.id,
        note: values?.note as string,
      },
      {
        onSuccess(data, variables, context) {
          toggleModal(false);
        },
      }
    );
  };

  return (
    <>
      {/* Do not remove class .note-task. This call use to detect if click in side note-task */}
      {!isNil(componentTrigger) ? (
        <div onClick={handleClickNote} className="note-task">
          {componentTrigger}
        </div>
      ) : (
        <div className={classNames(styles.noteTask, 'note-task')} onClick={handleClickNote}>
          <div>※</div>
          <div className="line-2">: {event?.note}</div>
        </div>
      )}

      <CommonModal
        title="手配のメモ"
        titleCenter
        visible={isOpen}
        onCancel={handleCancel}
        wrapProps={{ onClick: (e: any) => e.stopPropagation() }}
        width={700}
        onOk={() => form.submit()}
        confirmLoading={updateNoteMutation.isLoading}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="note" initialValue={event?.note}>
            <TextArea className="inputGrey" autoSize={{ minRows: 8 }} />
          </Form.Item>
        </Form>
      </CommonModal>
    </>
  );
};

export default NoteTask;
