import { ITask } from 'common/interface';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface IListDeleteTaskStore {
  // isModeDelete
  isModeDelete: boolean;
  setIsModeDelete: (isModeDelete: boolean) => void;

  // list selected task to delete
  selectedDeleteTasks: ITask[];
  addSingleSelectedDeleteTask: (task: ITask) => void;
  removeSelectedDeleteTask: (taskId: number) => void;
  removeMultiSelectedDeleteTask: (taskIds: number[]) => void;
  removeAllSelectedDeleteTask: () => void;
  resetSelectedDeleteTask: () => void;
}

export const useListDeleteTaskStore = create(
  immer<IListDeleteTaskStore>((set, get) => ({
    /* ------------------------------- Mode delete ------------------------------ */
    isModeDelete: false,
    setIsModeDelete: (isModeDelete: boolean) => {
      set((state) => {
        state.isModeDelete = isModeDelete;
      });
    },

    /* ------------------------------- List selected task to delete ------------------------------ */
    selectedDeleteTasks: [],
    addSingleSelectedDeleteTask: (task) => {
      set((state) => {
        state.selectedDeleteTasks.push(task);
      });
    },
    removeSelectedDeleteTask: (taskId: number) => {
      set((state) => {
        state.selectedDeleteTasks = state.selectedDeleteTasks.filter((task) => task.id !== taskId);
      });
    },
    removeMultiSelectedDeleteTask: (taskIds: number[]) => {
      set((state) => {
        state.selectedDeleteTasks = state.selectedDeleteTasks.filter((task) => !taskIds.includes(task.id));
      });
    },
    removeAllSelectedDeleteTask: () => {
      set((state) => {
        state.selectedDeleteTasks = [];
      });
    },
    resetSelectedDeleteTask: () => {
      set((state) => {
        state.selectedDeleteTasks = [];
        state.isModeDelete = false;
      });
    },
  }))
);
