import { IMetadataCustomerUpdate, ITaskDetail } from 'common/interface';
import styles from './styles.module.scss';
import { titlesShift } from 'common/const';
import { isNil } from 'lodash';
import { checkTaskSeparatedShift } from 'helper';

interface IProps {
  taskDetail?: ITaskDetail;
}

const MetaDataCertificateCustomer = ({ taskDetail }: IProps) => {
  const metadataCustomerUpdate = taskDetail?.metadataCustomerUpdate
    ? (JSON.parse(taskDetail?.metadataCustomerUpdate) as IMetadataCustomerUpdate)
    : null;

  const isShowMaxWorker = () => {
    const isHaveCertificatesUpdate =
      metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.morningShift &&
      metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.morningShift?.length > 0;

    if (isHaveCertificatesUpdate) return true;

    return !isNil(metadataCustomerUpdate?.maxWorker) && taskDetail?.maxWorker !== metadataCustomerUpdate?.maxWorker;
  };

  const isShowMaxWorkerMorning = () => {
    const isHaveCertificatesUpdate =
      metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.morningShift &&
      metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.morningShift?.length > 0;

    if (isHaveCertificatesUpdate) return true;

    return (
      !isNil(metadataCustomerUpdate?.maxWorkerMorning) &&
      taskDetail?.maxWorkerMorning !== metadataCustomerUpdate?.maxWorkerMorning
    );
  };

  const isShowMaxWorkerAfternoon = () => {
    const isHaveCertificatesUpdate =
      metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.afternoonShift &&
      metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.afternoonShift?.length > 0;

    if (isHaveCertificatesUpdate) return true;

    return (
      !isNil(metadataCustomerUpdate?.maxWorkerAfternoon) &&
      taskDetail?.maxWorkerAfternoon !== metadataCustomerUpdate?.maxWorkerAfternoon
    );
  };

  const isShowMaxWorkerEvening = () => {
    const isHaveCertificatesUpdate =
      metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.eveningShift &&
      metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.eveningShift?.length > 0;

    if (isHaveCertificatesUpdate) return true;

    return (
      !isNil(metadataCustomerUpdate?.maxWorkerEvening) &&
      taskDetail?.maxWorkerEvening !== metadataCustomerUpdate?.maxWorkerEvening
    );
  };

  const isShowMaxWorkerOther = () => {
    const isHaveCertificatesUpdate =
      metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.otherShift &&
      metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.otherShift?.length > 0;

    if (isHaveCertificatesUpdate) return true;

    return (
      !isNil(metadataCustomerUpdate?.maxWorkerOther) &&
      taskDetail?.maxWorkerOther !== metadataCustomerUpdate?.maxWorkerOther
    );
  };

  const isNewData = checkTaskSeparatedShift(metadataCustomerUpdate as any);

  if (!metadataCustomerUpdate) return null;

  return (
    <div className={styles.metaDataCertificateCustomer}>
      {/*MetaData Certificate Morning */}
      {!isNewData && (
        <div className={styles.item}>
          <div className={styles.title}>
            {isShowMaxWorker() && (
              <>
                {titlesShift?.allDay} : {metadataCustomerUpdate?.maxWorker}
              </>
            )}
          </div>
          <div className={styles.certificates}>
            {metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.morningShift?.map((certificate) => {
              return (
                <div key={certificate?.id}>
                  {certificate?.title} : {certificate?.workers}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {isNewData && (
        <>
          <div className={styles.item}>
            <div className={styles.title}>
              {isShowMaxWorkerMorning() && (
                <>
                  {titlesShift.morningShift} : {metadataCustomerUpdate?.maxWorkerMorning}
                </>
              )}
            </div>
            <div className={styles.certificates}>
              {metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.morningShift?.map((certificate) => {
                return (
                  <div key={certificate?.id}>
                    {certificate?.title} : {certificate?.workers}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.title}>
              {isShowMaxWorkerAfternoon() && (
                <>
                  {titlesShift.afternoonShift} : {metadataCustomerUpdate?.maxWorkerAfternoon}
                </>
              )}
            </div>
            <div className={styles.certificates}>
              {metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.afternoonShift?.map((certificate) => {
                return (
                  <div key={certificate?.id}>
                    {certificate?.title} : {certificate?.workers}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      {/*MetaData Certificate Evening */}
      <div className={styles.item}>
        <div className={styles.title}>
          {isShowMaxWorkerEvening() && (
            <>
              {titlesShift.eveningShift} : {metadataCustomerUpdate?.maxWorkerEvening}
            </>
          )}
        </div>
        <div className={styles.certificates}>
          {metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.eveningShift?.map((certificate) => {
            return (
              <div key={certificate?.id}>
                {certificate?.title} : {certificate?.workers}
              </div>
            );
          })}
        </div>
      </div>

      {/*MetaData Certificate Other */}
      <div className={styles.item}>
        <div className={styles.title}>
          {isShowMaxWorkerOther() && (
            <>
              {titlesShift.otherShift} : {metadataCustomerUpdate?.maxWorkerOther}
            </>
          )}
        </div>
        <div className={styles.certificates}>
          {metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.otherShift?.map((certificate) => {
            return (
              <div key={certificate?.id}>
                {certificate?.title} : {certificate?.workers}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MetaDataCertificateCustomer;
