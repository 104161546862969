import { CommonStatus, UserRole } from 'common';
import { emptyData } from 'common/const';

const ROLES_CAN_USE_LINK = [
  UserRole.ADMIN,
  UserRole.OFFICE_MANAGER,
  UserRole.MANAGER,
  UserRole.CUSTOMER,
  UserRole.VENDOR,
];

interface IProps {
  currentRoleInCompany: UserRole;
  constructionSiteId: number;
  constructionSiteName?: string;
  isAutoCreateTask?: CommonStatus;
}

const ProjectNameLink = ({
  currentRoleInCompany,
  constructionSiteId,
  constructionSiteName,
  isAutoCreateTask,
}: IProps) => {
  const name = constructionSiteName || emptyData;

  return (
    <>
      {ROLES_CAN_USE_LINK.includes(currentRoleInCompany) ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={`/works/information?constructionSiteId=${constructionSiteId}&isAutoCreateTask=${
            isAutoCreateTask || CommonStatus.INACTIVE
          }`}
        >
          {name}
        </a>
      ) : (
        name
      )}
    </>
  );
};

export default ProjectNameLink;
