import useProfile from 'hooks/useProfile';
import React from 'react';
import { Redirect } from 'react-router-dom';

const SuperAdminRoutes = ({ children }: { children: React.ReactNode }) => {
  const profile = useProfile();
  const isSuperAdmin = !!profile?.isSuperAdmin;

  if (!isSuperAdmin) return <Redirect to="/" />;

  return <>{children}</>;
};

export default SuperAdminRoutes;
