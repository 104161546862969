import { IMetadataCustomerUpdate, ITaskDetail } from 'common/interface';
import { renderListDayOffs } from 'helper';
import { clone } from 'lodash';

interface IProps {
  taskDetail?: ITaskDetail;
  metadataCustomerUpdate?: IMetadataCustomerUpdate;
}

const MetadataListDayOffs = ({ taskDetail, metadataCustomerUpdate }: IProps) => {
  if (!metadataCustomerUpdate) return null;

  const metadataDayOffsCustomerUpdate = metadataCustomerUpdate?.metadataDayOffs
    ? (JSON.parse(metadataCustomerUpdate.metadataDayOffs) as string[])
    : [];
  const metadataDayOffs: string[] = taskDetail?.metadataDayOffs ? JSON.parse(taskDetail.metadataDayOffs) : [];

  if (metadataDayOffsCustomerUpdate.length === 0) return null;

  // check if metadata different
  const isDifferent = String(clone(metadataDayOffs).sort()) !== String(clone(metadataDayOffsCustomerUpdate).sort());

  if (!isDifferent) return null;

  return <div className="text-green">{renderListDayOffs(metadataDayOffsCustomerUpdate)}</div>;
};

export default MetadataListDayOffs;
