import {
  IUnemploymentEmployee,
  TResIncomeTaxBonusConfig,
  TResUnemploymentEmployee,
  TRestHealthInsuranceRate,
} from 'common/interface';
import { sendGet, sendPost, sendPut } from './axios';

export const getListInsurance = () => sendGet('/v2/insurrance/list');
export const importExcel = (params: any) => sendPost('/v2/insurrance/import-excel', params);
export const viewDetailInsurance = (id: number, params: any) => sendPut(`/v2/insurance/${id}`, params);
export const getInsuranceText = () => sendGet('/v2/insurrance/text');
export const updateInsuranceText = (params: any) => sendPut('/v2/insurrance/text', params);
export const getListUnemploymentInsurance = () => sendGet('/v2/insurrance/get-list-unempoyment-insurrance');
export const updateUnemploymentInsurance = (params: any) => sendPut('/v2/insurrance/unempoyment-insurrance', params);
export const updateInsuranceItem = (id: number, params: any) => sendPut(`/v2/insurrance/${id}`, params);

export const getIncomeTaxBonusConfig = () =>
  sendGet<TResIncomeTaxBonusConfig>('/nest/common-config/get-income-tax-bonus-config');
export const importTaxBonus = (csv: string) => sendPost('/nest/common-config/set-income-tax-bonus-config', { csv });
export const downloadFileTaxBonus = () => sendPost<string>('/nest/common-config/income-tax-csv-from-config');

export const getHealthInsuranceBonusConfig = () =>
  sendGet<TRestHealthInsuranceRate>('/nest/common-config/get-health-insurance-bonus-config');
export const importHealthInsuranceBonus = (csv: string) =>
  sendPost('/nest/common-config/set-health-insurance-bonus-config', { csv });
export const downloadFileHealthInsuranceBonus = () =>
  sendPost<string>('/nest/common-config/health-insurance-csv-from-config');

export const getUnemploymentEmployeeConfig = () =>
  sendGet<TResUnemploymentEmployee>('/nest/common-config/get-unemployment-employee-config');
export const setUnemploymentEmployeeConfig = (payload: IUnemploymentEmployee) =>
  sendPost('/nest/common-config/set-unemployment-employee-config', payload);
