import { monthPaymentName } from 'common/const';
import { axiosInstance, sendGet, sendPost, sendPut } from './axios';
import type { IFilter, IManager, IUserCompany, TResListStaff } from 'common/interface';
import type { IPayLoadInviteStaff } from 'types/staff.type';

export const getStaff = async ({ filters, companyId }: { filters?: Partial<IFilter>; companyId?: number }) => {
  const response = await axiosInstance.post<TResListStaff>('/staff/list-staff', filters, { headers: { companyId } });

  return response?.data;
};

export const getDetailStaff = (id: number) => sendGet(`/staff/detail-staff/${id}`);
export const createStaff = (payload: Partial<IFilter>) => sendPost('/staff/create-staff', payload);
export const updateStaff = (id: number, payload: Partial<IStaff>) => sendPut(`/staff/update-staff/${id}`, payload);
export const getStaffConstructorSite = (id: number, filter: Partial<IFilter>) =>
  sendPost(`/staff/construction-site/${id}`, filter);
export const changePassword = (id: number, payload: any) => sendPut(`/staff/update-password/${id}`, payload);
export const getListPaymentRequest = (id: number, filter: Partial<IFilter>) =>
  sendPost(`/staff/${id}/list-payment-request`, filter);
export const inviteStaff = (payload: IPayLoadInviteStaff) => sendPost('/staff/invite-staff', payload);
export const getDetailRequestStaff = (payload: any) => sendPost('/staff/detail-invite-request', payload);
export const staffJoinCompany = (payload: any) => sendPost('/staff/staff-register', payload);
export const adminUpdateStaff = (id: number, payload: any) => sendPut(`/staff/admin-update-staff/${id}`, payload);

export const listInviteUser = (filter: Partial<IFilter>) => sendPost('/staff/list-invite-request', filter);
export const approvedRequest = (id: number, payload: any) => sendPost(`/staff/${id}/approve-payment-request`, payload);

export interface IStaff {
  id: number;
  status: number;
  isSuperAdmin: boolean;
  gender: number;
  email: string;
  password: string;
  phone: string;
  name: string;
  furiganaName: string;
  position: string;
  description: string;
  birthday: string;
  joinDate: string;
  address: string;
  avatar: string;
  job: string;
  yearExperience: string;
  createdBy: number;
  updateAt: string;
  createdAt: string;
  bankInfo: IBankInfo;
  userCompany: IUserCompany;
  company: ICompany & { [key: string]: any };
  manager: null | IManager;
  heal: IHeal;
  emergencyAddress: IEmergencyAddress;
  license: Array<ILicense>;
  certification: ICertification;
  [monthPaymentName]?: number;
  paymentDate?: number;
  [key: string]: any;
}

interface ILicense {
  id: number;
  type: number;
  objectId: number;
  objectType: number;
  url: string;
  name: string;
  status: number;
  createdBy: string;
}

interface IHeal {
  userId: number;
  bloodGroup: number;
  diseaseId: number;
  lastHealCheckDate: string;
  specificHealCheckDate: string;
  minBloodPressure: number;
  maxBloodPressure: number;
  healthInsuranceId: number;
  pensionInsuranceId: number;
  employmentInsuranceId: number;
  employmentInsuranceNumber: string;
  createAt: string;
}

interface ICertification {
  specialEducation: string;
  skillTraining: string;
  license: string;
}

interface IBankInfo {
  userId: number;
  bankId: number;
  branch: string;
  cardNumber: string;
  ownerName: string;
  type: number;
  status: number;
  bankName: string;
}

interface IEmergencyAddress {
  userId: number;
  name: string;
  address: string;
  relation: string;
  phone: string;
}

interface ICompany {
  id: number;
  adminId: number;
  status: number;
  type: number;
  postalCode: string;
  avatar: string;
  name: string;
  establishedAt: string;
  charterCapital: number;
  address: string;
  email: string;
  representativeName: string;
  representativeEmail: string;
  description: string;
  phone: string;
  faxNumber: string;
  workTimeFrom: string;
  workTimeTo: string;
  hourSalary: number;
  daySalary: number;
  monthSalary: number;
  breakTimeFrom: string;
  breakTimeTo: string;
  maxHourOverTime: string;
  subscriptionExpireAt: string;
  salaryClosingDate: string | number;
  acceptOverTimeFrom: string;
  dateRegisterSchedule: string;
  updateAt: string;
  createdAt: string;
}
