import { UserRole } from 'common';
import { isVendorCustomer } from 'helper';
import useProfile from './useProfile';
import useListCompanyActive from './apis/useListCompanyActive';
import { ROLES_ADMIN, ROLES_CAN_ADD_WORKER_REPORT } from 'common/const';
import { IComment } from 'common/interface';

const useCheckPermissionCalendar = () => {
  const profile = useProfile();
  const { listCompanyActive, rolesInCompanyActive, isVendorCustomerInCompanyActive } = useListCompanyActive();

  const checkCanViewWorkerReport = (viewMode?: any) => {
    if (viewMode !== 'day') return false;

    // other view mode is month || week
    return listCompanyActive.some((company) => {
      const isInRoleCanView = [
        UserRole.ADMIN,
        UserRole.MANAGER,
        UserRole.OFFICE_MANAGER,
        UserRole.CUSTOMER,
        UserRole.WORKER,
        UserRole.OTHER_WORKER,
        UserRole.PART_TIME_WORKER,
        UserRole.LEADER,
        UserRole.VENDOR_WORKER,
      ].includes(company.roleId);
      const isVendorCustomer = company.roleId === UserRole.VENDOR && !!company.isCustomer;

      return isInRoleCanView || isVendorCustomer;
    });

    // const isAdmin = ROLES_ADMIN.includes(profile?.company?.roleId);

    // return isAdmin;
  };

  const checkCanAddWorkerReport = ({ isSuperCalendar }: { isSuperCalendar?: boolean }) => {
    if (isSuperCalendar) {
      return (
        ROLES_CAN_ADD_WORKER_REPORT.some((role) => rolesInCompanyActive.includes(role)) ||
        isVendorCustomerInCompanyActive
      );
    }

    return ROLES_CAN_ADD_WORKER_REPORT.includes(profile?.company?.roleId) || isVendorCustomer(profile);
  };

  const checkCanOpenNote = ({ note }: { note: IComment }) => {
    // check roles can open note
    const isVendorCustomerInNote = note?.viewerRoleId === UserRole.VENDOR && Boolean(note?.viewerIsCustomer);
    const isRoleCanViewNote =
      [UserRole.CUSTOMER, ...ROLES_ADMIN].includes(note?.viewerRoleId) || isVendorCustomerInNote;

    return isRoleCanViewNote;
  };

  return { checkCanViewWorkerReport, checkCanAddWorkerReport, checkCanOpenNote };
};

export default useCheckPermissionCalendar;
