import { ITaskDetail } from 'common/interface';
import { renderListDayOffs } from 'helper';

interface IProps {
  taskDetail?: ITaskDetail;
}

const DetailsListDayOffs = ({ taskDetail }: IProps) => {
  // metadataDayOffs
  const metadataDayOffs: string[] = taskDetail?.metadataDayOffs ? JSON.parse(taskDetail.metadataDayOffs) : [];

  if (metadataDayOffs.length < 1) return null;

  return <div className="text-error">{renderListDayOffs(metadataDayOffs)}</div>;
};

export default DetailsListDayOffs;
