import { CompanyStatus, STATUS, SubscriptionType, UserStatus } from 'common';
import { ROLES_ADMIN } from 'common/const';
import moment from 'moment';

// disabled company for any role --- this from old code base.
export const disableCompany = (adminId: number, profileId: number, status: number) => {
  const isAdmin = adminId === profileId;
  if (status === CompanyStatus.ACTIVE) return false;
  else if (status === CompanyStatus.ADMIN_EXPIRE_PURCHASE && isAdmin) return false;
  else if (status === CompanyStatus.ADMIN_EXPIRE_PURCHASE && isAdmin) return false;
  else if (status === CompanyStatus.APPROVED && isAdmin) return false;
  else return true;
};

// check company is expired purchased --- this from old code base.
export const isExpiredPurchased = (el: any) => {
  return (
    el?.paymentStatus === STATUS.INACTIVE ||
    (el?.expiredAt !== null && moment(el?.expiredAt).isBefore(moment().format('YYYY-MM-DD')))
  );
};

// check is disable company --- this from old code base.
export const checkIsDisableCompany = (companyItem: any, currentProfile: any) => {
  const isAdmin = ROLES_ADMIN.includes(companyItem?.roleId);

  if (
    companyItem?.paymentStatus === STATUS.INACTIVE &&
    companyItem?.subscriptionType === SubscriptionType.ADMIN_BUY &&
    !isAdmin
  ) {
    return true;
  }

  if (companyItem?.subscriptionType === SubscriptionType.ADMIN_BUY && companyItem?.status !== CompanyStatus.INACTIVE)
    return false;

  return (
    disableCompany(companyItem?.adminId, currentProfile?.id, companyItem.status) ||
    companyItem.userCompanyStatus === CompanyStatus.INACTIVE ||
    companyItem?.adminStatus !== UserStatus.ACTIVE ||
    (isExpiredPurchased(companyItem) && companyItem?.adminId !== currentProfile?.id)
  );
};
