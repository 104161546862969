import { IDataNoteToTask } from 'common/interface';
import { create } from 'zustand';

type NoteToTask = IDataNoteToTask | null;

interface NoteToTaskStore {
  noteToTask: NoteToTask;
  openModalAddTask?: boolean;
  openModalAddNote?: boolean;
  setNoteToTask: (noteToTask: NoteToTask) => void;
  setOpenModalAddNote: (openModalAddNote: boolean) => void;
  setOpenModalAddTask: (openModalAddTask: boolean) => void;
  removeNoteToTask: () => void;
}

export const useNoteToTaskStore = create<NoteToTaskStore>((set) => ({
  noteToTask: null,
  setNoteToTask: (noteToTask: NoteToTask) => set({ noteToTask }),
  setOpenModalAddTask(openModalAddTask: boolean) {
    set({ openModalAddTask });
  },
  removeNoteToTask: () => set({ noteToTask: null, openModalAddTask: false }),
  setOpenModalAddNote: (openModalAddNote: boolean) => set({ openModalAddNote }),
}));
