import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { TaskDetailModal } from 'pages/Tasks/TaskDetailModal';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { getConstructionSiteTaskDetails } from 'api/constructionSite';
import { Form } from 'antd';
import { CommonInput } from 'components/CommonInput';
import Loader from 'components/Loader';
import { sendEmail } from 'api/task';
import { handleErrorMessage, handleMessageSuccess } from 'helper';
import { useRulesForm } from 'hooks/useRulesForm';
import { getNamePdfFile } from 'pages/Tasks/components/ButtonSendEmail/utils';
import { TYPE_INPUT } from 'common';
import useProfile from 'hooks/useProfile';
import sanitize from 'sanitize-filename';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print';
import { CommonButton } from 'components/CommonButton';
import { getImageFormUrl } from 'api/common';

type TValue = {
  replyToEmail: string;
  to: string;
  subject: string;
  html: string;
};
const SendEmail = () => {
  const detailsTaskRef = useRef<any>(null);

  const { taskId, companyId } = useParams<{ taskId: string; companyId: string }>();
  const profile = useProfile();

  const [form] = Form.useForm();
  const { rulesForm } = useRulesForm();

  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  // get details task
  const { data: taskDetail, isLoading: isLoadingTaskDetail } = useQuery(
    ['construction-site-task-detail', taskId, companyId],
    () => getConstructionSiteTaskDetails(+taskId, +companyId).then((res: any) => res?.data),
    { enabled: !!taskId }
  );

  const sendEmailMutation = useMutation({
    mutationFn: sendEmail,
    onSuccess() {
      handleMessageSuccess('メールを送信しました。');

      // reset fields form after send email success
      form.resetFields(['subject', 'html']);
    },
    onError(error, variables, context) {
      handleErrorMessage(error);
    },
  });

  const onSendEmail = (values: TValue) => {
    const formData = new FormData();
    formData.append('replyToEmail', values.replyToEmail);
    formData.append('to', values.to);
    formData.append('subject', values.subject ?? '');
    formData.append('html', values.html ?? '');
    formData.append('file', pdfFile as File);
    formData.append('taskId', taskDetail?.id as any);

    sendEmailMutation.mutate({ data: formData, companyId: +companyId });
  };

  const renderPdfTask = useReactToPrint({
    content: () => detailsTaskRef.current,
    onBeforeGetContent: () => {
      // remove class fixed-header-form
      if (detailsTaskRef?.current) {
        setIsLoadingPdf(true);

        // select all image and replace src
        detailsTaskRef.current.querySelectorAll('img')?.forEach((img: any) => {
          if (img?.src?.startsWith('https://')) {
            img.src = img.src.replace(img.src, getImageFormUrl(img.src));
          }
        });

        // remove class fixed-header-form to fix bug when print pdf
        detailsTaskRef.current?.classList.remove('fixed-header-form');
      }
    },
    onAfterPrint: () => {
      // add class fixed-header-form
      if (detailsTaskRef?.current) {
        setIsLoadingPdf(false);

        // reset class
        detailsTaskRef.current.classList.add('fixed-header-form');
      }
    },
    print: async (printIframe: HTMLIFrameElement) => {
      if (!detailsTaskRef.current) return;

      const document = printIframe.contentDocument;

      if (document) {
        const element = document.getElementsByTagName('html')[0];

        let opt = {
          margin: 1,
          image: { type: 'jpeg', quality: 0.95 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' },
        };

        try {
          // get name pdf file
          const namePdfFile = sanitize(getNamePdfFile(taskDetail) || 'task_details.pdf');

          // create blob pdf
          const blob = await html2pdf().set(opt).from(element).outputPdf('blob', namePdfFile);

          // convert blob to file
          const file = new File([blob], namePdfFile);

          // set pdf file
          setPdfFile(file);
        } catch (error) {
          handleErrorMessage(error);
        }
      }
    },
  });

  // watch isLoadingTaskDetail and ref detailsTaskRef to render pdf
  useEffect(() => {
    if (!detailsTaskRef.current || isLoadingTaskDetail) return;

    // set default value for form
    form.setFieldsValue({
      replyToEmail: profile?.email,
      to: taskDetail?.customerEmail,
    });

    // render pdf
    renderPdfTask();
  }, [detailsTaskRef.current, isLoadingTaskDetail, taskDetail?.customerEmail]);

  return (
    <>
      <div className={styles.wrapper}>
        <Form form={form} layout="vertical" onFinish={onSendEmail}>
          <Form.Item label="From" name="replyToEmail" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="example@gmail.com" />
          </Form.Item>
          <Form.Item label="To" name="to" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="example@gmail.com" />
          </Form.Item>
          <Form.Item label="件名" name="subject" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="タイトルを入力してください。" />
          </Form.Item>
          <Form.Item label="メッセージ" name="html" className="mb-10">
            <CommonInput placeholder="メッセージを入力してください" mode={TYPE_INPUT.MULTIPLE} />
          </Form.Item>

          <Loader isLoading={isLoadingPdf}>
            {pdfFile && <div className="text-blue">{getNamePdfFile(taskDetail)}</div>}
          </Loader>

          <CommonButton
            title="メール送信"
            htmlType="submit"
            type="primary"
            loading={sendEmailMutation.isLoading}
            className="mt-20 w-100"
          />
        </Form>
      </div>

      <TaskDetailModal
        taskId={+taskId}
        onCancel={() => {}}
        visible
        className={styles.myModal}
        ref={detailsTaskRef as any}
      />
    </>
  );
};

export default SendEmail;
