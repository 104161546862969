import { Col, Form, Row } from 'antd';
import { TYPE_INPUT } from 'common';
import { CommonInput, IPropsInput } from 'components/CommonInput';

interface IProps extends IPropsInput {}

const NoteConstruction = ({ ...rest }: IProps) => {
  return (
    <Row gutter={[20, 5]}>
      <Col span={24}>プロジェクト概要・メモ</Col>
      <Col flex={24}>
        <Form.Item name="noteConstructionSite" className="mb-form-0">
          <CommonInput mode={TYPE_INPUT.MULTIPLE} {...rest} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default NoteConstruction;
