import { UserRole } from 'common';
import { IProfile, ITaskDetail } from 'common/interface';

export const getNumberVendorHaveCertificate = ({
  taskDetail,
  currentRoleInCompany,
  profile,
}: {
  taskDetail?: ITaskDetail;
  currentRoleInCompany: UserRole;
  profile: IProfile;
}) => {
  return (
    taskDetail?.vendors?.reduce((acc, vendor) => {
      // Nếu vendor nào không được add chứng chỉ thì bỏ qua
      if (!vendor?.metadataUnitPriceCertificate) return acc;

      // Nếu người xem là vendor thì chỉ được xem chứng chỉ của mỗi nó
      if (currentRoleInCompany === UserRole.VENDOR && profile?.id !== vendor?.userId) {
        return acc;
      }

      return acc + 1;
    }, 0) || 0
  );
};
