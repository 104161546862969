import { Col, Form, Input, Row } from 'antd';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { CommonInputNumber } from 'components/CommonInputNumber';
import addField from 'assets/icons/add_circle_outline.svg';
import iconDelete from 'assets/icons/trash-2.svg';
import { useRef } from 'react';

interface IProps {
  disabledAll?: boolean;
  showIcons?: boolean;
}

const FormCertificateVendor = ({ disabledAll, showIcons }: IProps) => {
  const addRef = useRef<HTMLImageElement>(null);

  return (
    <div className={styles.container}>
      <div className={styles.title}>人工のライセンス</div>

      <Row gutter={20} className={styles.row}>
        <Col span={12}>
          <Form.Item
            label={<span className={classNames('color-text', 'text-13')}>ライセンス</span>}
            name="titleCertificateVendor"
          >
            <Input className="inputGreyNoBorder" placeholder="タイトルを入力" disabled={disabledAll} />
          </Form.Item>
        </Col>
        <Col span={12} className={styles.colWithIcon}>
          <Form.Item
            label={<span className={classNames('color-text', 'text-13')}>単価</span>}
            name="priceCertificateVendor"
            style={{ flex: '1' }}
          >
            <CommonInputNumber placeholder="単価" disabled={disabledAll} />
          </Form.Item>

          {showIcons && (
            <Form.Item>
              <img
                src={addField}
                alt=""
                onClick={() => addRef.current?.click()}
                className={classNames(styles.icon, styles.iconAdd, {
                  [styles.disabled]: disabledAll,
                })}
              />
            </Form.Item>
          )}
        </Col>
      </Row>

      <div className={styles.wrapList}>
        <Form.List name="dataUnitPriceCertificateVendor">
          {(fields, { add, remove }) => (
            <>
              <Form.Item className={classNames(styles.iconAddHide)}>
                <img src={addField} alt="" onClick={() => !disabledAll && add()} ref={addRef} />
              </Form.Item>

              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={20} className={styles.row}>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      name={[name, 'title']}
                      label={<span className={classNames('color-text', 'text-13')}>ライセンス</span>}
                    >
                      <Input className="inputGreyNoBorder" placeholder="タイトルを入力" disabled={disabledAll} />
                    </Form.Item>
                  </Col>
                  <Col span={12} className={styles.colWithIcon}>
                    <Form.Item
                      {...restField}
                      name={[name, 'price']}
                      style={{ flex: '1' }}
                      label={<span className={classNames('color-text', 'text-13')}>単価</span>}
                    >
                      <CommonInputNumber placeholder="単価" disabled={disabledAll} />
                    </Form.Item>

                    {showIcons && (
                      <Form.Item>
                        <img
                          src={iconDelete}
                          alt=""
                          onClick={() => !disabledAll && remove(name)}
                          className={classNames(styles.icon, {
                            [styles.disabled]: disabledAll,
                          })}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
      </div>
    </div>
  );
};

export default FormCertificateVendor;
