interface IObject {
  [key: number]: {
    id: number;
    name: string;
    [key: string]: any;
  };
}

class AddressHelper {
  public kens: IObject;
  public cities: IObject;
  constructor() {
    this.kens = {};
    this.cities = {};
  }
  init(kens: any[], cities: any[]) {
    this.kens = kens?.reduce((result, ken) => ({ ...result, [ken.id]: ken }), {}) || {};
    this.cities = cities?.reduce((result, ken) => ({ ...result, [ken.id]: ken }), {}) || {};
  }
}

const addressHelper = new AddressHelper();

export default addressHelper;
