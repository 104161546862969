import { Typography } from 'antd';
import styles from './styles.module.scss';
import classNames from 'classnames';
const { Text, Title } = Typography;

interface IStaffTag {
  avatar: string | null;
  vendor?: string;
  name?: string;
  disabled?: boolean;
  showRolesInCompany?: boolean;
  roleInCompany?: string;
}

export const StaffTag = ({ avatar, vendor, name, disabled, showRolesInCompany, roleInCompany, ...rest }: IStaffTag) => {
  return (
    <div {...rest} style={{ height: '100%' }}>
      <div className={styles.profile}>
        <div className={styles.avatar}>
          {avatar ? (
            <img src={avatar} alt="" />
          ) : (
            <div className={styles.avatarText}>
              <b>{name ? name[0] : 'A'}</b>
            </div>
          )}
        </div>

        <div className={classNames(styles.infoprofile, 'max-1-line')}>
          <Title level={5}>
            <div className={disabled ? 'color-red' : ''}>{name}</div>
          </Title>
          {vendor && <Text>{`サプライヤー: ${vendor}`}</Text>}
        </div>

        {showRolesInCompany && (
          <div
            className={classNames(styles.nameRoleInCompany, {
              'text-error': disabled,
            })}
          >
            ({roleInCompany})
          </div>
        )}
      </div>
    </div>
  );
};
