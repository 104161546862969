import useRoleId from 'hooks/useRoleId';

interface IProps {
  hidden?: boolean;
  accessibleRoles: Array<number>;
  children: any;
  isEnabled?: boolean;
}
export default function AccessControl(props: IProps) {
  const currentRole = useRoleId();
  if (props.hidden) return null;
  if (!props?.accessibleRoles?.includes(currentRole) && !props?.isEnabled) return null;

  return <>{props.children}</>;
}
