import { DropDownProps, Dropdown } from 'antd';

interface CustomDropDownProps extends DropDownProps {
  children?: React.ReactNode;
}

const CustomDropDown = ({ children, ...rest }: CustomDropDownProps) => {
  return (
    <Dropdown
      overlayStyle={{
        height: '256px',
        overflowY: 'auto',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      }}
      {...rest}
    >
      {children}
    </Dropdown>
  );
};

export default CustomDropDown;
