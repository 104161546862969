import { ICheckedShiftOfAdmin, ICheckedShiftOfCustomer, ICompanyProfile, ITaskDetail } from 'common/interface';
import moment from 'moment';

interface IProps {
  checkedOfAdmin: ICheckedShiftOfAdmin;
  checkedOfCustomer: ICheckedShiftOfCustomer;
  isCustomer?: boolean;
  taskDetail?: ITaskDetail;
  profileCompany: ICompanyProfile;
}

const useGetTimeShiftCustomer = () => {
  const getTimeShiftCustomer = ({
    checkedOfAdmin,
    checkedOfCustomer,
    isCustomer,
    taskDetail,
    profileCompany,
  }: IProps) => {
    if (!taskDetail) return {};

    // trường hợp : check xóa tất cả các ca phía admin và thêm vào ca other phía admin
    // xử lý : time Form và time To sẽ được fill theo thời gian của ca others.
    const isAdminDeleteAllShiftAndAddOther =
      [checkedOfAdmin.morningShift, checkedOfAdmin.afternoonShift, checkedOfAdmin.eveningShift].every(
        (shift) => !shift
      ) && checkedOfAdmin.otherShift;

    if (isAdminDeleteAllShiftAndAddOther && isCustomer) {
      const isAdminDeleteMorningShift = checkedOfCustomer.morning && !checkedOfAdmin.morningShift;
      const isAdminDeleteAfternoonShift = checkedOfCustomer.afternoon && !checkedOfAdmin.afternoonShift;
      const isAdminDeleteEveningShift = checkedOfCustomer.evening && !checkedOfAdmin.eveningShift;

      // TH checked 1 ca
      const isCheckOneShiftCustomer =
        [checkedOfCustomer.morning, checkedOfCustomer.afternoon, checkedOfCustomer.evening].filter(Boolean).length ===
        1;

      // TH checked 2 ca
      const isCheckTwoShiftCustomer =
        [checkedOfCustomer.morning, checkedOfCustomer.afternoon, checkedOfCustomer.evening].filter(Boolean).length ===
        2;

      // TH checked 3 ca
      const isCheckThreeShiftCustomer =
        [checkedOfCustomer.morning, checkedOfCustomer.afternoon, checkedOfCustomer.evening].filter(Boolean).length ===
        3;

      if (isCheckOneShiftCustomer && isAdminDeleteMorningShift) {
        return {
          morningFrom: taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '',
          morningTo: taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '',
        };
      }

      if (isCheckOneShiftCustomer && isAdminDeleteAfternoonShift) {
        return {
          afternoonFrom: taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '',
          afternoonTo: taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '',
        };
      }

      if (isCheckOneShiftCustomer && isAdminDeleteEveningShift) {
        return {
          eveningFrom: taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '',
          eveningTo: taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '',
        };
      }

      if (isCheckTwoShiftCustomer && isAdminDeleteMorningShift && isAdminDeleteAfternoonShift) {
        return {
          morningFrom: taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '',
          morningTo: profileCompany?.morningTo ? moment(profileCompany?.morningTo, 'HH:mm') : '',
          afternoonFrom: profileCompany?.afternoonFrom ? moment(profileCompany?.afternoonFrom, 'HH:mm') : '',
          afternoonTo: taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '',
        };
      }

      if (isCheckTwoShiftCustomer && isAdminDeleteMorningShift && isAdminDeleteEveningShift) {
        return {
          morningFrom: taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '',
          morningTo: profileCompany?.morningTo ? moment(profileCompany?.morningTo, 'HH:mm') : '',
          eveningFrom: profileCompany?.eveningFrom ? moment(profileCompany?.eveningFrom, 'HH:mm') : '',
          eveningTo: taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '',
        };
      }

      if (isCheckTwoShiftCustomer && isAdminDeleteAfternoonShift && isAdminDeleteEveningShift) {
        return {
          afternoonFrom: taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '',
          afternoonTo: profileCompany?.afternoonTo ? moment(profileCompany?.afternoonTo, 'HH:mm') : '',
          eveningFrom: profileCompany?.eveningFrom ? moment(profileCompany?.eveningFrom, 'HH:mm') : '',
          eveningTo: taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '',
        };
      }

      if (isCheckThreeShiftCustomer) {
        return {
          morningFrom: taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '',
          morningTo: profileCompany?.morningTo ? moment(profileCompany?.morningTo, 'HH:mm') : '',
          afternoonFrom: profileCompany?.afternoonFrom ? moment(profileCompany?.afternoonFrom, 'HH:mm') : '',
          afternoonTo: profileCompany?.afternoonTo ? moment(profileCompany?.afternoonTo, 'HH:mm') : '',
          eveningFrom: profileCompany?.eveningFrom ? moment(profileCompany?.eveningFrom, 'HH:mm') : '',
          eveningTo: taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '',
        };
      }
    }

    return {};
  };

  return { getTimeShiftCustomer };
};

export default useGetTimeShiftCustomer;
