import Axios from 'axios';
import { handleErrorMessage } from 'helper';
import Cookies from 'js-cookie';
import { history, queryClient } from '../App';
import configs from '../config';

export const axiosInstance = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: configs.API_DOMAIN,
});

const THIRDTY_DAY = 30;

axiosInstance.interceptors.request.use(
  (config) => {
    // get data config
    const companyId = config.headers?.companyId ?? Cookies.get('companyId');

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${Cookies.get('token')}`;
    config.headers.companyId = companyId;
    return config;
  },
  (error) => Promise.reject(error)
);

export const logout = (error?: any) => {
  Cookies.remove('token');
  Cookies.remove('refreshToken');
  Cookies.remove('companyId');
  Cookies.remove('roleId');
  Cookies.remove('inviteToken');
  Cookies.remove('viewMode');
  queryClient.clear();
  history.push('/login');
  return Promise.reject(error);
};
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { data } = error?.response || {};

    if (
      data?.errorCode === 'Your_Company_In_Header_Not_Valid' ||
      data?.errorCode === 'You_Are_Blocked_From_This_Company' ||
      data?.errorCode === 'Admin_Of_This_Company_Expire_Purchase'
    ) {
      Cookies.remove('companyId');
      history.push('/login-company');
      handleErrorMessage(error);
      return Promise.reject(error);
    }
    const originalConfig = error?.config;
    if (error?.response?.status !== 401) {
      return Promise.reject(error);
    }

    const refreshToken = Cookies.get('refreshToken');
    if (!refreshToken) {
      logout(error);
      return Promise.reject(error);
    }
    try {
      const res = await Axios.post(`${configs.API_DOMAIN}/auth/request-access-token`, {
        refreshToken,
      });

      if (res?.data?.success) {
        const data = res?.data?.data;
        Cookies.set('token', data?.token, { expires: THIRDTY_DAY });
        originalConfig.headers.Authorization = `Bearer ${data?.token}`;
        return Axios(originalConfig);
      } else {
        logout(error);
        return Promise.reject(error);
      }
    } catch (error) {
      logout(error);
      return Promise.reject(error);
    }
  }
);

export const sendGet = <T = any>(url: string, params?: any) =>
  axiosInstance.get<T>(url, { params }).then((res) => res?.data);
export const sendPost = <T = any>(url: string, params?: any, queryParams?: any) =>
  axiosInstance.post<T>(url, params, { params: queryParams }).then((res) => res?.data);
export const sendPut = <T = any>(url: string, params?: any) =>
  axiosInstance.put<T>(url, params).then((res) => res?.data);
export const sendPatch = (url: string, params?: any) => axiosInstance.patch(url, params).then((res) => res?.data);
export const sendDelete = (url: string, params?: any) => axiosInstance.delete(url, { params }).then((res) => res?.data);

export const sendUploadFile = (url: string, data: any) =>
  axiosInstance.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
