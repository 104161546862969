import React from 'react';
import addField from 'assets/icons/add_circle_outline.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
}

const IconAddDateExcludeTask = ({ className, disabled, ...rest }: IProps) => {
  return (
    <div className={classNames(styles.wrapper, { [styles.disabled]: disabled }, className)} {...rest}>
      <div>空け</div>
      <img src={addField} alt="" />
    </div>
  );
};

export default IconAddDateExcludeTask;
