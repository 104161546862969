import { Button, Col, DatePicker, Form, message, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import { searchCommonLeader, searchCommonWorker } from 'api/common';
import {
  createConstructionSite,
  getConstructionSiteDetail,
  ISearchCustomer,
  searchCustomer,
  updateConstructionSite,
  vendorApproveConstructionSite,
} from 'api/constructionSite';
import { searchNameCompany } from 'api/customer';
import classNames from 'classnames';
import { CommonStatus, ConstructionSiteState, ConstructionSiteType, ModeType, UserRole } from 'common';
import { BIG_PAGESIZE, listConstructionSiteType, MAX_LENGTH } from 'common/const';
import AccessControl from 'components/AccessControl';
import { CommonInput } from 'components/CommonInput';
import { CommonInputNumber } from 'components/CommonInputNumber';
import { CommonModal } from 'components/CommonModal';
import { CommonSelect } from 'components/CommonSelect';
import { TextAreaWithAttachment } from 'components/TextAreaWithAttachment';
import VendorPicker from 'components/VendorPicker';
import WorkerPicker from 'components/WorkerPicker';
import { getFileNameFromUrl, handleErrorMessage, isVendor, isVendorCustomer, updateLocationSearchQuery } from 'helper';
import useProfile from 'hooks/useProfile';
import useRoleId from 'hooks/useRoleId';
import _, { debounce, isNil, omit } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useSetState } from 'react-use';
import styles from './styles.module.scss';
import { IVendor, IWorker } from 'common/interface';
import { useNoteToTaskStore } from 'stores/NoteToTaskStore';

const { RangePicker } = DatePicker;
const { Option } = Select;

const VENDOR_FORM_FIELD = [
  'state',
  'name',
  'type',
  'station',
  'contractValue',
  'startTime',
  'endTime',
  'kenId',
  'cityId',
  'address',
  'customerId',
  'description',
  'files',
  'vendors',
  'workers',
  'timeRange',
];
const CUSTOMER_FORM_FIELD = [
  'state',
  'name',
  'type',
  'station',
  'contractValue',
  'startTime',
  'endTime',
  'kenId',
  'cityId',
  'address',
  'description',
  'files',
  'timeRange',
];

const CUSTOMER_UPDATE_FORM_FIELD = ['state', 'kenId', 'cityId', 'address', 'description', 'timeRange', 'files'];

type Mode = 'create' | 'edit';

type TFormValues = {
  workers: (IWorker & { isChecked: boolean })[];
  [key: string]: any;
};

interface IProps {
  mode: Mode;
  constructionSiteId?: number;
  onSuccess: () => any;
  onCancel: () => any;
  constructionSiteDetail?: any;
  setShowAddTask?: (value: boolean) => void;
}
export default function CreateConstructionSite({
  mode,
  constructionSiteId,
  onSuccess,
  onCancel,
  constructionSiteDetail,
  setShowAddTask = () => {},
}: IProps) {
  const [form] = useForm();
  const [typeProject, setTypeProject] = useState(ConstructionSiteType.LABOR);
  const queryClient = useQueryClient();
  const { data: resource }: any = queryClient.getQueryData('resource');
  const profile = useProfile();
  const roleId = useRoleId();
  const [isOpen, setIsOpen] = useState({ modalConfirm: false, modalConfirmCreate: false });
  const [loading, setLoading] = useState(false);
  const [representativeName, setRepresentativeName] = useState<any>(null);
  const [openFormCreateConstruction, setOpenFormCreateConstruction] = useState(false);
  const history = useHistory();
  const handleSelectCustomer = (id: number) => {
    const customer = listCustomer?.data?.find((x: any) => x?.id === id);
    setRepresentativeName(customer?.representativeName);
    const constructionSiteType = form.getFieldValue('type');
    if (constructionSiteType === ConstructionSiteType.LABOR) {
      form.setFieldsValue({
        regularUnitPrice: customer?.regularUnitPrice,
        nightUnitPrice: customer?.nightUnitPrice,
      });
    }
  };

  const handleChangeType = (type: number) => {
    const fieldValue = {
      customerId: null,
      regularUnitPrice: null,
      nightUnitPrice: null,
    };
    if (type === ConstructionSiteType.LABOR) {
      form.setFieldsValue({ ...fieldValue, contractValue: null });
    } else {
      form.setFieldsValue(fieldValue);
    }
  };
  const [leaderFilter, setLeaderFilter] = useSetState({
    pageIndex: 1,
    pageSize: 100,
    ignoreConstructionSiteId: constructionSiteId,
    startTime: undefined,
    endTime: undefined,
    keyword: '',
  });
  const { data: listLeader } = useQuery(['list_leader', leaderFilter], () => searchCommonLeader(leaderFilter));
  const handleSearchLeader = useCallback(
    _.debounce((keyword: string) => {
      setLeaderFilter({
        keyword,
        startTime: form.getFieldValue('timeRange')?.[0]?.format('YYYY-MM-DD'),
        endTime: form.getFieldValue('timeRange')?.[1]?.format('YYYY-MM-DD'),
      });
    }, 300),
    []
  );

  useEffect(() => {
    // get
    const fetch = async () => {
      const detailsConstructionSite = await getConstructionSiteDetail(Number(constructionSiteId));
      const { data } = detailsConstructionSite || {};

      const vendorIds = detailsConstructionSite?.data?.vendors?.map((vendor) => vendor?.id);
      const workerIdsInConstruction = detailsConstructionSite?.data?.workers?.map((worker) => worker?.id);

      const dataWorkers = await searchCommonWorker({
        pageIndex: 1,
        pageSize: 1000000,
        vendorIds: vendorIds?.length ? vendorIds : undefined,
        actionStatus: [CommonStatus.ACTIVE],
      });

      const workers = dataWorkers.data?.map((worker) => {
        const isChecked = workerIdsInConstruction?.includes(worker?.id);

        return { ...worker, isChecked };
      });

      const formValues = {
        ...data,
        timeRange: [
          data?.startTime ? moment(data?.startTime) : undefined,
          data?.endTime ? moment(data?.endTime) : undefined,
        ],
        description: {
          content: data?.description || null,
          files: data?.files || [],
        },
        regularUnitPrice: data.type === ConstructionSiteType.FIXED_PRICE ? null : data.regularUnitPrice,
        nightUnitPrice: data.type === ConstructionSiteType.FIXED_PRICE ? null : data.nightUnitPrice,
        workers,
      };

      form.setFieldsValue(formValues);

      if (roleId === UserRole.VENDOR) {
        form.setFieldsValue(_.pick(formValues, VENDOR_FORM_FIELD));
        // Select vendor workers only if role is Vendor
        form.setFieldsValue({ workers: workers?.filter((x: any) => x.managerId === profile.id) });
      }
      if (roleId === UserRole.CUSTOMER) {
        form.setFieldsValue(_.pick(formValues, CUSTOMER_FORM_FIELD));
      }
      setRepresentativeName(data?.representativeName);
      setCustomerFilter({ companyName: data?.customerCompanyName });
      handleSearchLeader('');
    };

    if (mode === ModeType.EDIT) {
      // getDetailConstruction();

      try {
        fetch();
      } catch (error) {
        handleErrorMessage(error);
      }
    }
    // Create mode
    if (mode === ModeType.CREATE) {
      form.setFieldsValue({ state: ConstructionSiteState.COMING_TO_WORK, type: ConstructionSiteType.LABOR });
      searchCommonWorker({
        pageIndex: 1,
        pageSize: 1000000,
      }).then((res) => form.setFieldsValue({ workers: res?.data?.map((worker) => ({ ...worker, isChecked: true })) }));
    }
  }, []);
  const handleOk = async () => {
    if (mode === ModeType.EDIT) {
      form.submit();
      return;
    }
    try {
      await form.validateFields();
      if (typeProject === ConstructionSiteType.LABOR) {
        setIsOpen({ modalConfirm: false, modalConfirmCreate: true });
        return;
      }
      setIsOpen({ modalConfirmCreate: false, modalConfirm: true });
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  const { noteToTask, setOpenModalAddTask, setNoteToTask } = useNoteToTaskStore();

  const handleSubmit = async (values: any) => {
    setLoading(true);

    if (roleId === UserRole.VENDOR && mode === ModeType.EDIT) {
      const workers =
        values?.workers?.filter((worker: any) => worker?.isChecked)?.map((x: any) => ({ userId: x.id })) || [];
      const files = values?.description?.files?.map((x: any) => {
        const id = isNil(x?.objectId) ? x?.id : undefined;

        // if not exist id => create new file
        if (!id) {
          return { name: x.name, url: getFileNameFromUrl(x.url) };
        }

        return { name: x.name, url: getFileNameFromUrl(x.url), id };
      });

      let payload: any = {
        status: constructionSiteDetail?.status,
        workers,
        files: files || [],
        description: values?.description?.content || '',
      };

      // remove files : vendor_customer && not customer of construction site
      if (isVendorCustomer(profile) && !isCustomerOfConstructionSite) {
        payload = omit(payload, ['files', 'description']);
      }

      // remove workers
      if (isVendorCustomer(profile) && isCustomerOfConstructionSite && !isVendorOfConstructionSite) {
        payload = omit(payload, ['workers']);
      }

      // remove workers
      if (isVendorCustomer(profile) && isCustomerOfConstructionSite && !isVendorOfConstructionSite) {
        payload = omit(payload, ['workers']);
      }

      try {
        // await updateConstructionSite(Number(constructionSiteId), payload);
        await vendorApproveConstructionSite(Number(constructionSiteId), payload);

        message.success('成功', 5);
        queryClient.invalidateQueries('list-all-construction-site');
        queryClient.invalidateQueries('construction-site-detail');
      } catch (e) {
        handleErrorMessage(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        let data = {
          state: values.state,
          name: values.name,
          type: values.type,
          leaderId: values.leaderId,
          address: values.address,
          station: values.station ? values.station : null,
          contractValue: values.contractValue || 0,
          startTime: values?.timeRange?.[0]?.format('YYYY-MM-DD') || null,
          endTime: values?.timeRange?.[1]?.format('YYYY-MM-DD') || null,
          customerId: values?.customerId,
          regularUnitPrice: values?.regularUnitPrice,
          nightUnitPrice: values?.nightUnitPrice,
          description: values?.description?.content || '',
          files: values?.description?.files?.map((x: any) => ({ name: x.name, url: getFileNameFromUrl(x.url) })) || [],
          vendors:
            roleId === UserRole.VENDOR
              ? [{ userId: profile.id }]
              : values?.vendors?.map((x: any) => ({
                  userId: x.id,
                  salary: values.type === ConstructionSiteType.FIXED_PRICE ? Number(x.salary) || 0 : undefined,
                })) || [],
          workers:
            values?.workers?.filter((worker: any) => worker?.isChecked)?.map((x: any) => ({ userId: x.id })) || [],
        };

        let payload: any = data;
        const newPayload =
          mode === ModeType.EDIT ? _.pick(data, CUSTOMER_UPDATE_FORM_FIELD) : _.pick(data, CUSTOMER_FORM_FIELD);

        if (roleId === UserRole.VENDOR) {
          payload = _.pick(data, VENDOR_FORM_FIELD);
        }

        if (roleId === UserRole.CUSTOMER) {
          payload = newPayload;
        }

        // constructionSiteId of data note to task
        let constructionSiteIdDataNoteToTask = null;

        if (mode === ModeType.EDIT) {
          await updateConstructionSite(Number(constructionSiteId), payload);
        } else {
          const res = await createConstructionSite(payload);
          updateLocationSearchQuery(history, { constructionSiteId: res.data.id });

          constructionSiteIdDataNoteToTask = res?.data?.id;
        }

        await queryClient.invalidateQueries('list-all-construction-site');
        await queryClient.invalidateQueries('construction-site-detail');
        setIsOpen({ modalConfirm: false, modalConfirmCreate: false });
        onCancel();
        message.destroy();
        message.success('成功', 5);
        if (openFormCreateConstruction) {
          setShowAddTask(true);
        }

        // check if noteToTask is not null, then create new Task from note
        if (noteToTask && !isNil(constructionSiteIdDataNoteToTask)) {
          setOpenModalAddTask(true);
          setNoteToTask({
            ...noteToTask,
            constructionSiteId: constructionSiteIdDataNoteToTask,
            noteConstructionSite: data?.description,
          });

          history.push('/calendar');
        }
      } catch (e) {
        handleErrorMessage(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRemoveVendor = useCallback((vendor: any) => {
    const workers: any[] = form.getFieldValue('workers');
    const newWorkers = workers?.filter((worker) => {
      return worker.roleId !== UserRole.VENDOR_WORKER || vendor.id !== worker.managerId;
    });
    form.setFieldsValue({ workers: newWorkers });
  }, []);

  const [searchCompanyParam, setSearchCompanyParam] = useSetState({
    pageIndex: 1,
    pageSize: BIG_PAGESIZE,
    keyword: '',
  });

  const { data: listCompanyName } = useQuery(['list-company-of-customer', searchCompanyParam], () =>
    searchNameCompany(searchCompanyParam)
  );

  const handleSearchCustomerCompany = useCallback(
    debounce((keyword: string) => setSearchCompanyParam({ keyword }), 300),
    []
  );
  const handleSelectCustomerCompany = (e: any) => {
    form.setFieldsValue({ customerId: null, regularUnitPrice: null, nightUnitPrice: null });
    setCustomerFilter({ companyName: e });
  };

  const [customerFilter, setCustomerFilter] = useSetState<ISearchCustomer>({
    pageIndex: 1,
    pageSize: 100,
    companyName: '',
    customerName: '',
    actionStatus: [CommonStatus.ACTIVE],
  });
  const { data: listCustomer } = useQuery(['list_customer', customerFilter], () => searchCustomer(customerFilter), {
    enabled: customerFilter.companyName ? true : false,
  });
  const handleSearchCustomer = useCallback(
    _.debounce((keyword: string) => setCustomerFilter({ customerName: keyword }), 300),
    []
  );

  // all values -> có data workers cũ
  const onValuesChange = (field: any, allValues: TFormValues) => {
    if (field?.type) {
      setTypeProject(field?.type);
    }

    if (field?.vendors) {
      const vendorIds = field?.vendors?.map((item: any) => item.id);
      searchCommonWorker({
        pageIndex: 1,
        pageSize: 1000000,
        vendorIds,
        actionStatus: [CommonStatus.ACTIVE],
      }).then((res) => {
        // persist isChecked from old value
        const workers = res?.data?.map((worker) => {
          const isCurrentChecked = allValues?.workers?.some((item) => item.id === worker.id && item.isChecked);
          const isOldWorker = allValues?.workers?.some((item) => item.id === worker.id);

          return { ...worker, isChecked: isOldWorker ? isCurrentChecked : true };
        });

        form.setFieldsValue({ workers });
      });
    }
  };

  const handleOkConfirm = () => {
    // if (roleId === UserRole.VENDOR) {
    //   setIsOpen({ modalConfirm: false, modalConfirmCreate: false });
    //   return;
    // }

    setIsOpen({ modalConfirm: false, modalConfirmCreate: true });
  };
  const handleCloseCreate = () => {
    setOpenFormCreateConstruction(false);
    form.submit();
  };
  const handleOkCreate = () => {
    setOpenFormCreateConstruction(true);
    form.submit();
  };
  const handleClear = () => {
    setLeaderFilter({ ...leaderFilter, keyword: '', pageIndex: 1 });
  };

  const disableContractValue = () => {
    if (mode === 'edit') {
      return [UserRole.CUSTOMER, UserRole.VENDOR].includes(profile.company.roleId);
    }

    return roleId === UserRole.CUSTOMER || roleId === UserRole.VENDOR ? false : true;
  };

  const isCustomerOfConstructionSite = constructionSiteDetail?.customerId === profile?.id;
  const isVendorOfConstructionSite = constructionSiteDetail?.vendors?.some(
    (vendor: IVendor) => vendor?.id === profile?.id
  );

  const disableDescription = () => {
    if (mode === 'edit' && isVendorCustomer(profile)) {
      // Check xem co phai vendor_customer là customer cua cong truong hay khong
      return !isCustomerOfConstructionSite;
    }

    // vendor khong duoc edit description
    if (mode === 'edit' && isVendor(profile)) return true;

    return false;
  };

  const isDisableWorkerPicker = () => {
    if (mode === 'edit' && isVendorCustomer(profile) && !isCustomerOfConstructionSite && !isVendorOfConstructionSite) {
      return true;
    }

    if (mode === 'edit' && isVendorCustomer(profile) && isCustomerOfConstructionSite && !isVendorOfConstructionSite) {
      return true;
    }

    return false;
  };

  return (
    <Modal visible closable={false} footer={null} width={1200}>
      <div className={styles.container}>
        <Form
          layout="vertical"
          form={form}
          scrollToFirstError={true}
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
        >
          <div className="d-flex justify-content-between">
            {mode === ModeType.CREATE ? <h3>プロジェクト作成</h3> : <h3>プロジェクト編集</h3>}
            <AccessControl accessibleRoles={[UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER]}>
              <Form.Item
                label="状態"
                name="state"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
                className={styles.selectStatus}
              >
                <Select>
                  <Option value={ConstructionSiteState.COMING_TO_WORK}>
                    <span className="color-orange">● 開始前 </span>
                  </Option>
                  <Option value={ConstructionSiteState.WORKING}>
                    <span className="color-green">● 進行中</span>
                  </Option>
                  <Option value={ConstructionSiteState.COMPLETED}>
                    <span className="color-blue">● 業務終了</span>
                  </Option>
                  <Option value={ConstructionSiteState.PAUSE}>
                    <span className="color-yellow">● 中止</span>
                  </Option>
                </Select>
              </Form.Item>
            </AccessControl>
          </div>
          <Row gutter={8}>
            <Col span={12}>
              <Row gutter={8}>
                <Col
                  span={
                    [
                      UserRole.ADMIN,
                      UserRole.MANAGER,
                      UserRole.OFFICE_MANAGER,
                      UserRole.CUSTOMER,
                      UserRole.VENDOR,
                    ]?.includes(roleId)
                      ? 12
                      : 24
                  }
                >
                  <Form.Item
                    label="プロジェクト名"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: '全ての項目を入力してください。',
                      },
                    ]}
                    labelAlign="left"
                  >
                    <CommonInput
                      disabled={(roleId === UserRole.VENDOR || roleId === UserRole.CUSTOMER) && mode === 'edit'}
                    />
                  </Form.Item>
                </Col>
                <AccessControl
                  accessibleRoles={[
                    UserRole.ADMIN,
                    UserRole.MANAGER,
                    UserRole.OFFICE_MANAGER,
                    UserRole.CUSTOMER,
                    UserRole.VENDOR,
                  ]}
                >
                  <Col span={12}>
                    <Form.Item
                      label="プロジェクトのタイプ"
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: '全ての項目を入力してください。',
                        },
                      ]}
                      labelAlign="left"
                    >
                      <CommonSelect
                        showSearch={false}
                        optionValue="id"
                        optionLabel="name"
                        options={listConstructionSiteType}
                        disabled={mode === 'edit'}
                        onChange={handleChangeType}
                      />
                    </Form.Item>
                  </Col>
                </AccessControl>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const type = getFieldValue('type');
                      if (type === ConstructionSiteType.LABOR) {
                        return (
                          <Form.Item label="売上金額" name="contractValue" labelAlign="left">
                            <CommonInputNumber
                              min={0}
                              // disabled={roleId === UserRole.CUSTOMER || roleId === UserRole.VENDOR ? false : true}
                              disabled={disableContractValue()}
                            />
                          </Form.Item>
                        );
                      }
                      return (
                        <Form.Item
                          label="売上金額"
                          name="contractValue"
                          labelAlign="left"
                          rules={[
                            {
                              required: true,
                              message: '全ての項目を入力してください。',
                            },
                          ]}
                        >
                          <CommonInputNumber
                            min={0}
                            disabled={(roleId === UserRole.VENDOR || roleId === UserRole.CUSTOMER) && mode === 'edit'}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="施工期間" name="timeRange" labelAlign="left">
                    <RangePicker
                      disabled={(roleId === UserRole.VENDOR || roleId === UserRole.CUSTOMER) && mode === 'edit'}
                      style={{ width: '100% ', background: '#f0f0f0' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label="住所"
                name="address"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput
                  maxLength={MAX_LENGTH * 2}
                  disabled={
                    (roleId === UserRole.VENDOR || isVendorCustomer(profile) || roleId === UserRole.CUSTOMER) &&
                    mode === 'edit'
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="最寄り駅" name="station" labelAlign="left">
                <CommonInput
                  maxLength={MAX_LENGTH * 2}
                  disabled={
                    (roleId === UserRole.VENDOR || isVendorCustomer(profile) || roleId === UserRole.CUSTOMER) &&
                    mode === 'edit'
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <AccessControl accessibleRoles={[UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER]}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="担当者" name="leaderId" labelAlign="left">
                  <CommonSelect
                    filterOption={false}
                    onSearch={handleSearchLeader}
                    optionLabel={'name'}
                    options={listLeader?.data || []}
                    onFocus={() => handleSearchLeader('')}
                    allowClear
                    onClear={handleClear}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <div style={{ position: 'relative' }}>
                  <span className={`color-text2 line-1 ${styles.representativeName}`}>
                    {representativeName ? `代表者名: ${representativeName}` : ''}
                  </span>
                </div>
              </Col>
            </Row>
          </AccessControl>

          <AccessControl accessibleRoles={[UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER]}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="企業名"
                  name="customerCompanyName"
                  rules={[
                    {
                      required: true,
                      message: '全ての項目を入力してください。',
                    },
                  ]}
                  labelAlign="left"
                >
                  <CommonSelect
                    filterOption={false}
                    onSearch={handleSearchCustomerCompany}
                    optionLabel="companyName"
                    optionValue="companyName"
                    options={listCompanyName?.data || []}
                    onSelect={handleSelectCustomerCompany}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="クライアント名"
                  name="customerId"
                  rules={[
                    {
                      required: true,
                      message: '全ての項目を入力してください。',
                    },
                  ]}
                  labelAlign="left"
                >
                  <CommonSelect
                    filterOption={false}
                    onSearch={handleSearchCustomer}
                    optionValue="id"
                    optionLabel={'customerName'}
                    optionAvatar="customerAvatar"
                    options={listCustomer?.data || []}
                    onSelect={handleSelectCustomer}
                  />
                </Form.Item>
              </Col>
            </Row>
          </AccessControl>
          <AccessControl accessibleRoles={[UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER]}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const constructionSiteType = getFieldValue('type');
                const isFixPrice = constructionSiteType === ConstructionSiteType.FIXED_PRICE;
                return (
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        label="常用基本単価"
                        name="regularUnitPrice"
                        labelAlign="left"
                        rules={[
                          {
                            required: !isFixPrice,
                            message: '全ての項目を入力してください。',
                          },
                        ]}
                      >
                        <CommonInputNumber min={0} disabled={isFixPrice} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="夜間単価"
                        name="nightUnitPrice"
                        labelAlign="left"
                        rules={[
                          {
                            required: !isFixPrice,
                            message: '全ての項目を入力してください。',
                          },
                        ]}
                      >
                        <CommonInputNumber min={0} disabled={isFixPrice} />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              }}
            </Form.Item>
          </AccessControl>
          <Form.Item label="プロジェクト概要・メモ" name="description" labelAlign="left">
            <TextAreaWithAttachment disabled={disableDescription()} />
          </Form.Item>
          <AccessControl accessibleRoles={[UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER]}>
            <Form.Item shouldUpdate={(prev, next) => prev.type !== next.type} noStyle>
              {() => (
                <Form.Item label="サプライヤー" name="vendors" labelAlign="left">
                  <VendorPicker
                    showPrice={form.getFieldValue('type') === ConstructionSiteType.FIXED_PRICE}
                    // onSelect={handleSelectVendor}
                    onRemove={handleRemoveVendor}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </AccessControl>
          <AccessControl accessibleRoles={[UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER, UserRole.VENDOR]}>
            <Form.Item shouldUpdate={(prev, next) => prev.vendors !== next.vendors} noStyle>
              {({ getFieldValue }) => {
                return (
                  <Form.Item label="スタッフ" name="workers" labelAlign="left">
                    <WorkerPicker
                      vendorIds={_.map(form.getFieldValue('vendors'), 'id')}
                      disable={isDisableWorkerPicker()}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </AccessControl>
          <div className="d-flex justify-content-center">
            <Button
              style={{ marginRight: 5, minWidth: 240 }}
              htmlType="button"
              className="btn-outline"
              onClick={onCancel}
            >
              キャンセル
            </Button>
            <Button style={{ marginLeft: 5, minWidth: 240 }} type="primary" disabled={loading} onClick={handleOk}>
              {mode === ModeType.CREATE ? '作成 ' : '​保存'}
            </Button>
          </div>
        </Form>
        <CommonModal
          isModalVisible={isOpen.modalConfirm}
          handleClose={() => setIsOpen({ ...isOpen, modalConfirm: false })}
          title=""
          maskClosable={false}
          footer={null}
          width={1000}
          className={styles.modalConfirm}
        >
          <div>
            <h2 className={classNames(styles.titleModal, 'heading-16 mt-20 text-center')}>
              <p>プロジェクトタイプが固定価格です。</p>
              <p>見積もり金額を確認してください。</p>
              <p>本当に作成しますか？</p>
            </h2>

            <div className="d-flex justify-content-center mt-25 ml-25">
              <Button
                htmlType="button"
                className={classNames(styles.btn, 'btn-outline')}
                onClick={() => setIsOpen({ ...isOpen, modalConfirm: false })}
              >
                いいえ
              </Button>

              <Button type="primary" className={styles.btn} onClick={handleOkConfirm}>
                はい
              </Button>
            </div>
          </div>
        </CommonModal>

        <CommonModal
          isModalVisible={isOpen.modalConfirmCreate}
          handleClose={() => setIsOpen({ ...isOpen, modalConfirmCreate: false })}
          title=""
          maskClosable={false}
          footer={null}
          width={1000}
          className={styles.modalConfirm}
        >
          <div>
            <h2 className={'heading-20 mt-20 text-center'}>このプロジェクトの手配を作成しますか。</h2>

            <div className="d-flex justify-content-center mt-25 ml-25">
              <Button
                htmlType="button"
                className={classNames(styles.btn, 'btn-outline')}
                onClick={handleCloseCreate}
                loading={loading}
              >
                いいえ
              </Button>

              <Button type="primary" className={styles.btn} onClick={handleOkCreate} loading={loading}>
                はい
              </Button>
            </div>
          </div>
        </CommonModal>
      </div>
    </Modal>
  );
}
