import { TypeTaskArrangement } from 'common';
import { dateUtils } from 'common/dateUtils';
import { isNil } from 'lodash';
import { nameTempTypeDate } from 'pages/Tasks/components/TimeTaskPicker';

const useCreateTask = () => {
  const checkDateSameToday = (values: any) => {
    const today = dateUtils.getToday();
    const typeDate = values?.[nameTempTypeDate];

    const endDate = typeDate === TypeTaskArrangement.ONE_DAY ? values?.startDate : values?.endDate;

    const isStartDateSameToday = !isNil(values?.startDate) && dateUtils.isSameDate(values?.startDate, today);

    const isEndDateSameToday = !isNil(endDate) && dateUtils.isSameDate(endDate, today);

    return isStartDateSameToday && isEndDateSameToday;
  };

  // not select start time desire
  const checkStartTimeDesireNotSelect = (values: any) => {
    const { startTimeDesire } = values;

    return isNil(startTimeDesire);
  };

  // not select both start time desire and end time desire
  const checkTimeDesireNotSelect = (values: any) => {
    const { startTimeDesire, endTimeDesire } = values;

    // is didn't select time desire
    return [startTimeDesire, endTimeDesire].every((time) => isNil(time));
  };

  const checkStartDateAndEndDateSameDateAndInPast = (values: any) => {
    const typeDate = values?.[nameTempTypeDate];

    const isStartDateAdnEndDateSameDate =
      typeDate === TypeTaskArrangement.ONE_DAY ? true : dateUtils.isSameDate(values?.startDate, values?.endDate);

    const isStartDateInPast = dateUtils.isDateInPast(values?.startDate);

    return isStartDateAdnEndDateSameDate && isStartDateInPast;
  };

  return {
    checkDateSameToday,
    checkTimeDesireNotSelect,
    checkStartDateAndEndDateSameDateAndInPast,
    checkStartTimeDesireNotSelect,
  };
};

export default useCreateTask;
