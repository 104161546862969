import { Checkbox, Col, Form, Input, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { updateProfile } from 'api/profile';
import { IStaff, staffJoinCompany } from 'api/staff';
import { UserRole } from 'common';
import {
  MAX_LENGTH,
  MAX_LENGTH_CHARTER_CAPITAL,
  PATTERN_EMAIL,
  PATTERN_KATAKANA,
  PATTERN_ONLY_TEXT_AND_NUMBER,
  PATTERN_PHONE,
} from 'common/const';
import { dateUtils } from 'common/dateUtils';
import { CommonButton } from 'components/CommonButton';
import { CommonDatepicker } from 'components/CommonDatepicker';
import { CommonInput } from 'components/CommonInput';
import { CommonModal } from 'components/CommonModal';
import { CommonSelect } from 'components/CommonSelect';
import { UploadListFile } from 'components/UploadListFile';
import { handleErrorMessage, navigateToApp, newListFile } from 'helper';
import { passwordValidator } from 'helper/validator';
import mobile from 'is-mobile';
import Cookies from 'js-cookie';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';
import { useRulesForm } from 'hooks/useRulesForm';

const { Text, Link } = Typography;

interface IProps {
  handleClose?: () => void;
  edit?: boolean;
  handleOpenStaffInfo?: (data?: any) => any;
  isExist?: boolean;
  navigateLogin?: () => void;
}

export const FormStaff = ({ handleClose, edit, handleOpenStaffInfo, isExist, navigateLogin }: IProps) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [disable, setDisable] = useState(true);
  const queryClient = useQueryClient();
  const tokenLocal = useMemo(() => Cookies.get('token'), []);
  const refreshTokenLocal = useMemo(() => Cookies.get('refreshToken'), []);
  const isMobile = mobile();
  const { data: listResource } = queryClient.getQueryData('resource') as any;
  const profile = queryClient.getQueryData('profile') as any;
  // const profileStaff = useQuery('profileStaff', loadProfile);
  // const profile = profileStaff?.data;
  const requestStaff = queryClient.getQueryData('inviteStaffToken') as any;
  const newStaffEmail = requestStaff?.data?.email;
  const { rulesForm } = useRulesForm();

  const listCardType = listResource?.cardType || [];
  const listBloodGroup = listResource?.bloodGroup || [];
  const listDisease = listResource?.disease || [];
  const listEmploymentInsurance = listResource?.employmentInsurance || [];
  const listHealthInsurance = listResource?.healthInsurance || [];
  const listPensionInsurance = listResource?.pensionInsurance || [];

  useEffect(() => {
    form.setFieldsValue(isExist ? { ...profile?.data } : { email: newStaffEmail });
  }, [newStaffEmail]);

  const onClose = () => {
    handleClose!();
    form.resetFields();
  };

  const handleSubmit = async (data: Partial<IStaff>) => {
    try {
      // @ts-ignore
      delete data?.confirm;
      delete data?.email;
      setLoading(true);
      const payloadStaffJoinCompany = {
        token: requestStaff?.data?.inviteId,
        password: !isExist ? data.password : '',
      };
      const newData = {
        ...data,
        name: data?.name,
        address: data?.address || null,
        yearExperience: +data?.yearExperience! || null,
        birthday: data?.birthday ? dateUtils.getDateToString(data?.birthday) : null,
        joinDate: data?.joinDate ? dateUtils.getDateToString(data?.joinDate) : null,
        phone: data?.phone || null,
        furiganaName: data?.furiganaName || null,
        job: data?.job,
        emergencyAddress: {
          address: data?.emergencyAddress?.address || null,
          name: data?.emergencyAddress?.name || null,
          phone: data?.emergencyAddress?.phone || null,
          relation: data?.emergencyAddress?.relation || null,
        },
        heal: {
          bloodGroup: data?.heal?.bloodGroup || null,
          lastHealCheckDate: data?.heal?.lastHealCheckDate
            ? dateUtils.getDateToString(data?.heal?.lastHealCheckDate)
            : null,
          specificHealCheckDate: data?.heal?.specificHealCheckDate
            ? dateUtils.getDateToString(data?.heal?.specificHealCheckDate)
            : null,
          minBloodPressure: +data?.heal?.minBloodPressure! || null,
          maxBloodPressure: +data?.heal?.maxBloodPressure! || null,
          diseaseId: +data?.heal?.diseaseId! || null,
          healthInsuranceId: +data?.heal?.healthInsuranceId! || null,
          pensionInsuranceId: +data?.heal?.pensionInsuranceId! || null,
          employmentInsuranceId: +data?.heal?.employmentInsuranceId! || null,
          employmentInsuranceNumber: data?.heal?.employmentInsuranceNumber! || null,
        },
        certification: {
          //@ts-ignore
          license: newListFile(data?.certification?.license || []),
          //@ts-ignore
          skillTraining: newListFile(data?.certification?.skillTraining || []),
          //@ts-ignore
          specialEducation: newListFile(data?.certification?.specialEducation || []),
        },
        bankInfo: {
          bankName: data?.bankInfo?.bankName || null,
          branch: data?.bankInfo?.branch || null,
          cardNumber: data?.bankInfo?.cardNumber || null,
          ownerName: data?.bankInfo?.ownerName || null,
          type: +data?.bankInfo?.type! || null,
        },
      };
      if (!isExist) {
        const response = await staffJoinCompany({ ...newData, ...payloadStaffJoinCompany });
        const { token, refreshToken } = response?.data;
        Cookies.set('token', token);
        Cookies.set('refreshToken', refreshToken);
        Cookies.set('roleId', requestStaff?.data?.roleId + '');
        Cookies.set('companyId', requestStaff?.data?.companyId + '');
        if (isMobile)
          return navigateToApp(token, refreshToken, requestStaff?.data?.companyId, requestStaff?.data?.roleId);
        !!handleOpenStaffInfo && handleOpenStaffInfo();
        if (!isExist && !!token) {
          queryClient.setQueryData('hideFormStaff', true);
          history.push('/calendar');
        }
      } else {
        await updateProfile(newData);
        if (isMobile)
          return navigateToApp(
            tokenLocal!,
            refreshTokenLocal!,
            requestStaff?.data?.companyId + '',
            requestStaff?.data?.roleId + ''
          );
        !!handleOpenStaffInfo && handleOpenStaffInfo();
        queryClient.refetchQueries(['profile'], { active: true });
      }
      onClose();
      handleClose!();
      form.resetFields();
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CommonModal
        handleClose={() => {
          onClose();
          !isExist && navigateLogin && navigateLogin!();
        }}
        isModalVisible={edit}
        title=""
        form={form}
        footer={null}
        width={1000}
        maskClosable={false}
        className={`${styles.modalStaff} ${styles.pModal}`}
      >
        <div className={'head'}>
          <div className={'name'}>人事を確認</div>
        </div>
        <Form form={form} layout="vertical" onFinish={handleSubmit} scrollToFirstError={true}>
          <input id="password" type="password" className="hidden-autocomplete" />
          <input id="email" className="hidden-autocomplete" />
          <div className={`heading-16 mb-25`}>ログイン情報</div>
          <Row gutter={20}>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="メール"
                name="email"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                  {
                    pattern: PATTERN_EMAIL,
                    message: 'メールが違います',
                  },
                ]}
              >
                <CommonInput disabled />
              </Form.Item>
            </Col>
            {!isMobile && <Col span={12} xs={24} sm={24} md={12} lg={12}></Col>}
            {!isExist && (
              <>
                <Col span={12} xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label="パスワード" name="password" rules={passwordValidator} labelAlign="left">
                    <Input.Password className="inputGrey" />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    name="confirm"
                    label="確認パスワード"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: '全ての項目を入力してください。',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('パスワードと確認パスワードが間違っています。');
                        },
                      }),
                    ]}
                  >
                    <Input.Password className="inputGrey" />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <div className={`heading-16 mb-25`}>基本情報</div>
          <Row gutter={20}>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="氏名 "
                name="name"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>

            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="氏名（カタカナ） "
                name="furiganaName"
                rules={[
                  {
                    pattern: PATTERN_KATAKANA,
                    message: 'カタカナで入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="経験年数 " name="yearExperience" rules={[]} labelAlign="left">
                <Input type="tel" className="inputGrey" min={0} maxLength={MAX_LENGTH_CHARTER_CAPITAL} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="職種 " name="job" rules={[]} labelAlign="left">
                <CommonInput />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="生年月日" name="birthday" rules={[rulesForm().required]} labelAlign="left">
                <CommonDatepicker />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="住所" name="address" rules={[]} labelAlign="left">
                <CommonInput maxLength={MAX_LENGTH * 2} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="電話番号"
                name="phone"
                rules={[
                  {
                    pattern: PATTERN_PHONE,
                    message: '電話番号の形式が正しくありません。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>
          </Row>

          <div className={`heading-16 mb-25`}>緊急連絡先</div>
          <Row gutter={20}>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="氏名" name={['emergencyAddress', 'name']} rules={[]} labelAlign="left">
                <CommonInput />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="続柄" name={['emergencyAddress', 'relation']} rules={[]} labelAlign="left">
                <CommonInput />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="住所" name={['emergencyAddress', 'address']} rules={[]} labelAlign="left">
                <CommonInput />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="電話番号"
                name={['emergencyAddress', 'phone']}
                rules={[
                  {
                    pattern: PATTERN_PHONE,
                    message: '電話番号の形式が正しくありません。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>
          </Row>

          <div className={`heading-16 mb-25`}>健康状態</div>
          <Row gutter={20}>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="血液型" name={['heal', 'bloodGroup']} rules={[]} labelAlign="left">
                <CommonSelect options={listBloodGroup} showSearch={false} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="最近の健康診断日" name={['heal', 'lastHealCheckDate']} rules={[]} labelAlign="left">
                <CommonDatepicker />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="最高血圧" name={['heal', 'maxBloodPressure']} rules={[]} labelAlign="left">
                <Input type="tel" className="inputGrey" min={0} maxLength={MAX_LENGTH_CHARTER_CAPITAL} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="最低血圧" name={['heal', 'minBloodPressure']} rules={[]} labelAlign="left">
                <Input type="tel" className="inputGrey" min={0} maxLength={MAX_LENGTH_CHARTER_CAPITAL} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="特殊健康診断日" name={['heal', 'specificHealCheckDate']} labelAlign="left">
                <CommonDatepicker />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="種類" name={['heal', 'diseaseId']} labelAlign="left">
                <CommonSelect options={listDisease} showSearch={false} />
              </Form.Item>
            </Col>
          </Row>

          <div className={`heading-16 mb-25`}>保険加入状況</div>
          <Row gutter={20}>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="健康保険" name={['heal', 'healthInsuranceId']} rules={[]} labelAlign="left">
                <CommonSelect options={listHealthInsurance} showSearch={false} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="年金保険" name={['heal', 'pensionInsuranceId']} rules={[]} labelAlign="left">
                <CommonSelect options={listPensionInsurance} showSearch={false} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="雇用保険" name={['heal', 'employmentInsuranceId']} rules={[]} labelAlign="left">
                <CommonSelect options={listEmploymentInsurance} showSearch={false} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="雇用保険番号(下4桁)" name={['heal', 'employmentInsuranceNumber']} labelAlign="left">
                <CommonInput maxLength={4} />
              </Form.Item>
            </Col>
          </Row>

          <div className={`heading-16 mb-25`}>資格</div>
          <Row gutter={20}>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="雇入・職長特別教育" name={['certification', 'specialEducation']} labelAlign="left">
                <UploadListFile />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="技能講習" name={['certification', 'skillTraining']} labelAlign="left">
                <UploadListFile />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item label="免許" name={['certification', 'license']} labelAlign="left">
                <UploadListFile />
              </Form.Item>
            </Col>
          </Row>

          <div className={`heading-16 mb-25`}>銀行口座情報</div>
          <Row gutter={20}>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="金融機関名"
                name={['bankInfo', 'bankName']}
                rules={[
                  {
                    required: requestStaff?.data?.roleId === UserRole.OTHER_WORKER,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="支店名"
                name={['bankInfo', 'branch']}
                rules={[
                  {
                    required: requestStaff?.data?.roleId === UserRole.OTHER_WORKER,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="口座種別"
                name={['bankInfo', 'type']}
                rules={[
                  {
                    required: requestStaff?.data?.roleId === UserRole.OTHER_WORKER,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonSelect options={listCardType} showSearch={false} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="口座番号"
                name={['bankInfo', 'cardNumber']}
                rules={[
                  {
                    required: requestStaff?.data?.roleId === UserRole.OTHER_WORKER,
                    message: '全ての項目を入力してください。',
                  },
                  {
                    pattern: PATTERN_ONLY_TEXT_AND_NUMBER,
                    message: '数字もしくは文字が入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="口座名義"
                name={['bankInfo', 'ownerName']}
                rules={[
                  {
                    required: requestStaff?.data?.roleId === UserRole.OTHER_WORKER,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput />
              </Form.Item>
            </Col>
          </Row>
          {!isExist && (
            <Checkbox className="mb-20" onChange={() => setDisable(!disable)}>
              Booking offの
              <Link href="/term-of-policy" target="_blank">
                利用規約
              </Link>
              ・
              <Link href="/term-of-privacy" target="_blank">
                プライバシーポリシー
              </Link>
              に同意する。
            </Checkbox>
          )}

          <Row gutter={20}>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item className={'right'}>
                <CommonButton
                  onClick={() => {
                    onClose();
                    !isExist && navigateLogin && navigateLogin!();
                  }}
                  title="キャンセル"
                  className={'btnWhite'}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Form.Item>
                <CommonButton
                  title="確認 "
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={!isExist ? disable : false}
                  className={'btnPrimary'}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};
