import { Col, Form, Row, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import icNoteTask from 'assets/icons/note-task.svg';
import { TextAreaProps } from 'antd/lib/input';
import classNames from 'classnames';

interface IProps extends TextAreaProps {}

const NoteTaskInput = ({ className, ...rest }: IProps) => {
  return (
    <Row gutter={[20, 0]} align="top">
      <Col span={24}>
        <Space size={4}>
          <img src={icNoteTask} alt="" />
          <span>メモ</span>
        </Space>
      </Col>

      <Col span={24} className="mt-10">
        <Form.Item name="note" className="mb-0">
          <TextArea className={classNames(' inputGrey', className)} autoSize={{ minRows: 5 }} {...rest} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default NoteTaskInput;
