import { UpdateTaskMode } from 'common';
import { dateUtils } from 'common/dateUtils';
import { IMetadataCustomerUpdate, ITaskDetail } from 'common/interface';

interface IProps {
  metadataCustomerUpdate: IMetadataCustomerUpdate;
  taskDetail?: ITaskDetail;
}

const MetadataTime = ({ metadataCustomerUpdate, taskDetail }: IProps) => {
  // mode = 3: future
  // const isHideMetadataTimeWhenModeIsFuture =
  //   taskDetail?.startDate === metadataCustomerUpdate?.date?.from &&
  //   taskDetail?.seriesTo === metadataCustomerUpdate?.date?.to &&
  //   metadataCustomerUpdate?.mode === UpdateTaskMode.FUTURE;

  // const isHideMetadataTime = isHideMetadataTimeWhenModeIsSingle || isHideMetadataTimeWhenModeIsFuture;

  // mode = 1: single
  const isHideMetadataTimeWhenModeIsSingle =
    metadataCustomerUpdate?.mode === UpdateTaskMode.ONE &&
    taskDetail?.startDate === metadataCustomerUpdate?.date?.from &&
    taskDetail?.startDate === metadataCustomerUpdate?.date?.to;

  // mode 3 : future
  const isHideMetadataTimeWhenModeFuture =
    metadataCustomerUpdate?.mode === UpdateTaskMode.FUTURE &&
    metadataCustomerUpdate?.taskUpdate?.startDate === metadataCustomerUpdate?.date?.from &&
    taskDetail?.seriesTo === metadataCustomerUpdate?.date?.to;

  const isHideMetadataTime = isHideMetadataTimeWhenModeIsSingle || isHideMetadataTimeWhenModeFuture;

  if (isHideMetadataTime) return null;

  const isSameTimeStartAndEnd = metadataCustomerUpdate?.date?.from === metadataCustomerUpdate?.date?.to;

  const title = !isSameTimeStartAndEnd
    ? `${dateUtils.getDate(metadataCustomerUpdate?.date?.from)}-${dateUtils.getDate(metadataCustomerUpdate?.date?.to)}`
    : dateUtils.getDate(metadataCustomerUpdate?.date?.from);

  return <div className="text-green">{title}</div>;
};

export default MetadataTime;
