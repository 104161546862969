import { Row, Tooltip } from 'antd';
import classNames from 'classnames';
import AvatarProfile from 'components/Avatar';
import { formatCurrencyJP, getWorkerType } from 'helper';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';
import { UserRole } from 'common';
import { IWorker } from 'common/interface';
import useProfile from 'hooks/useProfile';

interface StaffItemProps {
  worker: IWorker;
  hiddenPrice?: boolean;
  isCanViewLink?: boolean;
}

function StaffItem({ worker, hiddenPrice = true, isCanViewLink }: StaffItemProps) {
  const profile = useProfile();

  const isUnActive = () => {
    if ([UserRole.ADMIN, UserRole.MANAGER, UserRole.LEADER, UserRole.OFFICE_MANAGER].includes(worker?.roleId))
      return false;

    return !Boolean(worker?.status);
  };

  const isShowRoleTitle = ![UserRole.VENDOR, UserRole.CUSTOMER, UserRole.VENDOR_WORKER].includes(
    profile?.company?.roleId
  );

  const showPrice = () => {
    const isFreelancer = worker?.roleId === UserRole.OTHER_WORKER;
    const isAdmin = [UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER].includes(profile.company.roleId);

    if (!isFreelancer) {
      return false;
    }

    if (isAdmin) {
      return true;
    }

    if (isFreelancer) {
      return worker?.userId === profile.id;
    }

    return false;
  };

  return (
    <NavLink
      to={`/staff/${worker?.id}`}
      key={worker?.id}
      target="_blank"
      className={classNames(
        { [styles.unActive]: isUnActive(), [styles.disableLink]: !isCanViewLink },
        styles.staffItem
      )}
    >
      <AvatarProfile src={worker.avatar} name={worker.name} size={33} />
      <div className={styles.info}>
        <div className={styles.name}>
          <Tooltip className="line-1" title={worker?.name}>
            {worker?.name}
          </Tooltip>
        </div>
        <Row align="middle" justify="space-between">
          {isShowRoleTitle && (
            <div className="fontsize-13 color-text2 max-1-line">
              {worker?.vendorName ? `サプライヤー: ${worker?.vendorName}` : getWorkerType(worker.roleId)}
            </div>
          )}

          {!hiddenPrice && showPrice() && (
            <Tooltip title={formatCurrencyJP(worker?.price)} placement="topRight">
              <div className={classNames(styles.inputPrice, 'max-1-line')}>{formatCurrencyJP(worker?.price)}</div>
            </Tooltip>
          )}
        </Row>
      </div>
    </NavLink>
  );
}

export default StaffItem;
