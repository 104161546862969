import { Col, Form, Input, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { login } from 'api/authentication';
import { createCustomer } from 'api/customer';
import { staffJoinCompany } from 'api/staff';
import { createVendor } from 'api/vendor';
import { UserRole } from 'common';
import { listRolesStaff } from 'common/const';
import { BannerRightHeader } from 'components/Banner';
import { CommonButton } from 'components/CommonButton';
import { CommonModal } from 'components/CommonModal';
import ConfirmLogout from 'components/ConfirmLogout';
import { handleErrorMessage } from 'helper';
import { passwordValidator } from 'helper/validator';
import Cookies from 'js-cookie';
import { useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import styles from './styles.module.scss';
const { Title } = Typography;

interface IProps {
  invitedUser?: any;
}

export default function ConfirmPasswordInvitedUser({ invitedUser }: IProps) {
  const [form] = useForm();

  const [open, setOpen] = useSetState({ openConfirmPassword: true, openAccept: false, openFormStaff: false });
  const [responseToken, setResponseToken] = useSetState<any>({});
  const isStaff = (roleId: number) => listRolesStaff.find((el) => el.id === roleId);

  const queryClient = useQueryClient();

  const handleSubmit = async (payload: any) => {
    try {
      if (invitedUser?.roleId === UserRole.VENDOR) {
        const newPayload = { ...payload, email: invitedUser?.email, inviteId: invitedUser?.inviteId };
        const response = await createVendor(newPayload);
        const { token, refreshToken } = response?.data;
        Cookies.set('companyId', invitedUser?.companyId + '');
        Cookies.set('roleId', invitedUser?.roleId + '');
        Cookies.set('token', token);
        Cookies.set('refreshToken', refreshToken);
        queryClient.refetchQueries('profile');
        setOpen({ openAccept: false, openConfirmPassword: false });
      } else if (isStaff(invitedUser?.roleId)) {
        const newPayload = { ...payload, email: invitedUser?.email };
        const response = await login(newPayload);
        const { token } = response?.data;
        const responseInviteStaff = await staffJoinCompany({ token: invitedUser?.inviteId, accessToken: token });
        const inviteStaffToken = responseInviteStaff?.data;
        Cookies.set('token', inviteStaffToken?.token);
        Cookies.set('refreshToken', inviteStaffToken?.refreshToken);
        Cookies.set('roleId', invitedUser?.roleId + '');
        Cookies.set('companyId', invitedUser?.companyId + '');
        queryClient.invalidateQueries('profile');
        setOpen({ openAccept: false, openConfirmPassword: false });
      } else if (invitedUser?.roleId === UserRole.CUSTOMER) {
        const newPayload = { ...payload, email: invitedUser?.email, inviteId: invitedUser?.inviteId };
        const response = await createCustomer(newPayload);
        const { token, refreshToken } = response?.data;
        Cookies.set('companyId', invitedUser?.companyId + '');
        Cookies.set('roleId', invitedUser?.roleId + '');
        Cookies.set('token', token);
        Cookies.set('refreshToken', refreshToken);
        queryClient.refetchQueries('profile');
        setResponseToken(response?.data);
        setOpen({ openAccept: true, openConfirmPassword: false });
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  return (
    <>
      <CommonModal
        isModalVisible={open.openConfirmPassword}
        handleClose={() => setOpen({ openConfirmPassword: false })}
        title=""
        maskClosable={false}
        footer={null}
        width={700}
      >
        <div className={styles.header}>
          <BannerRightHeader />
          <Title level={5} className={styles.formContent}>
            {`${invitedUser?.email}アカウントでログインしています。`}
            <br />
            続行するには、パスワードを入力してください。
          </Title>
          <Form form={form} layout="vertical" onFinish={handleSubmit} scrollToFirstError={true}>
            <Row justify="space-between" gutter={20}>
              <Col span={24}>
                <Form.Item label={'パスワード'} name="password" rules={passwordValidator} labelAlign="left">
                  <Input.Password className="inputGrey" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <CommonButton
                    title="戻る "
                    type="default"
                    onClick={() => setOpen({ openConfirmPassword: false })}
                    className={styles.btnWhite}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <CommonButton title="ログイン" type="primary" htmlType="submit" className={styles.btnSubmit} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </CommonModal>
      {open.openAccept && (
        <ConfirmLogout companyId={invitedUser?.companyId} roleId={invitedUser?.roleId} responseToken={responseToken} />
      )}
    </>
  );
}
