import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { searchCommonVendor } from 'api/common';
import { Col, Row, Select, Tooltip } from 'antd';
import chevronDown from 'assets/icons/chevron-down-black.svg';
import close from 'assets/icons/x.svg';

import styles from './styles.module.scss';
import AvatarProfile from 'components/Avatar';
import { CommonInput } from 'components/CommonInput';
import useRoleId from 'hooks/useRoleId';
import useProfile from 'hooks/useProfile';
import { UserRole } from 'common';

const { Option } = Select;

interface IProps {
  value?: any[];
  onChange?: Function;
  onSelect?: (vendor: any) => any;
  onRemove?: (vendor: any) => any;
  disabled?: boolean;
  showPrice?: boolean;
  showWorker?: boolean;
}

export default function VendorPicker({ value = [], onChange, onSelect, onRemove, disabled, showPrice }: IProps) {
  const roleId = useRoleId();
  const profile = useProfile();
  const [options, setOptions] = useState<any[]>([]);
  const fetchVendor = useCallback(
    _.debounce((keyword: string = '') => {
      searchCommonVendor({ pageIndex: 1, pageSize: 100, keyword }).then((res) => setOptions(res?.data));
    }, 300),
    []
  );

  useEffect(() => fetchVendor(''), []);
  const handleSelect = (id: any) => {
    const vendor = options.find((x) => x.id === id);
    const newValue = _.uniqBy([vendor, ...value], 'id');

    onChange && onChange(newValue);
    onSelect && onSelect(vendor);
  };
  const handleRemove = (vendor: any) => {
    onChange && onChange(value?.filter((x: any) => x.id !== vendor.id));
    onRemove && onRemove(vendor);
  };
  const handleChangeSalary = (id: number, salary: number) => {
    const vendor = value?.find((x) => x.id === id);
    vendor.salary = salary;
    onChange && onChange([...value]);
  };

  return (
    <div style={{ cursor: disabled ? 'not-allowed' : undefined }}>
      <div className={disabled ? styles.disabled : undefined}>
        <Select
          className={styles.container}
          suffixIcon={<img width={12} height={12} src={chevronDown} alt="" />}
          showSearch
          onChange={handleSelect}
          filterOption={false}
          onSearch={fetchVendor}
          value={[]}
        >
          {options?.map((option: any) => (
            <Option key={option?.id} value={option.id}>
              <div key={option?.id} className={styles.option}>
                <AvatarProfile src={option.avatar} name={option.name} size={33} />
                <div className={styles.info}>
                  {option?.vendorCompanyName} - {option?.name}
                </div>
              </div>
            </Option>
          ))}
        </Select>
        <Row gutter={8}>
          {value?.map((item: any) => {
            if (roleId === UserRole.VENDOR && item.id !== profile.id) return null;
            return (
              <Col key={item.id} xs={12}>
                <div className={styles.selectedItem}>
                  <AvatarProfile src={item.avatar} name={item.name} size={33} />
                  <div className={styles.info}>
                    <Tooltip title={item?.name}>
                      {item?.vendorCompanyName} - {item?.name}
                    </Tooltip>
                  </div>
                  {showPrice && (
                    <div style={{ margin: '0 6px', maxWidth: '30%' }}>
                      <CommonInput
                        type="number"
                        min={0}
                        value={Number(item?.salary || 0).toString()}
                        onChange={(e) => handleChangeSalary(item.id, +e.target.value)}
                        suffix="¥"
                      />
                    </div>
                  )}
                  <img className={styles.remove} src={close} onClick={() => handleRemove(item)} alt="" />
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}
